import { makeStyles } from '@mui/styles';

const assignPRMStyles = makeStyles({
  options: {
    color: '#0A3A67 !important',
    fontFamily: 'Literata !important',
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  select: {
    '& .MuiOutlinedInput-input': {
      background: 'transparent !important'
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
    background: '#0F3E6A1A',
    borderRadius: '50px !important',
    color: 'rgba(15, 62, 106, 0.6) !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    height: '38px',
    outline: 'none',
    width: '10rem'
  }
});

export default assignPRMStyles;
