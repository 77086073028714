import { createSlice } from '@reduxjs/toolkit';
import {
  addOrderSubStatus,
  addPaymentsInStTransOrderSubStatus,
  addStPayment,
  addStPerformancePoint,
  addStTransactionalOrder,
  addStTransOrderComment,
  addStTransOrderExtComment,
  addStTransOrderExtSubComment,
  assignStTransOrderRM,
  cancelStTransactionalOrder,
  deleteStOrderFile,
  deleteStTransOrderFile,
  getPerformanceCredit,
  getStNormalOrdersListingByClientId,
  getStOrderFiles,
  getStPaymentList,
  getStPerformancePoint,
  getStTransactionalOrderDetailsById,
  getStTransactionalOrdersListing,
  getStTransactionalOrdersListingByClientId,
  getStTransOrderComments,
  getStTransOrderExtComments,
  getStTransOrderExtSubComments,
  getStTransOrderFiles,
  searchInStTransComment,
  stTransactionCart,
  stTransOrderSubStatusAdd,
  stTransOrderSubStatusUpdate,
  updatePaymentsInStTransOrderSubStatus,
  updateStSubAssignFOS,
  updateStTransactionalOrder,
  updateStTransactionalOrderStatus,
  updateStTransOrderSubAssignRM,
  uploadStOrderFile,
  uploadStTransOrderCommentFile,
  uploadStTransOrderFile
} from 'api/stCases';
import { toast } from 'react-toastify';

export const stTransOrderFilterInitialValues = {
  assignTo: '',
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  orderStatus: '',
  orderType: '',
  rewm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const stCasesSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: stTransOrderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'stCases',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const stCasesAction = stCasesSlice.actions;

export const handleGetStTransactionalOrdersListing = (params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStTransactionalOrdersListing(params);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStTransactionalOrderDetailsById = (orderId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStTransactionalOrderDetailsById(orderId);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStTransactionalOrdersListingByClientId =
  (clientId, page) => async (dispatch) => {
    try {
      dispatch(stCasesAction.setIsLoading(true));
      const data = await getStTransactionalOrdersListingByClientId(clientId, page);
      return data;
    } catch (error) {
      dispatch(stCasesAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(stCasesAction.setIsLoading(false));
    }
  };

  export const handleGetStNormalOrdersListingByClientId =
  (clientId, page) => async (dispatch) => {
    try {
      dispatch(stCasesAction.setIsLoading(true));
      const data = await getStNormalOrdersListingByClientId(clientId, page);
      return data;
    } catch (error) {
      dispatch(stCasesAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(stCasesAction.setIsLoading(false));
    }
  };

export const handleAddStTransactionalOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addStTransactionalOrder(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleCancelStTransactionalOrder = (clientId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await cancelStTransactionalOrder(clientId);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleUpdateStTransactionalOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await updateStTransactionalOrder(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStTransOrderComments = (id) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStTransOrderComments(id);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAddStTransOrderComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addStTransOrderComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleCreateStTransactionCart = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await stTransactionCart(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAssignStTransOrderRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await assignStTransOrderRM(clientId, orderId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleStTransOrderFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await uploadStTransOrderFile(clientId, files);
    if (data?.message) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStTransOrderFiles = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStTransOrderFiles(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleDeleteStTransOrderFile = (clientId, orderId, fileId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await deleteStTransOrderFile(clientId, orderId, fileId);
    if (data?.message) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleStOrderFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await uploadStOrderFile(clientId, files);
    if (data?.message) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStOrderFiles = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStOrderFiles(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleDeleteStOrderFile = (clientId, orderId, fileId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await deleteStOrderFile(clientId, orderId, fileId);
    if (data?.message) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};


export const handleAddStTransOrderExtComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addStTransOrderExtComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStTransOrderExtComments = (id) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStTransOrderExtComments(id);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAddStTransOrderExtSubComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addStTransOrderExtSubComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStTransOrderExtSubComments = (id) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStTransOrderExtSubComments(id);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleStTransOrderCommentFileUpload = (id, files) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await uploadStTransOrderCommentFile(id, files);
    if (data?.message) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleSearchInStTransComment =
  (offset, limit, search_param, orderId) => async (dispatch) => {
    try {
      dispatch(stCasesAction.setIsLoading(true));
      const data = await searchInStTransComment(offset, limit, search_param, orderId);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(stCasesAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(stCasesAction.setIsLoading(false));
    }
  };

export const handleStTransOrderStatusUpdate =
  (clientId, transOrderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(stCasesAction.setIsLoading(true));
      const data = await updateStTransactionalOrderStatus(
        clientId,
        transOrderId,
        statusId,
        subStatusId
      );
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(stCasesAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(stCasesAction.setIsLoading(false));
    }
  };

export const handleSubAssignFOSStTrans = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await updateStSubAssignFOS(clientId, orderId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleSubAssignRMStTrans = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await updateStTransOrderSubAssignRM(clientId, orderId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAddStOrderSubStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await stTransOrderSubStatusAdd(clientId, orderId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAddPaymentsInStOrderSubStatus = (formData) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addPaymentsInStTransOrderSubStatus(formData);
    if (data) toast.success(data?.message || 'Details added successfully');
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleUpdateStOrderSubStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await stTransOrderSubStatusUpdate(clientId, orderId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleUpdatePaymentsInStOrderSubStatus = (paymentId, formData) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await updatePaymentsInStTransOrderSubStatus(paymentId, formData);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};


export const handleAddOrderSubStatus = (clientId, formData) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addOrderSubStatus(clientId, formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAddStPayment = (formData) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addStPayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStPayments = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStPaymentList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStPerformanceCredit = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getPerformanceCredit(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleAddStPerformancePoint = (formData) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await addStPerformancePoint(formData);
    if (data) toast.success(data?.message || 'Details added successfully');
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export const handleGetStPerformancePoint = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(stCasesAction.setIsLoading(true));
    const data = await getStPerformancePoint(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(stCasesAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stCasesAction.setIsLoading(false));
  }
};

export default stCasesSlice;