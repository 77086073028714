import React, { useEffect, useState } from 'react';
import { CloseOutlined as CloseOutlinedIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './dateTimePicker.styles';
function DateTimePicker({
  type,
  value,
  onChange,
  open,
  onClose,
  startDateAndTime,
  workingHours = false,
  disableFuture = false,
  showTimePicker = true
}) {
  const classes = styles();
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [amPmvalue, setAmPmValue] = useState('');
  const [errorMessage, setError] = useState('');

  // set the default date, time, hours, minutes, am/pm values on value change
  useEffect(() => {
    setDate(value || (type === 'start' ? new Date() : ''));
    setTime(
      value ? moment(value).format('YYYY/MM/DD hh:mm A') : moment().format('YYYY/MM/DD hh:mm A')
    );

    setHours(value ? moment(value).format('hh') : moment().format('hh'));
    setMinutes(value ? moment(value).format('mm') : moment().format('mm'));
    setAmPmValue(value ? moment(value).format('A') : moment().format('A'));
  }, [value]);

  // on hours, minutes, am/pm value change, set the time value
  useEffect(() => {
    if (hours && minutes && amPmvalue) {
      const formattedDate = moment().format('YYYY-MM-DD');
      const formattedTime = `${hours}:${minutes} ${amPmvalue}`;

      setTime(moment(formattedDate + ' ' + formattedTime).format('YYYY/MM/DD HH:mm:ss'));
    }
  }, [hours, minutes, amPmvalue]);

  // set date and time on clicking on ok button
  const setDateAndTime = () => {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    let formattedTime = moment(time).format('HH:mm:ss');

    if (workingHours) {
      let validation = validateTime(formattedTime);

      if (!validation) return null;
    }

    const formattedDateAndTime = moment(
      formattedDate + ' ' + formattedTime,
      'YYYY-MM-DDTHH:mm:00.000Z'
    );

    onChange(formattedDateAndTime);
    onClose();
  };

  // Adding Validate Time should be between 09:30AM and 6:30PM
  const validateTime = (inputTime) => {
    const timeRegex = /^(0[9-9]|1[0-8]):([0-5][0-9]):([0-5][0-9])$/;

    if (timeRegex.test(inputTime)) {
      const [hours, minutes, seconds] = inputTime.split(':').map(Number);
      const timeInSeconds = hours * 3600 + minutes * 60 + seconds;
      const startTimeInSeconds = 9 * 3600 + 30 * 60;
      const endTimeInSeconds = 18 * 3600 + 30 * 60;

      if (timeInSeconds >= startTimeInSeconds && timeInSeconds <= endTimeInSeconds) {
        setError('');
        return true;
      } else {
        setError('Time should be between 09:30AM and 6:30PM');
        return false;
      }
    } else {
      setError('Time should be between 09:30AM and 6:30PM');
      return false;
    }
  };

  // handle hours
  const handleHoursChange = (e) => {
    setError('');
    const hour = e.target.value;
    if (hour >= 0 && hour <= 12 && hour.length <= 2) {
      setHours(hour);
    }
  };

  // handle minutes
  const handleMinutesChange = (e) => {
    setError('');
    const mins = e.target.value;
    if (mins >= 0 && mins < 60 && mins.length <= 2) setMinutes(mins);
  };

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.main}>
        <Box sx={{ alignItems: 'center', display: 'flex', gap: 4, marginBottom: '1rem' }}>
          <Typography className={classes.title}>
            {showTimePicker ? type + ' ' + 'Date and Time Selection' : 'Select Date'}
          </Typography>
          <CloseOutlinedIcon onClick={onClose} className={classes.closeBtn} />
        </Box>
        <Divider sx={{ marginBottom: '1.5rem' }} />
        <Grid container gap={4} className={classes.container}>
          {/* date picker */}
          <Grid item xs={12} lg={5.7}>
            <FormControl sx={{ width: { lg: '90%', xs: '100%' } }}>
              <FormLabel>Date</FormLabel>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  readOnly
                  inputFormat="DD/MM/YYYY"
                  value={date}
                  onChange={setDate}
                  helperText={'DD/MM/YYYY'}
                  disableOpenPicker
                  renderInput={(params) => <TextField {...params} className={classes.datePicker} />}
                  maxDate={disableFuture ? moment() : undefined}
                />
              </LocalizationProvider>
              <Typography
                sx={{
                  color: 'rgba(10, 58, 103, 0.6)',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  marginTop: '-4px',
                  paddingLeft: '4px'
                }}>
                DD/MM/YYYY
              </Typography>
            </FormControl>
            <Calendar
              value={date || null}
              onChange={setDate}
              formatShortWeekday={(locale, date) =>
                ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
              }
              formatMonth={(locale, date) => moment(date).format('MMM')}
              formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
              showDoubleView={false}
              minDate={type === 'end' ? moment(startDateAndTime).toDate() : null}
              maxDate={
                type === 'end'
                  ? moment(startDateAndTime).toDate()
                  : disableFuture
                  ? moment().toDate()
                  : null
              }
            />
          </Grid>

          {/* time picker  */}
          {showTimePicker && (
            <Grid item xs={12} lg={5.7}>
              <FormControl sx={{ width: { lg: '90%', xs: '100%' } }}>
                <FormLabel>Time</FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    readOnly
                    value={time}
                    onChange={setTime}
                    disableOpenPicker
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.datePicker}
                        error={Boolean(errorMessage)}
                        helperText={errorMessage}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography
                  sx={{
                    color: 'rgba(10, 58, 103, 0.6)',
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    marginTop: '-4px',
                    paddingLeft: '4px'
                  }}>
                  HH:MM (AM/PM) - 12 hour format
                </Typography>
              </FormControl>

              {/* time custom text field */}
              <Box className={classes.timeContainer}>
                {/* hours */}
                <TextField
                  value={hours}
                  type="number"
                  onChange={(e) => handleHoursChange(e)}
                  className={classes.hourAndMinuteField}
                  helperText="Hour"
                />
                {/* colon */}
                <Typography
                  sx={{
                    color: 'rgba(29, 27, 32, 1)',
                    fontSize: '57px',
                    height: '100%',
                    marginTop: '-54px',
                    padding: '0 12px'
                  }}>
                  :
                </Typography>
                {/* minutes */}
                <TextField
                  value={minutes}
                  type="number"
                  InputProps={{ inputProps: { max: 60, min: 0 } }}
                  inputProps={{ maxLength: 2 }}
                  className={classes.hourAndMinuteField}
                  helperText="Minute"
                  onChange={(e) => handleMinutesChange(e)}
                />
                {/* am/pm tabs */}
                <Tabs
                  orientation="vertical"
                  value={amPmvalue}
                  className={classes.amPmTabs}
                  onChange={(event, newValue) => {
                    setAmPmValue(newValue);
                    setError('');
                  }}>
                  <Tab label="AM" value="AM" sx={{ padding: '4px 0' }} />
                  <Tab
                    sx={{
                      borderTop: '1px solid rgba(10, 58, 103, 1)'
                    }}
                    label="PM"
                    value="PM"
                  />
                </Tabs>
              </Box>
              {workingHours && (
                <Typography
                  sx={{ color: '#bf3333', fontSize: '14px', fontWeight: '500', padding: '12px 0' }}>
                  Note: time should be between 09:30 AM - 06:30 PM
                </Typography>
              )}
            </Grid>
          )}
        </Grid>

        {/* action buttons */}
        <Box
          sx={{
            padding: '36px 0',
            textAlign: 'center'
          }}>
          <Button
            type="reset"
            variant="outlined"
            onClick={onClose}
            className={classes.cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (date && time) setDateAndTime();
            }}
            variant="contained"
            className={classes.okButton}>
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default DateTimePicker;

DateTimePicker.propTypes = {
  disableFuture: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showTimePicker: PropTypes.bool,
  startDateAndTime: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  workingHours: PropTypes.bool.isRequired
};
