import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Drawer, IconButton } from '@mui/material';

import SideMenuBar from 'components/SideMenuBar';
import { userSignout } from 'store/authSlice';
import ImagesLinks from 'utils/ImagesLinks';
import responsiveSideBarStyles from './ResponsiveSideBar.styles';

export default function ResponsiveSideBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = responsiveSideBarStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleLogout = () => dispatch(userSignout());
  const drawerWidth = 240;

  const drawer = (
    <Box>
      <Box sx={{ paddingLeft: '1rem', paddingTop: '1rem' }}>
        <CloseIcon sx={{ color: 'maroon' }} onClick={handleDrawerToggle} />
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '1rem'
        }}>
        <img
          alt="indiassetz"
          style={{ cursor: 'pointer' }}
          src={ImagesLinks.LogoImageDrawer}
          className={classes.leftSideImage}
          onClick={() => navigate('/dashboard')}
        />
      </Box>
      <SideMenuBar />
      <Box>
        <Button className={classes.LogoutBtn} onClick={handleLogout}>
          <img alt="def-img" className={classes.logouticon} src={ImagesLinks.Logout} />
          Logout
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ display: 'none', flexShrink: { sm: 0 }, width: { sm: drawerWidth } }}
        aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          anchor="right"
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          }}>
          {drawer}
        </Drawer>
      </Box>
      <Box className={classes.hamburger}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{
            '@media (max-width:768px)': {
              margin: '0'
            },
            background: '#fff',
            border: '0.5px solid rgba(10, 58, 103, 0.4) !important',
            borderRadius: '8px',
            color: '#0A3A67',
            m: 1,
            padding: '8px .5rem'
          }}>
          <MenuIcon />
        </IconButton>
      </Box>
    </>
  );
}
