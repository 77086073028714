import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const addButtonDropdownStyles = makeStyles({
  iconWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '24px',
    justifyContent: 'center',
    width: '24px'
  },
  menu: {
    '&:before': {
      bgcolor: 'background.paper',
      content: '""',
      display: 'block',
      height: 10,
      position: 'absolute',
      right: 14,
      top: 0,
      transform: 'translateY(-50%) rotate(45deg)',
      width: 10,
      zIndex: 0
    },
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '1rem',
    overflow: 'visible'
  },
  menuItems: {
    '& p': {
      color: COLORS.PRIMARY,
      fontSize: '16px !important',
      fontWeight: '500',
      marginLeft: '8px !important'
    },
    padding: '12px 28px 12px 16px !important'
  }
});

export default addButtonDropdownStyles;
