import { createSlice } from '@reduxjs/toolkit';
import {
  addCallback,
  getBrokerCount,
  getBrokerListData,
  getByIdBroker,
  getCallback,
  postBrokerData,
  putBrokerData,
  updateCallback
} from 'api/broker';
import { toast } from 'react-toastify';

export const brokerFilterInitialValues = {
  branch: '',
  fromDate: '',
  leadSource: '',
  limit: 10,
  page: 0,
  rewm: '',
  sortAt: '',
  sortBy: '',
  toDate: '',
  updatedFromDate: '',
  updatedToDate: ''
};
const brokerSlice = createSlice({
  initialState: {
    filters: brokerFilterInitialValues,
    isLoading: false,
    currentTablePage: 0,
    rowPerPage: 10
  },
  name: 'broker',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
     setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const brokerActions = brokerSlice.actions;

export const handleAddBroker = (params) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await postBrokerData(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleEditBroker = (id, params) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await putBrokerData(id, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleGetListBroker = (filters) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await getBrokerListData(filters);
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleGetByIdBroker = (id) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await getByIdBroker(id);
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleGetBrokerCityCount = () => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await getBrokerCount();
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleAddCallback = (id, params) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await addCallback(id, params);
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleGetCallbackById = (id) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await getCallback(id);
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export const handleUpdateCallback = (id, params) => async (dispatch) => {
  try {
    dispatch(brokerActions.setIsLoading(true));
    const data = await updateCallback(id, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(brokerActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(brokerActions.setIsLoading(false));
  }
};

export default brokerSlice;
