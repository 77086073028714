import { createSlice } from '@reduxjs/toolkit';
import {
  addCallbackComment,
  createCallback,
  deleteCallback,
  getCallbackById,
  getCallbackComments,
  getCallbacks,
  getCallbacksByClientId,
  updateCallback,
  updateCallbackStatus
} from 'api/callback';
import { toast } from 'react-toastify';

export const callbackFilterInitialValues = {
  callbackStatus: '',
  createdFromDate: '',
  createdToDate: '',
  limit: 10,
  page: 0,
  requestedFromDate: '',
  requestedToDate: '',
  rewm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const callbackSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: callbackFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'callback',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const callbackActions = callbackSlice.actions;

export const handleGetCallbacks = (params) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await getCallbacks(params);
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleGetCallbackById = (callbackId) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await getCallbackById(callbackId);
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleCreateCallback = (clientId, params) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await createCallback(clientId, params);
    if (data) {
      toast.success(data?.message || 'CallBack Scheduled Successfully');
    }
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleUpdateCallback = (clientId, callbackId, params) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await updateCallback(clientId, callbackId, params);
    if (data) {
      toast.success(data?.message || 'CallBack Schedule Successfully Updated');
    }
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleUpdateCallbackStatus = (clientId, callbackId, status) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await updateCallbackStatus(clientId, callbackId, status);
    if (data) {
      toast.success(data?.message || 'CallBack Schedule Successfully Updated');
    }
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleDeleteCallback = (clientId, callbackId) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await deleteCallback(clientId, callbackId);
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleGetCallbackComments = (clientId, callbackId) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await getCallbackComments(clientId, callbackId);
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleAddCallbackComment = (clientId, callbackId, comment) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await addCallbackComment(clientId, callbackId, comment);
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export const handleGetCallbackByClientId = (clientId) => async (dispatch) => {
  try {
    dispatch(callbackActions.setIsLoading(true));
    const data = await getCallbacksByClientId(clientId);
    return data;
  } catch (error) {
    dispatch(callbackActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(callbackActions.setIsLoading(false));
  }
};

export default callbackSlice;
