import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { default as loanicon } from 'assets/images/LoansIcon.svg';
import { default as brokerIcon } from 'assets/images/sidecardicons/brokerIcon.svg';
import { default as callbackicon } from 'assets/images/sidecardicons/Callback.svg';
import { default as clientsicon } from 'assets/images/sidecardicons/Clients.svg';
import { default as leadsicon } from 'assets/images/sidecardicons/Leads.svg';
import { default as ordersicon } from 'assets/images/sidecardicons/Orders.svg';
import { default as propertiesicon } from 'assets/images/sidecardicons/Properties.svg';
import { default as venderIcon } from 'assets/images/sidecardicons/venderIcon.svg';
import PropTypes from 'prop-types';
import { rolesEnums } from 'utils/enums';
import addButtonDropdownStyles from './addButtonDropdownStyles';
import SelectionModal from '../SelectionModal/SelectionModal';

export default function AddButtonDropdown({ anchorEl, handleClose, open }) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.role);
  const classes = addButtonDropdownStyles();
  const { clientId } = useParams();
  const [type, setType] = useState('');
  const [isSelectionModalVisible, setIsSelectionModalVisible] = useState(false);

  useEffect(() => {
    if (type && type !== 'client') {
      setIsSelectionModalVisible(true);
    } else if (type === 'client') {
      if (clientId) {
        navigate('/add-client');
        window.location.reload();
      } else {
        navigate('/add-client');
      }
    }
  }, [type, clientId, navigate]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        className={classes.menu}
        PaperProps={{
          elevation: 0
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            setType('client');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={clientsicon} width="100%" height="100%" />
          </ListItemIcon>
          <Typography>Client</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            setType('property');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={propertiesicon} width="100%" height="100%" />
          </ListItemIcon>
          <Typography>Property</Typography>
        </MenuItem>
        {userRole !== rolesEnums.CHANNEL_PARTNER && (
          <>
            <MenuItem
              className={classes.menuItems}
              onClick={() => {
                setType('order');
                handleClose();
              }}>
              <ListItemIcon className={classes.iconWrapper}>
                <img src={ordersicon} width="100%" height="100%" />
              </ListItemIcon>
              <Typography>Order</Typography>
            </MenuItem>
          </>
        )}
        {userRole !== rolesEnums.CHANNEL_PARTNER && (
          <MenuItem
            className={classes.menuItems}
            onClick={() => {
              setType('callback');
              handleClose();
            }}>
            <ListItemIcon className={classes.iconWrapper}>
              <img src={callbackicon} width="120%" height="120%" />
            </ListItemIcon>
            <Typography>Callback</Typography>
          </MenuItem>
        )}
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            navigate('/add-lead');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={leadsicon} width="120%" height="120%" />
          </ListItemIcon>
          <Typography>Lead</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            setType('loan');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={loanicon} width="120%" height="120%" />
          </ListItemIcon>
          <Typography>Loan</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            navigate('/loan/add-lead');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={leadsicon} width="120%" height="120%" />
          </ListItemIcon>
          <Typography>Loan Lead</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            navigate('/add-broker');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={brokerIcon} width="90%" height="90%" />
          </ListItemIcon>
          <Typography>Broker</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItems}
          onClick={() => {
            navigate('/add-vendor');
            handleClose();
          }}>
          <ListItemIcon className={classes.iconWrapper}>
            <img src={venderIcon} width="90%" height="90%" />
          </ListItemIcon>
          <Typography>Vendor</Typography>
        </MenuItem>
      </Menu>
      <SelectionModal
        open={isSelectionModalVisible}
        handleClose={() => setIsSelectionModalVisible(false)}
        type={type}
      />
    </>
  );
}

AddButtonDropdown.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  open: PropTypes.bool
};
