import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import { handleGetPrimarySalesPropertyLiveabilityDetails } from 'features/PrimarySales/Properties/primarySalesPropertySlice';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { Doughnut } from 'react-chartjs-2';
import { handleGetLiveabilityDetails } from 'store/propertySlice';
import { handleGetStPropertyLiveabilityDetails } from 'store/stPropertySlice';
import ImagesLinks from 'utils/ImagesLinks';
import ProgressBar from './component/ProgressBar';
import keyHighlightsStyles from './KeyHighlights.styles';

export default function KeyHighlights({ propId }) {
  const classes = keyHighlightsStyles();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const activePage = pathname?.startsWith('/primary-sales')
    ? 'PrimarySalesProperties'
    : pathname?.startsWith('/st')
    ? 'StProperties'
    : 'Properties';

  const [iconColors, setIconColors] = useState(new Array(10).fill('#0A3A674D'));
  const [arrowColors, setArrowColors] = useState(new Array(10).fill('#0A3A674D'));
  const [triangleColors, setTriangleColors] = useState(new Array(10).fill('#0A3A674D'));
  const [liveabilityDetails, setLiveabilityDetails] = useState(null);

  const iconRefs = useRef([]);
  const arrowRefs = useRef([]);
  const triangleRef = useRef([]);

  useEffect(() => {
    if (propId) {
      getLiveabilityDetails(propId);
    }
  }, [propId]);

  useEffect(() => {
    const observers = iconRefs.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIconColors((colors) => {
              const newColors = [...colors];
              newColors[index] =
                entry.isIntersecting &&
                index <
                  liveabilityDetails?.liveability_connectivity?.[0]?.liveabilityconnectivityscore
                  ? '#0A3A67'
                  : '#0A3A674D';
              return newColors;
            });
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(ref);
      return observer;
    });

    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, [liveabilityDetails]);

  useEffect(() => {
    const observers = arrowRefs.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setArrowColors((colors) => {
              const newColors = [...colors];
              newColors[index] =
                entry.isIntersecting &&
                index < liveabilityDetails?.liveability_aqi?.[0]?.liveabilityaqiscore
                  ? '#0A3A67'
                  : '#0A3A674D';
              return newColors;
            });
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(ref);
      return observer;
    });

    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, [liveabilityDetails]);

  useEffect(() => {
    const observers = triangleRef.current.map((ref, index) => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setTriangleColors((colors) => {
              const newColors = [...colors];
              newColors[index] =
                entry.isIntersecting &&
                index <
                  liveabilityDetails?.liveability_neighbourhood?.[0]?.liveabilityneighbourhoodscore
                  ? '#0A3A67'
                  : '#0A3A674D';
              return newColors;
            });
          });
        },
        { threshold: 0.5 }
      );

      observer.observe(ref);
      return observer;
    });

    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, [liveabilityDetails]);

  const getLiveabilityDetails = async (propertyId) => {
    let response = null;
    if (activePage === 'Properties') {
      response = await dispatch(handleGetLiveabilityDetails(propertyId));
    } else if (activePage === 'StProperties') {
      response = await dispatch(handleGetStPropertyLiveabilityDetails(propertyId));
    } else {
      response = await dispatch(handleGetPrimarySalesPropertyLiveabilityDetails(propertyId));
    }

    setLiveabilityDetails(response?.data?.results);
  };

  const connectivity = [
    {
      color: '#731425',
      imgUrl: ImagesLinks.Bus,
      name: 'Bus',
      score: liveabilityDetails?.liveability_connectivity?.[0]?.busstand
    },
    {
      color: '#D4B415',
      imgUrl: ImagesLinks.Metro,
      name: 'Metro',
      score: liveabilityDetails?.liveability_connectivity?.[0]?.metro
    },
    {
      color: '#DB7E48',
      imgUrl: ImagesLinks.Airport,
      name: 'Airport',
      score: liveabilityDetails?.liveability_connectivity?.[0]?.airport
    }
  ];

  const neighbourhood = [
    {
      imgUrl: ImagesLinks.School,
      name: 'Schools',
      score: liveabilityDetails?.liveability_neighbourhood?.[0]?.school
    },
    {
      imgUrl: ImagesLinks.Restaurants,
      name: 'Restaurants',
      score: liveabilityDetails?.liveability_neighbourhood?.[0]?.restaurant
    },
    {
      imgUrl: ImagesLinks.Park,
      name: 'Parks',
      score: liveabilityDetails?.liveability_neighbourhood?.[0]?.park
    },
    {
      imgUrl: ImagesLinks.Shopping,
      name: 'Shopping',
      score: liveabilityDetails?.liveability_neighbourhood?.[0]?.shopping
    },
    {
      imgUrl: ImagesLinks.Hospital,
      name: 'Hospital',
      score: liveabilityDetails?.liveability_neighbourhood?.[0]?.hospital
    }
  ];

  Chart.register(CategoryScale);

  let aqiAvg = [];
  let aqiLabels = [];
  let aqiMin = [];
  let aqiMax = [];

  liveabilityDetails?.liveability_aqi?.[0]?.aqi_pm?.map((item) => aqiAvg.push(item.avg));
  liveabilityDetails?.liveability_aqi?.[0]?.aqi_pm?.map((item) => aqiMin.push(item.min));
  liveabilityDetails?.liveability_aqi?.[0]?.aqi_pm?.map((item) => aqiMax.push(item.max));
  liveabilityDetails?.liveability_aqi?.[0]?.aqi_pm?.map((item, index) => {
    if (index >= 0 && index < 7) {
      aqiLabels.push(moment(item.day).format('ddd'));
    }
  });

  const aqiColor = (pollutionLevel) => {
    switch (pollutionLevel) {
      case 'Good':
        return '#43976A';
      case 'Moderate':
        return '#D4B415';
      case 'Unhealthy for sensitive groups':
        return '#F19E4B';
      case 'Unhealthy':
        return '#BB2739';
      case 'Very unhealthy':
        return '#5D0E93';
      case 'Hazardous':
        return '#731425';
      default:
        return '#D4B415';
    }
  };

  const overallLiveabilityData = {
    datasets: [
      {
        backgroundColor: ['#fff', '#0A3A67'].reverse(),
        data: [
          100 - liveabilityDetails?.liveability_total_score * 10,
          liveabilityDetails?.liveability_total_score * 10
        ].reverse()
      }
    ]
  };

  const todayAvg = liveabilityDetails?.liveability_aqi?.[0]?.aqi_pm?.find((item) => {
    return item.day === moment().format('YYYY-MM-DD');
  })?.avg;

  const todaysAQILevel = {
    datasets: [
      {
        backgroundColor: [
          '#fff',
          aqiColor(liveabilityDetails?.liveability_aqi?.[0]?.aqi_level_main)
        ].reverse(),
        data: [100 - (todayAvg / 400) * 100, (todayAvg / 400) * 100].reverse()
      }
    ]
  };

  const LineGraphData = {
    datasets: [
      {
        backgroundColor: 'transparent',
        borderColor: '#C4EA04',
        borderWidth: 5,
        data: aqiAvg,
        fill: true,
        lineTension: 0.2,
        pointBackgroundColor: '#0C3A67',
        pointBorderColor: 'white',
        pointBorderWidth: 3,
        pointHoverBackgroundColor: 'white',
        pointHoverBorderColor: 'rgba(255, 99, 132, 1)',
        pointHoverRadius: 6,
        pointRadius: 8
      }
    ],
    labels: aqiLabels
  };

  return liveabilityDetails?.liveability_total_score !== '0' ? (
    <Box className={classes.main} sx={{ border: '1px solid rgba(10, 58, 103, 0.2)' }}>
      <Typography variant="h2" sx={{ mb: '2rem' }}>
        Key highlights
      </Typography>
      <Grid container justifyContent="space-between" columnGap={1}>
        <Grid item lg={5} xs={12} sx={{ paddingTop: '1.4rem' }}>
          <Typography variant="h3">Connectivity</Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ paddingBottom: '2rem', paddingTop: '1rem' }}>
            <Grid item sx={{ alignItems: 'baseline', display: 'flex' }}>
              <Typography className={classes.score}>
                {liveabilityDetails?.liveability_connectivity?.[0]?.liveabilityconnectivityscore ||
                  0}
              </Typography>
              <Typography
                sx={{
                  color: '#DB7E48',
                  fontSize: '40px',
                  fontWeight: '300',
                  lineHeight: '0 !important'
                }}>
                /10
              </Typography>
            </Grid>
            <Grid lg={3.5} sm={4.5} xs={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {Array.from({ length: 10 }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    color: iconColors[index],
                    transition: 'color 1s ease-out',
                    transitionDelay: `${index * 0.2}s`
                  }}
                  ref={(el) => (iconRefs.current[index] = el)}>
                  <CircleIcon />
                </Box>
              ))}
            </Grid>
          </Grid>
          <Stack gap={2}>
            {connectivity?.map((item, index) => (
              <Grid
                container
                key={item.name}
                className={classes.connectivityCard}
                sx={
                  index === 1
                    ? { background: '#0A3A67' }
                    : { background: 'rgba(10, 58, 103, 0.05)' }
                }>
                <Grid item lg={4}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      background: '#fff',
                      border: '1px solid rgba(10, 58, 103, 0.15)',
                      borderRadius: '100%',
                      display: 'flex',
                      height: '2rem',
                      justifyContent: 'center',
                      padding: '12px',
                      width: '2rem'
                    }}>
                    <img src={item.imgUrl} style={{ width: '100%' }} />
                  </Box>
                </Grid>

                <Grid item lg={3}>
                  <Typography sx={{ color: item.color, fontSize: '48px', fontWeight: '600px' }}>
                    {item.score || 0}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography sx={index === 1 ? { color: '#D4B415' } : { color: '#0A3A67' }}>
                    {item.name}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Stack>
        </Grid>

        <Grid
          item
          lg={6.5}
          xs={12}
          className={classes.card}
          sx={{ marginTop: { lg: '0', xs: '2rem' }, position: 'relative' }}>
          <Typography variant="h3" sx={{ mb: '2rem' }}>
            Neighbourhood
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ paddingBottom: '2rem', paddingTop: '1rem' }}>
            <Grid item sx={{ alignItems: 'baseline', display: 'flex' }}>
              <Typography className={classes.score}>
                {liveabilityDetails?.liveability_neighbourhood?.[0]
                  ?.liveabilityneighbourhoodscore || 0}
              </Typography>
              <Typography
                sx={{
                  color: '#DB7E48',
                  fontSize: '40px',
                  fontWeight: '300',
                  lineHeight: '0 !important'
                }}>
                /10
              </Typography>
            </Grid>
            <Grid lg={4} sm={4.5} xs={6} sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {Array.from({ length: 10 }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottomColor: triangleColors[index],
                    borderLeftColor: triangleColors[index],
                    transition: 'borderBottomColor borderLeftColor 1s ease-out',
                    transitionDelay: `${index * 0.2}s`
                  }}
                  className={classes.triangle}
                  ref={(el) => (triangleRef.current[index] = el)}></Box>
              ))}
            </Grid>
          </Grid>
          <Stack gap={2}>
            {neighbourhood?.map((item) => (
              <Grid container key={item.name} alignItems="center">
                <Grid item lg={2} xs={12}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      background: '#fff',
                      borderRadius: '100%',
                      display: 'flex',
                      height: '1.5rem',
                      justifyContent: 'center',
                      padding: '12px',
                      width: '1.5rem'
                    }}>
                    <img src={item.imgUrl} style={{ width: '100%' }} />
                  </Box>
                </Grid>
                <Grid item lg={10} xs={12} className={classes.progress}>
                  <ProgressBar percent={item.score / 10} />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
                    <Typography sx={{ color: '#0A3A6799', fontSize: '12px', fontWeight: '500' }}>
                      {item.name}
                    </Typography>
                    <Typography sx={{ color: '#0A3A67', fontSize: '12px', fontWeight: '500' }}>
                      {item.score || 0}/10
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Stack>
          <Box
            sx={{
              background: `url(${ImagesLinks.NeighbourhoodBg})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              borderTopRightRadius: '30px',
              height: '36%',
              position: 'absolute',
              right: 0,
              top: 0,
              width: '30%'
            }}></Box>
        </Grid>
        <Grid
          item
          lg={7}
          xs={12}
          className={classes.card}
          sx={{ marginTop: '2rem', position: 'relative' }}>
          <Box
            sx={{
              '@media (max-width:768px)': {
                flexDirection: 'column',
                justifyContent: 'flex-start'
              },
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <Typography variant="h3" sx={{ mb: '2rem' }}>
              Air quality
            </Typography>
            <Box>
              <Box
                sx={{
                  '@media (max-width:768px)': {
                    justifyContent: 'flex-start'
                  },
                  alignItems: 'flex-end',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                <Typography sx={{ color: '#0A3A6799', fontSize: '1rem', marginRight: '10px' }}>
                  Pollution level
                </Typography>
                <Typography
                  color={aqiColor(liveabilityDetails?.liveability_aqi?.[0]?.aqi_level)}
                  sx={{ fontSize: '1rem', fontWeight: '600' }}>
                  {liveabilityDetails?.liveability_aqi?.[0]?.aqi_level}
                </Typography>
              </Box>
              <Box
                sx={{
                  '@media (max-width:768px)': {
                    justifyContent: 'flex-start'
                  },
                  alignItems: 'baseline',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '8px'
                }}>
                <Typography sx={{ color: '#0A3A6799', fontSize: '14px', marginRight: '10px' }}>
                  Week’s average
                </Typography>
                <Typography sx={{ color: '#0A3A67', fontSize: '20px', fontWeight: '600' }}>
                  {liveabilityDetails?.liveability_aqi?.[0]?.aqi_avg_weekly_value}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ paddingBottom: '1rem', paddingTop: '2rem' }}>
            <Grid item sx={{ alignItems: 'baseline', display: 'flex' }}>
              <Typography
                className={classes.score}
                color={aqiColor(liveabilityDetails?.liveability_aqi?.[0]?.aqi_level)}>
                {liveabilityDetails?.liveability_aqi?.[0]?.liveabilityaqiscore || 0}
              </Typography>
              <Typography
                color={aqiColor(liveabilityDetails?.liveability_aqi?.[0]?.aqi_level)}
                sx={{
                  fontSize: '40px',
                  fontWeight: '300',
                  lineHeight: '0 !important'
                }}>
                /10
              </Typography>
            </Grid>
            <Grid
              lg={7}
              sm={4.5}
              xs={6}
              sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-end' }}>
              {Array.from({ length: 10 }).map((_, index) => (
                <Box
                  key={index}
                  sx={{
                    color: arrowColors[index],
                    transition: 'color 0.5s ease-out',
                    transitionDelay: `${index * 0.2}s`,
                    width: '1.5rem'
                  }}
                  ref={(el) => (arrowRefs.current[index] = el)}>
                  <ArrowForwardIosIcon sx={{ fontSize: '2.8rem', marginRight: '-18px' }} />
                </Box>
              ))}
              <Box
                sx={{
                  background: `url(${ImagesLinks.AQIBg})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  borderTopRightRadius: '30px',
                  bottom: 0,
                  height: '36%',
                  position: 'absolute',
                  right: 0,
                  width: '50%'
                }}></Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              '@media (max-width:768px)': {
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                margin: '1rem 0'
              },
              alignItems: 'center',
              display: 'flex'
            }}>
            <Box
              sx={{
                position: 'relative',
                width: { lg: '20%', xs: '40%' }
              }}>
              <Doughnut
                data={todaysAQILevel}
                options={{
                  cutout: 40,
                  legend: { reverse: true },
                  maintainAspectRatio: true,
                  responsive: true
                }}
                redraw
              />
              <Box
                sx={{
                  alignItems: 'baseline',
                  display: 'flex',
                  left: '36%',
                  position: 'absolute',
                  top: '42%'
                }}>
                <Typography sx={{ color: '#0A3A67', fontSize: '20px', fontWeight: '600' }}>
                  {todayAvg || 0}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginLeft: '.5rem' }}>
              <Typography sx={{ color: '#0A3A67', fontSize: '18px', fontWeight: '500' }}>
                {liveabilityDetails?.liveability_aqi?.[0]?.aqi_level_main}
              </Typography>
              <Typography sx={{ color: '#0A3A6799', fontSize: '12px', fontWeight: '500' }}>
                {liveabilityDetails?.liveability_aqi?.[0]?.aqi_level_body}
              </Typography>
            </Box>
          </Box>

          <Box>
            <Line
              data={LineGraphData}
              options={{
                plugins: {
                  legend: {
                    display: false
                  },
                  title: {
                    display: false
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tooltipItem) {
                        const min = aqiMin[tooltipItem.dataIndex];
                        const max = aqiMax[tooltipItem.dataIndex];
                        const avg = aqiAvg[tooltipItem.dataIndex];
                        return `Avg: ${avg}, Min: ${min}, Max: ${max}`;
                      }
                    }
                  }
                },
                scales: {
                  x: {
                    grid: {
                      display: false
                    },
                    ticks: {
                      color: aqiLabels.map((day) =>
                        day === moment().format('ddd') ? '#C96869' : '#0A3A6799'
                      ),
                      font: {
                        size: '14px',
                        style: 'Literata',
                        weight: '600'
                      }
                    }
                  },
                  y: {
                    grid: {
                      display: false
                    },
                    ticks: {
                      stepSize: 10
                    }
                  }
                }
              }}
            />
          </Box>
        </Grid>
        <Grid item lg={4.5} xs={12} className={classes.card} sx={{ marginTop: '2rem' }}>
          <Typography variant="h3" sx={{ paddingBottom: '5rem !important' }}>
            Overall liveability
          </Typography>
          <Box
            sx={{
              margin: '0 auto',
              position: 'relative'
            }}>
            <Doughnut
              data={overallLiveabilityData}
              options={{
                cutout: 140,
                legend: { reverse: true },
                maintainAspectRatio: true,
                responsive: true
              }}
              redraw
            />
            <Box
              sx={{
                alignItems: 'baseline',
                display: 'flex',
                left: { lg: '20%', xs: '15%' },
                position: 'absolute',
                top: { lg: '30%', xs: '20%' }
              }}>
              <Typography className={classes.totalScoreValue}>
                {liveabilityDetails?.liveability_total_score || 0}
              </Typography>
              <Typography className={classes.totalScoreValue} sx={{ fontSize: '40px !important' }}>
                /10
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  ) : null;
}

KeyHighlights.propTypes = {
  propId: PropTypes.string
};
