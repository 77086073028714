import { makeStyles } from '@mui/styles';

const propertyDetailsStyles = makeStyles({
  box: {
    alignItems: 'center !important',
    background: 'white !important',
    borderRadius: '20px !important',
    marginBottom: '2rem !important',
    padding: '0'
  },
  clientId: {
    color: '#0A3A67 !important',
    fontSize: '14px !important',
    fontWeight: '400 !important'
  },
  clientName: {
    color: '#0A3A67 !important',
    fontSize: '1rem !important',
    fontWeight: '600 !important',
    textTransform: 'capitalize !important'
  },
  cover: {
    '@media (max-width: 768px)': {
      marginBottom: '1.5rem'
    },
    backgroundPosition: 'center center !important',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover !important',
    borderRadius: '20px 20px 0px 0px',
    height: '22rem !important',
    marginBottom: '6rem',
    position: 'relative !important'
  },
  insight: {
    '& .MuiSvgIcon-root': {
      color: '#0A3A6799',
      fontSize: '2rem !important'
    },
    background: 'rgba(10, 58, 103, 0.05)',
    border: '1px solid #0A3A6726',
    borderRadius: '10px',
    padding: '1rem'
  },
  insightContainer: {
    alignItems: 'center',
    background: 'white !important',
    border: '1px solid rgba(10, 58, 103, 0.20) !important',
    borderRadius: '20px !important',
    justifyContent: 'space-between !important',
    left: '5%',
    padding: '1rem !important',
    position: 'absolute',
    right: '5%',
    width: '90% !important'
  },
  main: {
    '@media (max-width: 768px)': {
      padding: '24px 12px !important'
    },
    '@media (min-width: 1440px)': {
      margin: '0 auto !important',
      width: '1123px !important'
    },
    padding: '24px 54px !important'
  },
  menuBtn: {
    border: '2px solid rgba(10, 58, 103, 0.15) !important',
    borderRadius: '8px !important',
    cursor: 'pointer'
  },
  menuDropdown: {
    '&:before': {
      bgcolor: 'background.paper',
      content: '""',
      display: 'block',
      height: 10,
      position: 'absolute',
      right: 14,
      top: 60,
      transform: 'translateY(-50%) rotate(45deg)',
      width: 10,
      zIndex: 0
    },
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    overflow: 'visible'
  },
  profileCompletionStyle: {
    color: '#75013D',
    fontSize: '1rem',
    fontWeight: '500',
    left: 28,
    position: 'absolute',
    top: 36
  },
  propertyName: {
    '& p': {
      fontSize: '1.5rem !important',
      fontWeight: '600 !important'
    },
    '& svg': {
      '&:hover': {
        background: '#fff',
        color: '#0A3A67'
      },
      border: '1px solid white',
      borderRadius: '6px',
      cursor: 'pointer',
      padding: '4px'
    },
    alignItems: 'center !important',
    backdropFilter: 'blur(20px)',
    background: 'rgba(10, 58, 103, 0.15)',
    borderRadius: '20px 20px 0px 0px',
    color: 'white',
    justifyContent: 'space-between !important',
    padding: '.5rem 1.2rem !important'
  },
  title: {
    '& p': {
      color: '#0A3A67 !important',
      fontSize: '22px !important',
      fontWeight: '500 !important',
      textTransform: 'capitalize !important'
    },
    alignItems: 'center !important',
    background: 'white !important',
    borderRadius: '10px !important',
    boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06) !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    margin: '2rem 0 !important',
    padding: '1rem !important'
  },
  viewBtn: {
    border: '1px solid rgba(10, 58, 103, 0.40) !important',
    borderRadius: '10px !important',
    color: '#0A3A67 !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    padding: '4px 12px !important',
    textTransform: 'capitalize !important'
  },
  viewMoreBtn: {
    border: '2px solid #0A3A67 !important',
    borderRadius: '9px !important',
    color: '#0A3A67 !important',
    fontSize: '1rem !important',
    padding: '4px 1rem !important',
    textTransform: 'capitalize !important',
    verticalAlign: 'medium !important'
  }
});

export default propertyDetailsStyles;
