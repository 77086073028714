import React, { memo } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid as Table } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import dataGridStyles from './DataGrid.styles';

function DataGrid({
  checkboxSelection = false,
  columns,
  headerBgColor = '#0A3A67',
  headerHeight = 36,
  hideFooter = false,
  onPageChange,
  onRowClick,
  page = 0,
  rowCount = 0,
  rows,
  title,
  customStyles = {},
  pageSize,
  onRowsPerPageChange = () => {},
  ...props
}) {
  const classes = dataGridStyles();
  return (
    <Box className={classes.main}>
      {title && <Typography className={classes.title}>{title}</Typography>}
      <Table
        className={classes.table}
        sx={{
          '& .MuiDataGrid-columnHeadersInner': {
            backgroundColor: headerBgColor,
            color: '#fff'
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            height: pageSize * 68 + 'px !important'
          },
          ...customStyles
        }}
        headerHeight={headerHeight}
        autoHeight
        rows={rows}
        columns={columns}
        hideFooter={hideFooter}
        rowCount={rowCount}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        showColumnRightBorder={false}
        showCellRightBorder={false}
        rowHeight={60}
        rowsPerPageOptions={[10, 20, 50, 75, 100]}
        onPageSizeChange={onRowsPerPageChange}
        onRowClick={onRowClick}
        onPageChange={onPageChange}
        page={page}
        pageSize={pageSize}
        checkboxSelection={checkboxSelection}
        paginationMode="server"
        {...props}
      />
    </Box>
  );
}

DataGrid.propTypes = {
  checkboxSelection: PropTypes.bool,
  columns: PropTypes.array,
  customStyles: PropTypes.object,
  headerBgColor: PropTypes.string,
  headerBorderRadius: PropTypes.string,
  headerHeight: PropTypes.number,
  hideFooter: PropTypes.bool,
  onPageChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  rowCount: PropTypes.number,
  rows: PropTypes.array,
  title: PropTypes.string
};

export default memo(DataGrid);
