import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Edit as EditIcon } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material';
import Header from 'components/Header/Header';
import ScheduleCallBackModal from 'features/Magik/components/Modals/ScheduleCallBackModal';
import moment from 'moment';
import {
  handleAddCallback,
  handleGetByIdBroker,
  handleGetCallbackById,
  handleGetListBroker,
  handleUpdateCallback
} from 'store/brokerSlice';
import { scrollToErrors } from 'utils';
import { Callbacks, DateTimePicker, HtmlContent, Loader, SpecialNotes } from 'components';
import brokerDetailsStyles from './DetailsBroker.styles';

export default function BrokerDetails() {
  const classes = brokerDetailsStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { brokerId } = useParams();

  const { prospectiveBuyersDropdownValues } = useSelector((state) => state.magik);

  const [brokerDetails, setBrokerDetails] = useState([]);
  const [openScheduleCallbackModal, setOpenScheduleCallbackModal] = useState(false);
  const [callback, setCallback] = useState([]);

  const [isCallbackEditable, setCallbackEditable] = useState(false);

  const initialValues = {
    callMade: callback?.broker_contacted || false,
    callReceived: callback?.broker_call_received || false,
    remarks: callback?.remarks || '',
    status:
      prospectiveBuyersDropdownValues?.callback_status?.filter(
        (item) => item?.status_name === callback?.status
      )?.[0]?.status_id || ''
  };

  useEffect(() => {
    getBrokerDetails();
  }, []);

  useEffect(() => {
    getCallbacks();
  }, [brokerDetails]);

  const getBrokerDetails = async () => {
    const res = await dispatch(handleGetByIdBroker(brokerId));

    setBrokerDetails(res?.[0] || []);
  };

  const addCallback = async (id, time) => {
    const res = await dispatch(handleAddCallback(id, time));
    if (res) {
      getBrokerDetails();
    }
  };

  const getCallbacks = async () => {
    const res = await dispatch(handleGetListBroker());
    if (res) {
      let arr = res?.[0]?.brokers?.find(
        (item) => item?.broker_user_id === brokerDetails?.broker_user_id
      );
      setCallback(arr?.callbacks?.[0]);
    }
  };

  const updateScheduledCallback = async (values) => {
    const params = {
      call_made: values?.callMade || false,
      call_received: values?.callReceived || false,
      remarks: values?.remarks || '',
      status: values?.status || ''
    };

    const response = await dispatch(handleUpdateCallback(callback?.broker_call_back_id, params));

    if (response) {
      setCallbackEditable(false);
      getBrokerDetails();
    }
  };

  return (
    <>
      <Box className={classes.main}>
        <Header />

        <Box className={classes.titleBox}>
          <Typography className={classes.title}>Broker Details</Typography>
          <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
            {!callback && (
              <Button
                variant="outlined"
                className={classes.btn}
                onClick={() => setOpenScheduleCallbackModal(true)}>
                Add Callback
              </Button>
            )}

            <Button
              variant="outlined"
              className={classes.btn}
              onClick={() => navigate(`/broker-edit/${brokerId}`)}>
              Edit Broker
            </Button>
          </Box>
        </Box>

        {/* Broker details */}
        <Box className={classes.section}>
          <Grid container sx={{ alignItems: 'center', columnGap: '8rem' }}>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Agent name</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.agent_name || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Contact</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.mobile_no || '-'}</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Email</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.email || '-'}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Area of operation</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.area || '-'}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Branch</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.branch || '-'}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.section}>
          <Grid container sx={{ alignItems: 'center', columnGap: '8rem' }}>
            <Grid item xs={12}>
              <Typography sx={{ color: '#0A3A67', fontWeight: '600', marginBottom: '1rem' }}>
                Occupation
              </Typography>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Company Name</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.company_name || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Company Age</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.company_age || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Employee type</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.occupation || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>No of employees</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.no_of_employees || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Designation</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.designation || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ color: '#0A3A6726', marginBottom: '1.5rem', marginTop: '1rem' }} />

          <Grid container sx={{ alignItems: 'center', columnGap: '8rem' }}>
            <Grid item xs={12}>
              <Typography sx={{ color: '#0A3A67', fontWeight: '600', marginBottom: '1rem' }}>
                Additional Details
              </Typography>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Possession</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.possession || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>RERA</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.rera || '-'}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Transactions</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.transactions || '-'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Land</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.land || '-'}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Type</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>{brokerDetails?.type || '-'}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Part of association</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.part_of_association === true
                    ? 'Yes'
                    : brokerDetails?.part_of_association === false
                    ? 'No'
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Ready to use our platform</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.ready_to_use_our_platform || '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Loan/ Mortgages Facilitation</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.loan_mortage_facilitation === true
                    ? 'Yes'
                    : brokerDetails?.loan_mortage_facilitation === false
                    ? 'No'
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>
                  Wants to &lsquo;Join the Waitlist&lsquo; program
                </Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.join_waitlist_program === true
                    ? 'Yes'
                    : brokerDetails?.join_waitlist_program === false
                    ? 'No'
                    : '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ color: '#0A3A6726', marginBottom: '1.5rem', marginTop: '1rem' }} />

          <Grid container sx={{ alignItems: 'center', columnGap: '8rem' }}>
            <Grid item xs={12}>
              <Typography sx={{ color: '#0A3A67', fontWeight: '600', marginBottom: '1rem' }}>
                Marketing Details
              </Typography>
            </Grid>
            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Any CRM being Used</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.crm_used === 'No'
                    ? 'No'
                    : brokerDetails?.crm_used === ''
                    ? '-'
                    : 'Yes'}
                </Typography>
              </Grid>
            </Grid>

            {brokerDetails?.crm_used !== 'No' && brokerDetails?.crm_used !== '' && (
              <Grid
                item
                lg={5}
                container
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '1rem'
                }}>
                <Grid item lg={4} xs={12}>
                  <Typography className={classes.property}>CRM Information</Typography>
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Typography className={classes.value}>
                    {brokerDetails?.crm_used || '-'}
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid
              item
              lg={5}
              container
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '1rem'
              }}>
              <Grid item lg={4} xs={12}>
                <Typography className={classes.property}>Marketing Platform</Typography>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Typography className={classes.value}>
                  {brokerDetails?.portal_for_marketing_properties === 'No'
                    ? 'No'
                    : brokerDetails?.portal_for_marketing_properties === ''
                    ? '-'
                    : 'Yes'}
                </Typography>
              </Grid>
            </Grid>

            {brokerDetails?.portal_for_marketing_properties !== 'No' &&
              brokerDetails?.portal_for_marketing_properties !== '' && (
                <Grid
                  item
                  lg={5}
                  container
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '1rem'
                  }}>
                  <Grid item lg={4} xs={12}>
                    <Typography className={classes.property}>Platform Information</Typography>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Typography className={classes.value}>
                      {brokerDetails?.portal_for_marketing_properties || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Box>

        {callback && (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={updateScheduledCallback}>
            {({ errors, handleChange, handleSubmit, isValid, values, setFieldValue }) => {
              return (
                <Box className={classes.callbackContainer}>
                  <Typography sx={{ color: '#0A3A67', fontWeight: '600', marginBottom: '1rem' }}>
                    Callback Details
                  </Typography>
                  <Box className={classes.firstRow}>
                    <Box className={classes.callbackDateTimeContainer}>
                      <Typography variant="span" className={classes.callbackDateTime}>
                        Call back date & time
                      </Typography>
                      <Typography variant="span" className={classes.callbackDateTime}>
                        {callback?.date_time_requested
                          ? moment(callback?.date_time_requested).format('DD/MM/YYYY hh:mm A')
                          : '-'}
                      </Typography>
                    </Box>
                    <Button
                      size="small"
                      onClick={() => setCallbackEditable(true)}
                      className={classes.editBtn}>
                      <EditIcon sx={{ color: '#0A3A67' }} />
                    </Button>
                  </Box>

                  <Grid container className={classes.outline}>
                    <Grid item lg={3.5} xs={12} sx={{ alignItems: 'center', display: 'flex' }}>
                      <RadioGroup
                        row
                        defaultValue={callback?.broker_contacted}
                        value={values?.callMade}
                        onChange={(e) => setFieldValue('callMade', e.target.value === 'true')}
                        sx={{ alignItems: 'center', display: 'flex' }}>
                        <FormLabel className={classes.radioLabel}>Called ?</FormLabel>
                        <FormControlLabel
                          labelPlacement="end"
                          value={true}
                          control={<Radio />}
                          className={classes.radioButton}
                          label="Yes"
                          disabled={!isCallbackEditable}
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          value={false}
                          control={<Radio />}
                          className={classes.radioButton}
                          label="No"
                          disabled={!isCallbackEditable}
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item lg={3.5} xs={12}>
                      <RadioGroup
                        row
                        defaultValue={callback?.broker_call_received}
                        value={values?.callReceived}
                        onChange={(e) => setFieldValue('callReceived', e.target.value === 'true')}
                        sx={{ alignItems: 'center', display: 'flex' }}>
                        <FormLabel className={classes.radioLabel}>Received?</FormLabel>
                        <FormControlLabel
                          labelPlacement="end"
                          value={true}
                          control={<Radio />}
                          className={classes.radioButton}
                          label="Yes"
                          disabled={!isCallbackEditable}
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          value={false}
                          control={<Radio />}
                          className={classes.radioButton}
                          label="No"
                          disabled={!isCallbackEditable}
                        />
                      </RadioGroup>
                    </Grid>

                    <Grid item lg={3.5} xs={12} sx={{ alignItems: 'center', display: 'flex' }}>
                      <FormLabel className={classes.radioLabel}>Status</FormLabel>
                      <Select
                        size="small"
                        name="status"
                        value={values?.status}
                        disabled={!isCallbackEditable}
                        onChange={handleChange}
                        sx={{
                          background: '#FFF',
                          border: '1px solid  rgba(10, 58, 103, 0.60)',
                          borderRadius: '10px',
                          outline: 'none',
                          width: '100%'
                        }}>
                        {prospectiveBuyersDropdownValues?.callback_status?.map((item) => (
                          <MenuItem key={item?.status_id} value={item?.status_id}>
                            {item?.status_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: '1rem' }}>
                      <FormControl fullWidth>
                        <FormLabel className={classes.radioLabel}>Remarks</FormLabel>
                        <TextField
                          fullWidth
                          value={values?.remarks}
                          placeholder="Add remarks here and then press enter"
                          name="specialNote"
                          variant="outlined"
                          multiline
                          disabled={!isCallbackEditable}
                          onChange={(e) => setFieldValue('remarks', e.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {isCallbackEditable && (
                    <Box sx={{ marginTop: '30px', textAlign: 'center' }}>
                      <Button
                        size="small"
                        sx={{
                          border: '1px solid #0A3A67',
                          borderRadius: '8px',
                          color: '#0A3A67',
                          marginRight: '16px',
                          padding: '8px 30px',
                          textTransform: 'capitalize'
                        }}
                        onClick={() => setCallbackEditable(false)}
                        variant="outlined">
                        Cancel
                      </Button>
                      <Button
                        onClick={() => (isValid ? handleSubmit() : scrollToErrors(errors))}
                        size="small"
                        sx={{
                          '&:hover': {
                            background: 'white',
                            border: '1px solid #0A3A67',
                            color: '#0A3A67'
                          },
                          background: '#0A3A67',
                          borderRadius: '8px',
                          color: 'rgba(236, 238, 245, 1)',
                          padding: '8px 30px',
                          textTransform: 'capitalize'
                        }}>
                        Update
                      </Button>
                    </Box>
                  )}
                </Box>
              );
            }}
          </Formik>
        )}

        <ScheduleCallBackModal
          open={openScheduleCallbackModal}
          setClose={setOpenScheduleCallbackModal}
          handleAddCallback={handleAddCallback}
          getBrokerDetails={getBrokerDetails}
          brokerId={brokerId}
        />
      </Box>
    </>
  );
}
