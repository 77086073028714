import { axiosClient } from 'utils/axiosClient';

export const getLoanLeads = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    fromDate,
    createdFromDate,
    createdToDate,
    toDate,
    updatedFromDate,
    updatedToDate,
    prm,
    source,
    branch,
    sortAt,
    sortBy,
    type,
    temperature,
    rm,
    leadSource
  } = filters;

  const params = {
    branch,
    from_date: createdFromDate || fromDate,
    leadSource,
    lead_prm: prm,
    limit,
    list_type: type || 'ETB',
    offset: limit * page,
    rm_name: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    source,
    temperature,
    to_date: createdToDate || toDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('v1/loans/leads' + queryString).then((resp) => resp?.data);
};

export const getLoanLeadDetails = (id) => {
  return axiosClient.get(`v1/loans/lead/${id}`).then((resp) => resp?.data);
};

export const addLoanLead = (params) => {
  return axiosClient.post('v1/loans/lead/add', params).then((resp) => resp?.data);
};

export const updateLoanLead = (id, params) => {
  return axiosClient.put(`v1/loans/lead/${id}`, params).then((resp) => resp?.data);
};

export const deleteLoanLead = (id) => {
  return axiosClient.delete(`v1/loans/lead/${id}`).then((resp) => resp?.data);
};

export const assignLoanLeadPm = (id, params) => {
  return axiosClient.put(`v1/loans/lead/${id}/assign_pm`, params).then((resp) => resp?.data);
};

export const addLoanLeadComment = (id, params) => {
  return axiosClient.post(`v1/loans/lead/${id}/comments`, params).then((resp) => resp?.data);
};

export const getLoanLeadComments = (id) => {
  return axiosClient.get(`v1/loans/lead/${id}/comments`).then((resp) => resp?.data);
};

export const addLoanLeadCallback = (id, params) => {
  return axiosClient.post(`v1/loans/lead/${id}/call_back`, params).then((resp) => resp?.data);
};

export const loanLeadTypeDropdownValues = () => {
  return axiosClient.get(`v1/loans/lead/dropdown/values`).then((resp) => resp?.data);
};

export const updateLoanLeadTemperature = (loanId, params) => {
  return axiosClient
    .put(`v1/loans/lead/${loanId}/update/temperature`, params)
    .then((resp) => resp?.data);
};

export const updateLoanLeadStatus = (loanId, params) => {
  return axiosClient
    .put(`/v1/loans/lead/${loanId}/update/status`, params)
    .then((resp) => resp?.data);
};
