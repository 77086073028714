import { axiosClient } from '../utils/axiosClient';

const addStClientEndpoint = '/v2/structured_transaction/client/add';
const checkIfStClientMobileExistsEndpoint = '/v2/structured_transaction/mobile_exists';
const checkIfStClientEmailExistsEndpoint = '/v2/structured_transaction/email_exists';

export const getStClients = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    premiumType,
    rewm,
    branchHead,
    rm,
    createdFromDate,
    createdToDate,
    customerType,
    paymentFromDate,
    paymentToDate,
    revenueCollectedBy,
    revenueType,
    channelPartner,
    country,
    deleted,
    ekyc,
    insights,
    referralType,
    appDownloads,
    sortAt,
    sortBy,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    app_downloads: appDownloads, //  total, android, ios
    branch,
    branch_head: branchHead,
    channel_partner: channelPartner,
    country,
    deleted, // total, deleted,
    ekyc, // total, android, ios
    fee_paid_from_date: paymentFromDate,
    fee_paid_to_date: paymentToDate,
    from_date: createdFromDate,
    insights: insights, //  total, verified, jarvis
    limit,
    offset: limit * page,
    portfolio_manager: rewm,
    premium_type: premiumType,
    referral_type: referralType,
    revenue_collected_by: revenueCollectedBy,
    revenue_type: revenueType,
    rm_name: rm,
    sort_at: sortAt, // created_At, updated_at,
    sort_by: sortBy, // asc, desc,
    to_date: createdToDate,
    type: customerType,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient
    .get('/v2/structured_transaction/clients' + queryString)
    .then((resp) => resp?.data);
};

export const getStClientDetails = (clientId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/details/${clientId}`)
    .then((resp) => resp?.data);
};

export const getStClientInsights = (clientId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${clientId}/insights`)
    .then((resp) => resp?.data);
};

export const assignPortfolioManagerToStClient = (clientId, assignedTo) => {
  return axiosClient
    .put(`/v2/structured_transaction/client/${clientId}/assign_rm`, { assigned_to: assignedTo })
    .then((resp) => resp?.data);
};

export const addStClient = (params) => {
  return axiosClient.post(addStClientEndpoint, params).then((resp) => resp?.data);
};

export const updateStClient = (clientId, params) => {
  return axiosClient
    .put(`/v2/structured_transaction/client/${clientId}/update`, params)
    .then((resp) => resp?.data);
};

export const getStClientComments = (clientId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${clientId}/comments`)
    .then((resp) => resp?.data);
};

export const addStClientComment = (clientId, comments) => {
  return axiosClient
    .post(`/v2/structured_transaction/client/${clientId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const checkIfStClientEmailExists = ({ email }) => {
  return axiosClient
    .post(checkIfStClientEmailExistsEndpoint, {
      email
    })
    .then((resp) => resp?.data);
};

export const checkIfStClientMobileExists = ({ country_code, mobile }) => {
  return axiosClient
    .post(checkIfStClientMobileExistsEndpoint, {
      country_code,
      mobile
    })
    .then((resp) => resp?.data);
};

export const updateStClientPremiumType = (clientId, premiumType) => {
  return axiosClient
    .put(`/v2/structured_transaction/client/${clientId}/update/premium`, {
      premium_type: premiumType
    })
    .then((resp) => resp?.data);
};

export const getStClientPayment = (clientId, paymentId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${paymentId}/payments/${clientId}`)
    .then((resp) => resp?.data);
};

export const getStClientPayments = (id) => {
  return axiosClient
    .get('/v2/structured_transaction/client/payments/status/' + id)
    .then((resp) => resp?.data);
};

export const addStClientPaymentDetails = (formData) => {
  return axiosClient
    .post('/v2/structured_transaction/client/payments/fileupload?encoded=false', formData)
    .then((resp) => resp?.data);
};

export const updateStClientPaymentDetails = (formData, paymentId) => {
  return axiosClient
    .put(`/v2/structured_transaction/client/payments/${paymentId}/update`, formData)
    .then((resp) => resp?.data);
};