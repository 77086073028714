import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Modal,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { handleCreateProspectCallBackRequest } from 'features/Magik/magik.slice';
import moment from 'moment';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import scheduledCallbackModalStyles from './ScheduleCallBackModal.styles';
import 'react-calendar/dist/Calendar.css';
import './dateTimePicker.styles.css';

export default function ScheduleCallBackModal({
  open = false,
  setClose = () => {},
  prospect = {},
  getOrder = () => {},
  setRowExpand = () => {},
  handleAddCallback,
  getBrokerDetails,
  brokerId
}) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const classes = scheduledCallbackModalStyles();
  const { currentTablePage, filters } = useSelector((state) => state.magik);

  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [amPmvalue, setAmPmValue] = useState('');

  // on hours, minutes, am/pm value change, set the time value
  useEffect(() => {
    if (hours && minutes && amPmvalue) {
      const formattedDate = moment().format('YYYY-MM-DD');
      const formattedTime = `${hours}:${minutes} ${amPmvalue}`;

      setTime(moment(formattedDate + ' ' + formattedTime).format('YYYY/MM/DD HH:mm:ss'));
    }
  }, [hours, minutes, amPmvalue]);

  async function scheduleCallback() {
    let formattedDate = moment(date).format('YYYY-MM-DD');
    let formattedTime = moment(time).format('HH:mm:ss');

    const formattedDateAndTime = moment(
      formattedDate + ' ' + formattedTime,
      'YYYY-MM-DDTHH:mm:00.000Z'
    );

    let response;

    if (pathname?.includes('/broker')) {
      response = await dispatch(
        handleAddCallback(brokerId, {
          date_time_requested: formattedDateAndTime
        })
      );
    } else {
      response = await dispatch(
        handleCreateProspectCallBackRequest(prospect?.prospectId, formattedDateAndTime)
      );
    }

    if (response) {
      if (pathname?.includes('/broker')) {
        await getBrokerDetails();
      } else if (pathname?.startsWith('/order/transactional-details/')) {
        await getOrder();
        setRowExpand(false);
      } else {
        await getOrder(currentTablePage, 10, filters);
        setRowExpand(false);
      }
      setClose(false);
    }
  }

  // handle hours
  const handleHoursChange = (e) => {
    const hour = e.target.value;
    if (hour >= 0 && hour <= 12 && hour.length <= 2) {
      setHours(hour);
    }
  };

  // handle minutes
  const handleMinutesChange = (e) => {
    const mins = e.target.value;
    if (mins >= 0 && mins < 60 && mins.length <= 2) {
      setMinutes(mins);
    }
  };

  return (
    <Modal open={open} onClose={() => setClose(false)} className={classes.modal}>
      <Box className={classes.box}>
        {/* modal header */}
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: '10px',
              marginRight: '24px',
              padding: '12px',
              width: '100%'
            }}>
            <Typography sx={{ fontSize: '16px' }}>Schedule Callback</Typography>
          </Box>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: 'rgba(10, 58, 103, 0.6)',
              borderWidth: '2px'
            }}
            onClick={() => setClose(false)}>
            <CloseIcon sx={{ color: 'rgba(10, 58, 103, 0.6)' }} />
          </Button>
        </Box>

        <Divider sx={{ color: 'rgba(10, 58, 103, 0.15)', margin: '32px 0' }} />

        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 1)',
            borderRadius: '24px',
            padding: '12px 0'
          }}>
          {/* client info */}

          {prospect && Object.entries(prospect)?.length > 0 && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { lg: 'row', xs: 'column' },
                  justifyContent: 'space-between',
                  padding: '0 20px'
                }}>
                <Box
                  sx={{
                    color: 'rgba(10, 58, 103, 1)',
                    display: 'flex',

                    fontSize: '16px'
                  }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      lineHeight: '24px',
                      textTransform: 'capitalize'
                    }}>
                    {prospect?.name}
                  </Typography>
                  <Typography sx={{ paddingLeft: '8px', textTransform: 'uppercase' }}>
                    ({prospect?.referenceNo})
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { lg: 'row', xs: 'column' }
                    }}>
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                      <MailOutlineIcon />
                      <Typography sx={{ margin: '0 8px' }}>{prospect?.prospectEmail}</Typography>
                    </Box>

                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                      <PhoneOutlinedIcon />
                      <Typography sx={{ margin: '0 8px' }}>
                        {prospect?.prospectPhoneNumber}
                      </Typography>
                    </Box>
                  </Box>
                  <Box>
                    <img src={prospect?.sourceImage} alt="source-image-url" />
                  </Box>
                </Box>
              </Box>

              <Divider sx={{ color: 'rgba(10, 58, 103, 0.15)', margin: '12px 0' }} />
            </>
          )}

          <Grid container gap={4} className={classes.container}>
            {/* date picker */}
            <Grid item xs={12} lg={5.7}>
              <FormControl sx={{ width: { lg: '90%', xs: '100%' } }}>
                <FormLabel>Date</FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    readOnly
                    inputFormat="DD/MM/YYYY"
                    value={date}
                    onChange={setDate}
                    helperText={'DD/MM/YYYY'}
                    disableOpenPicker
                    renderInput={(params) => (
                      <TextField {...params} className={classes.datePicker} />
                    )}
                  />
                </LocalizationProvider>
                <Typography
                  sx={{
                    color: 'rgba(10, 58, 103, 0.6)',
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    marginTop: '-4px',
                    paddingLeft: '4px'
                  }}>
                  DD/MM/YYYY
                </Typography>
              </FormControl>
              <Calendar
                value={date}
                onChange={setDate}
                formatShortWeekday={(locale, date) =>
                  ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
                }
                formatMonth={(locale, date) => moment(date).format('MMM')}
                formatMonthYear={(locale, date) => moment(date).format('MMM YYYY')}
                showDoubleView={false}
              />
            </Grid>

            {/* time picker  */}
            <Grid item xs={12} lg={5.7}>
              <FormControl sx={{ width: { lg: '90%', xs: '100%' } }}>
                <FormLabel>Time</FormLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    readOnly
                    value={time}
                    onChange={setTime}
                    disableOpenPicker
                    renderInput={(params) => (
                      <TextField {...params} className={classes.datePicker} error={false} />
                    )}
                  />
                </LocalizationProvider>
                <Typography
                  sx={{
                    color: 'rgba(10, 58, 103, 0.6)',
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    marginTop: '-4px',
                    paddingLeft: '4px'
                  }}>
                  HH:MM (AM/PM) - 12 hour format
                </Typography>
              </FormControl>

              {/* time custom text field */}
              <Box className={classes.timeContainer}>
                {/* hours */}
                <TextField
                  value={hours}
                  type="number"
                  onChange={(e) => handleHoursChange(e)}
                  className={classes.hourAndMinuteField}
                  helperText="Hour"
                />

                {/* colon */}
                <Typography
                  sx={{
                    color: 'rgba(29, 27, 32, 1)',
                    fontSize: '57px',
                    height: '100%',
                    marginTop: '-54px',
                    padding: '0 12px'
                  }}>
                  :
                </Typography>

                {/* minutes */}
                <TextField
                  value={minutes}
                  type="number"
                  InputProps={{ inputProps: { max: 60, min: 0 } }}
                  inputProps={{ maxLength: 2 }}
                  className={classes.hourAndMinuteField}
                  helperText="Minute"
                  onChange={(e) => handleMinutesChange(e)}
                />

                {/* am/pm tabs */}
                <Tabs
                  orientation="vertical"
                  value={amPmvalue}
                  className={classes.amPmTabs}
                  onChange={(event, newValue) => setAmPmValue(newValue)}>
                  <Tab label="AM" value="AM" sx={{ padding: '4px 0' }} />
                  <Tab
                    sx={{
                      borderTop: '1px solid rgba(10, 58, 103, 1)'
                    }}
                    label="PM"
                    value="PM"
                  />
                </Tabs>
              </Box>
            </Grid>
          </Grid>

          {/* action buttons */}
          <Box
            sx={{
              padding: '24px 0',
              textAlign: 'center'
            }}>
            <Button
              variant="outlined"
              onClick={() => setClose(false)}
              className={classes.cancelButton}>
              Cancel
            </Button>
            <Button onClick={scheduleCallback} variant="contained" className={classes.okButton}>
              Ok
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

ScheduleCallBackModal.propTypes = {
  getOrder: PropTypes.func,
  open: PropTypes.bool,
  prospect: PropTypes.object,
  setClose: PropTypes.func,
  setRowExpand: PropTypes.func
};
