import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const IconButton = ({ onClick, label, icon, sx }) => {
  return (
    <Button
      variant="contained"
      startIcon={icon}
      onClick={onClick}
      sx={{
        '&:hover': {
          background: '#f7f7f7',
          boxShadow: 'none',
          color: '#0A3A67'
        },
        background: '#fff ',
        border: '1px solid rgba(10, 58, 103, 0.15)',
        borderRadius: '10px',
        boxShadow: 'none',
        color: '#0A3A67',
        padding: '4px 2rem',
        textTransform: 'capitalize',
        ...sx
      }}>
      {label}
    </Button>
  );
};

IconButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  sx: PropTypes.object
};

export default IconButton;
