import { makeStyles } from '@mui/styles';

const imagePreviewStyles = makeStyles({
  icons: {
    background: ' rgba(0,0,0,0.3)',
    borderRadius: '10px',
    color: 'white',
    cursor: 'pointer',
    marginRight: '1rem',
    padding: '.5rem'
  },
  iconsContainer: {
    bottom: '-16%',
    paddingBottom: '1rem',
    position: 'absolute',
    textAlign: 'center',
    width: '100%'
  }
});

export default imagePreviewStyles;
