import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

export default function ProgressBar({ percent = 0 }) {
  let widthLg = 440;
  let widthXs = 200;

  const progressLg = percent * widthLg || 0;
  const progressXs = percent * widthXs || 0;

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        border: '1px solid rgba(10, 58, 103, 0.15)',
        borderRadius: '.5rem',
        width: { lg: widthLg, xs: widthXs }
      }}>
      <Box
        sx={{
          backgroundColor: '#0A3A67',
          borderRadius: '1rem',
          height: '10px',
          transition: '6s ease',
          transitionDelay: '0.15s',
          width: { lg: `${progressLg}px`, xs: `${progressXs}px` }
        }}
      />
    </Box>
  );
}

ProgressBar.propTypes = {
  percent: PropTypes.number,
  width: PropTypes.number
};
