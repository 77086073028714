import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const titleContainerStyles = makeStyles({
  button: {
    '@media (max-width:768px)': {
      fontSize: '12px !important',
      lineHeight: '16px !important',
      padding: '11px !important'
    },
    backgroundColor: `${COLORS.PRIMARY} !important`,
    borderRadius: '10px !important',
    color: '#FFF  !important',
    fontFamily: 'Literata !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    letterSpacing: '-0.02em !important',
    padding: '10px 32px !important',
    textTransform: 'capitalize !important'
  },
  main: {
    alignItems: 'center',
    backgroundColor: '#fff',
    border: '1px solid rgba(10, 58, 103, 0.2)',
    borderRadius: '10px',
    boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '35px',
    marginTop: '24px',
    padding: '20px 30px'
  },
  secondaryButton: {
    background: 'white !important',
    border: '1px solid #0F3E6A !important',
    color: `${COLORS.PRIMARY} !important`,
    marginLeft: '0.5rem !important',
    marginRight: '0.5rem !important'
  },
  subtitle: {
    color: 'rgba(10, 58, 103, 0.6)',
    fontSize: '16px !important',
    letterSpacing: '0.01em !important',
    lineHeight: '24px !important'
  },
  title: {
    '@media (max-width:768px)': {
      fontSize: '18px !important'
    },
    color: '#0A3A67',
    fontSize: '22px !important',
    fontWeight: '500 !important',
    lineHeight: '33px !important',
    textTransform: 'capitalize'
  }
});

export default titleContainerStyles;
