import { makeStyles } from '@mui/styles';

const faqModalStyles = makeStyles({
  heading: {
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontSize: '20px !important',
    fontWeight: '400 !important'
  },
  main: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    display: 'block',
    maxHeight: 800,
    position: 'absolute',
    right: '21%',
    top: '7%',
    width: '58%'
  },

  subtitle: {
    color: 'rgba(10, 58, 103, 0.6)',
    fontSize: '14px !important',
    letterSpacing: '0.01em !important',
    lineHeight: '24px !important'
  },
  title: {
    '@media (max-width:768px)': {
      fontSize: '20px !important'
    },
    color: '#0A3A67',
    fontSize: '20px !important',
    fontWeight: '500 !important',
    lineHeight: '33px !important',
    textTransform: 'capitalize'
  }
});

export default faqModalStyles;
