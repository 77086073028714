import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CopyIcon from '@mui/icons-material/ContentCopy';
import { Avatar, Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import SideMenuBar from 'components/SideMenuBar';
import { toast } from 'react-toastify';
import { handleDeleteProfileImg, handleUploadProfileImg } from 'store/mainSlice';
import ImagesLinks from 'utils/ImagesLinks';
import mainSidebarStyles from './MainSidebar.styles';

export default function MainSideBar() {
  const classes = mainSidebarStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { userAvatar, userId, userName, referralCode, referralURL } = useSelector(
    (state) => state?.auth
  );

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const uploadImg = async (file) => {
    const form = new FormData();

    const arr = Array.prototype.slice.call(file);

    arr?.forEach((file) => form.append('file', file));

    const response = await dispatch(handleUploadProfileImg(form));
    if (response) {
      handleClose();
    }
  };

  const deleteImg = async () => {
    const response = await dispatch(handleDeleteProfileImg());

    if (response) {
      handleClose();
    }
  };

  const handleCopyReferralURL = () => {
    navigator.clipboard.writeText(referralURL);
    toast.info('Referral URL Copied ' + referralURL);
  };

  return (
    <Box className={classes.leftSideScreen}>
      <Box
        sx={{
          cursor: 'pointer',
          height: '6rem',
          margin: '0 auto',
          width: '6rem'
        }}
        id="profile-image"
        onClick={handleClick}>
        {userAvatar ? (
          <img
            src={userAvatar || ''}
            style={{ borderRadius: '50%', height: '100%', objectFit: 'cover', width: '100%' }}
          />
        ) : (
          <Avatar sx={{ height: '100%', width: '100%' }} />
        )}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-image'
        }}>
        {!userAvatar && (
          <MenuItem sx={{ color: '#0A3A6799' }} component="label">
            <img src={ImagesLinks.UploadProfileImg.default} style={{ marginRight: '.5rem' }} />
            Upload profile picture
            <input
              type="file"
              hidden
              accept="image/png, image/jpg, image/jpeg, image/webp"
              multiple
              onChange={(e) => uploadImg(e.target.files)}
            />
          </MenuItem>
        )}
        {userAvatar && (
          <MenuItem onClick={deleteImg} sx={{ color: '#0A3A6799' }}>
            <img src={ImagesLinks.RemoveProfileImg.default} style={{ marginRight: '.5rem' }} />
            Remove profile picture
          </MenuItem>
        )}
        {userAvatar && (
          <MenuItem sx={{ color: '#0A3A6799' }} component="label">
            <img src={ImagesLinks.ChangeProfileImg.default} style={{ marginRight: '.5rem' }} />
            Change profile picture
            <input
              type="file"
              hidden
              accept="image/png, image/jpg, image/jpeg, image/webp"
              multiple
              onChange={(e) => uploadImg(e.target.files)}
            />
          </MenuItem>
        )}
      </Menu>
      <Typography sx={{ color: '#0A3A67', fontWeight: '500' }}>{userName}</Typography>
      {userId && (
        <Typography
          sx={{
            color: 'rgba(10, 58, 103, 0.60)',
            fontSize: '14px',
            fontWeight: '400',
            marginTop: '4px'
          }}>
          Partner Code : {userId}
        </Typography>
      )}
      {referralCode && (
        <Typography
          sx={{
            alignItems: 'center',
            color: 'rgba(10, 58, 103, 0.60)',
            display: 'flex',
            fontSize: '14px',
            fontWeight: '400',
            justifyContent: 'center',
            marginBottom: '1rem'
          }}>
          Referral Code : <span style={{ textTransform: 'uppercase' }}> {referralCode}</span>
          <CopyIcon
            sx={{ cursor: 'pointer', fontSize: '20px', marginLeft: '4px' }}
            onClick={handleCopyReferralURL}
          />
        </Typography>
      )}
      <Divider />
      <SideMenuBar />
    </Box>
  );
}
