import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import ImagesLinks from 'utils/ImagesLinks';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children, handleSetError } = this.props;

    if (this.state.hasError) {
      handleSetError(this.state.hasError);
      return (
        <Box
          sx={{
            '@media (max-width: 767px)': {
              padding: '24px 12px'
            },
            '@media (min-width: 1440px)': {
              margin: '0 auto',
              width: '1123px'
            },
            alignItems: 'center',
            backgroundColor: '#E7F1FB',
            boxSizing: 'border-box',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            padding: '24px 54px'
          }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              '@media (max-width: 767px)': {
                flexDirection: 'column-reverse'
              }
            }}>
            <Grid xs={12} sm={5.6} lg={5.6} item>
              <Typography
                sx={{
                  '@media (max-width: 767px)': {
                    fontSize: '30px',
                    marginTop: '2rem',
                    textAlign: 'center'
                  },
                  color: '#0A3A67',
                  fontFamily: 'Lato',
                  fontSize: '45px',
                  fontWeight: '500'
                }}>
                Ooops !!
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 767px)': {
                    fontSize: '18px',
                    textAlign: 'center'
                  },
                  color: '#0A3A67',
                  fontFamily: 'Lato',
                  fontSize: '24px',
                  fontWeight: '500',
                  marginTop: '10px'
                }}>
                Something went wrong!
              </Typography>
              <Typography
                sx={{
                  '@media (max-width: 767px)': {
                    fontSize: '18px',
                    textAlign: 'center',
                    width: '100%'
                  },
                  color: '#0A3A67',
                  fontFamily: 'Lato',
                  fontSize: '24px',
                  fontWeight: '500',
                  marginTop: '30px',
                  width: '60%'
                }}>
                Please try again or connect with the Tech Team
              </Typography>
              <Box
                sx={{
                  '@media (max-width: 767px)': { textAlign: 'center' }
                }}>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={() => (window.location.pathname = '/')}
                  endIcon={<ArrowForwardIcon />}
                  sx={{
                    '@media (max-width: 767px)': {
                      marginTop: '2rem',
                      padding: '8px 24px'
                    },
                    background: '#0A3A67',
                    borderRadius: '10px',
                    marginTop: '75px',
                    padding: '8px 34px',
                    textTransform: 'capitalize'
                  }}>
                  Go back home
                </Button>
              </Box>
            </Grid>
            <Grid xs={8} sm={5.6} lg={5.6} item>
              <img alt="internet-problem" width="100%" src={ImagesLinks.InternetProblem} />
            </Grid>
          </Grid>
        </Box>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  handleSetError: PropTypes.func
};
