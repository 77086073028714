import { makeStyles } from '@mui/styles';

const responsiveSideBarStyles = makeStyles({
  LogoutBtn: {
    alignItems: 'center',
    background: 'transparent',
    color: '#74013c !important',
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Lato',
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    justifyContent: 'flex-start !important',
    marginTop: '40px !important',
    paddingLeft: '2rem !important',
    textTransform: 'capitalize !important'
  },
  hamburger: {
    '@media (max-width: 900px) or (min-width: 1536px)': {
      display: 'block'
    },
    display: 'none'
  },
  leftSideImage: {
    marginTop: '24px'
  },
  list: {
    '& .MuiListItem-root:hover': {
      backgroundColor: '#e5eff9'
    },
    '& .MuiListItemIcon-root': {
      justifyContent: 'center'
    },
    paddingLeft: '1rem !important',
    paddingTop: '1rem !important'
  },
  logouticon: {
    marginRight: '20px',
    width: '18px'
  }
});

export default responsiveSideBarStyles;
