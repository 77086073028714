import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Close as CloseIcon, Download as DownloadIcon } from '@mui/icons-material';
import { Box, IconButton, Modal } from '@mui/material';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import styles from './PDFViewer.styles';

export default function PDFViewer({ open, onClose, url }) {
  const userRole = useSelector((state) => state.auth?.role);
  const classes = styles();

  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Box className={classes.box}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          {userRole === 'Super Admin' && (
            <IconButton
              onClick={() => window.open(url, '_blank')}
              className={classes.closeBtn}
              sx={{ marginRight: '12px' }}>
              <DownloadIcon sx={{ color: '#0A3A67' }} />
            </IconButton>
          )}

          <IconButton onClick={onClose} className={classes.closeBtn}>
            <CloseIcon sx={{ color: '#0A3A67' }} />
          </IconButton>
        </Box>
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from({ length: numPages }).map((_, pageNumber) => (
            <Page
              key={pageNumber}
              pageNumber={pageNumber + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      </Box>
    </Modal>
  );
}

PDFViewer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  url: PropTypes.strings
};
