import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const scheduleCallbackModalStyles = makeStyles({
  amPmTabs: {
    '& .MuiButtonBase-root': {
      minHeight: '40px !important',
      minWidth: '50px !important'
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: 'rgba(10, 58, 103, 0.15)',
      color: COLORS.PRIMARY
    },
    border: '1px solid rgba(10, 58, 103, 1)',
    borderRadius: '4px',
    height: '86px',
    marginLeft: '8px',
    marginTop: '-32px',
    textAlign: 'center'
  },
  box: {
    backgroundColor: 'rgba(229, 239, 249, 1)',
    borderRadius: '10px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    maxHeight: '80vh !important',
    overflowY: 'scroll',
    padding: '2rem',
    width: '900px !important'
  },
  cancelButton: {
    border: '1px solid #0A3A67 !important',
    color: '#0A3A67 !important',
    marginRight: '12px !important',
    minWidth: '140px !important',
    textTransform: 'capitalize !important'
  },
  closeBtn: {
    '&:hover': {
      background: 'white !important',
      border: '1px solid red !important',
      color: 'red !important'
    },
    border: '1px solid #0A3A6799 !important',
    borderRadius: '10px !important',
    cursor: 'pointer !important',
    padding: '.4rem !important'
  },
  container: {
    '@media (max-width: 767px)': {
      padding: '1rem !important'
    },
    background: 'white !important',
    borderRadius: '30px',
    padding: '1rem 2rem !important'
  },
  datePicker: {
    '& .MuiInputBase-input::placeholder': {
      fontStyle: 'italic',
      fontWeight: 'normal'
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '2px solid rgba(10, 58, 103, 0.6)'
    }
  },
  hourAndMinuteField: {
    '& .MuiFormHelperText-root': {
      color: COLORS.PRIMARY,
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 500
    },
    '& .MuiInputBase-input': {
      '@media only screen and ( max-width: 600px)': {
        fontSize: '32px'
      },
      backgroundColor: 'rgba(10, 58, 103, 0.05)',
      borderRadius: '12px !important',
      fontSize: '45px',
      textAlign: 'center'
    },
    '& .MuiInputBase-root': {
      borderRadius: '12px !important'
    },
    '& .MuiOutlinedInput-input:focus': {
      background: '#fff !important',
      border: '2px solid rgba(10, 58, 103, 0.6)'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    width: '96px'
  },
  main: {
    '@media (max-width: 767px)': {
      padding: '1rem !important'
    },
    background: '#E5EFF9',
    borderRadius: '30px',
    height: '100%',
    overflow: 'scroll',
    padding: '2rem'
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh !important',
    justifyContent: 'center',
    width: '100vw !important'
  },
  okButton: {
    background: '#0A3A67 !important',
    borderRadius: '8px !important',
    minWidth: '140px !important'
  },
  timeContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(10, 58, 103, 0.05)',
    borderRadius: '20px',
    display: 'flex',
    padding: '24px 24px 40px 24px'
  },
  title: {
    background: '#fff',
    borderRadius: '10px',
    color: '#0A3A67',
    flex: 1,
    fontSize: '1rem',
    padding: '1rem',
    textTransform: 'capitalize'
  }
});

export default scheduleCallbackModalStyles;
