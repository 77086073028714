import { createSlice } from '@reduxjs/toolkit';
import {
  addLead,
  addLeadCallback,
  addLeadComment,
  assignLeadPm,
  assignLeadRm,
  deleteLead,
  getLeadComments,
  getLeadDetails,
  getLeads,
  updateLead
} from 'api/lead';
import { toast } from 'react-toastify';

export const leadFilterInitialValues = {
  branch: '',
  fromDate: '',
  leadSource: '',
  limit: 10,
  page: 0,
  rewm: '',
  sortAt: '',
  sortBy: '',
  toDate: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const leadRecommendation = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: leadFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'lead',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const leadActions = leadRecommendation.actions;

export const handleGetLeads = (filters) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await getLeads(filters);
    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleGetLeadDetails = (id) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await getLeadDetails(id);
    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleAddLead = (params) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await addLead(params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleUpdateLead = (id, params) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await updateLead(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleDeleteLead = (id) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await deleteLead(id);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleAssignLeadPM = (id, params) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await assignLeadPm(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleAssignLeadRM = (id, params) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await assignLeadRm(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleAddLeadComment = (id, params) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await addLeadComment(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleGetLeadComments = (id) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await getLeadComments(id);
    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export const handleAddLeadCallback = (id, params) => async (dispatch) => {
  try {
    dispatch(leadActions.setIsLoading(true));
    const response = await addLeadCallback(id, params);
    if (response) toast.success(response?.message || 'Callback added successfully');
    return response;
  } catch (error) {
    dispatch(leadActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(leadActions.setIsLoading(false));
  }
};

export default leadRecommendation;
