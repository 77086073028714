import { axiosClient } from '../../../utils/axiosClient';
const propertiesEndpoint = '/v2/primary_sales/clients/properties';
const propertiesListingByClientIdEndpoint = '/v2/primary_sales/client/';
const propertyDetailsEndpoint = '/v2/primary_sales/client/property/';
const clientProperty = '/v2/primary_sales/client/';

export const getPrimarySalesProperties = (filters = {}) => {
  const {
    branch,
    branchHead,
    createdFromDate,
    createdToDate,
    customerType,
    limit,
    page,
    premiumType,
    rewm,
    rm,
    sortAt,
    sortBy,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    branch,
    branch_head: branchHead,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    portfolio_manager: rewm,
    premium_type: premiumType,
    rm_name: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    to_date: createdToDate,
    type: customerType,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(propertiesEndpoint + queryString).then((resp) => resp?.data);
};

export const getPrimarySalesPropertyDetails = (propertyId) => {
  return axiosClient.get(propertyDetailsEndpoint + propertyId).then((resp) => resp?.data);
};

export const getPrimarySalesPropertiesByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient
    .get(
      propertiesListingByClientIdEndpoint + clientId + `/properties?limit=10&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const addPrimarySalesPropertyDetails = (clientId, params) => {
  return axiosClient
    .post(clientProperty + clientId + '/property/add', params)
    .then((resp) => resp?.data);
};

export const updatePrimarySalesPropertyDetails = (clientId, propertyId, params) => {
  return axiosClient
    .put(clientProperty + clientId + '/property/' + propertyId + '/update', params)
    .then((resp) => resp?.data);
};

export const getPrimarySalesPropertyComments = (orderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/property/${orderId}/comments`)
    .then((resp) => resp?.data);
};

export const addPrimarySalesPropertyComment = (propertyId, comments) => {
  return axiosClient
    .post(`/v2/primary_sales/client/property/${propertyId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const uploadPrimarySalesPropertyFile = (clientId, files) => {
  return axiosClient
    .post(`/v2/primary_sales/client/${clientId}/properties/fileupload?encoded=false`, files)
    .then((resp) => resp?.data);
};

export const getPrimarySalesPropertyFiles = (clientId, propertyId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/${clientId}/property/${propertyId}/filelist`)
    .then((resp) => resp?.data);
};

export const deletePrimarySalesPropertyFile = (clientId, propertyId, fileId) => {
  return axiosClient
    .delete(`/v2/primary_sales/client/${clientId}/property/${propertyId}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};

export const getPrimarySalesPropertyLiveabilityDetails = (propertyId) => {
  return axiosClient.get(`/v2/primary_sales/keyhighlights/score?prop_id=${propertyId}`);
};

export const updatePrimarySalesPropertyVerifiedDetails = (propertyId, params) => {
  return axiosClient.put(`/v2/primary_sales/property_verification/${propertyId}/update`, params);
};
