import * as React from 'react';
import Box from '@mui/material/Box';
import ImagesLinks from 'utils/ImagesLinks';

export default function Loader() {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'rgb(0,0,0, 0.9)',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 999
      }}>
      <img src={ImagesLinks.Loader} width={'200px'} />
    </Box>
  );
}
