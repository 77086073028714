import { axiosClient } from '../utils/axiosClient';

export const geteKYCDetails = (clientId) => {
  return axiosClient
    .get(`v1/users/digio/ekyc/details?client_id=${clientId}`)
    .then((resp) => resp?.data);
};

export const createEKYCRequestForAadhar = (params) => {
  return axiosClient.post(`v1/users/digio/ekyc`, params).then((resp) => resp?.data);
};

export const createEKYCRequest = (params) => {
  return axiosClient.post(`v1/users/digio/ekyc/id/upload`, params).then((resp) => resp?.data);
};

export const updateKycStatusForAadhar = (params) => {
  return axiosClient.post(`v1/users/digio/ekyc/status`, params).then((resp) => resp?.data);
};

export const updateKycStatusForPanDLandVID = (params) => {
  return axiosClient
    .post(`v1/users/digio/ekyc/id/upload/status`, params)
    .then((resp) => resp?.data);
};
