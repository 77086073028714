import { createSlice } from '@reduxjs/toolkit';
import {
  addSTAssignPRM,
  addSTAssignStatus,
  addStOrder,
  addStOrderComment,
  addStOrderExtComment,
  addStOrderSubStatus,
  assignStOrderFOS,
  assignStOrderPRM,
  assignStOrderRM,
  getStOrderComments,
  getStOrderDetailsById,
  getStOrderExtComments,
  getStOrdersListing,
  orderAssignConfirmStatus,
  subAssignStOrderRM,
  updateOrderStatus,
  updateSTAssignPRM,
  updateSTAssignStatus,
  uploadStOrderCommentFile
} from 'api/stOrder';
import { toast } from 'react-toastify';

export const nonTransStOrderFilterInitialValues = {
  assignTo: '',
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  orderStatus: '',
  orderType: '',
  rewm: '',
  sortBy: '',
  srtAt: '',
  subAssignTo: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const stOrderSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: nonTransStOrderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10,
    selectedRowsPerPage: 10
  },
  name: 'stOrder',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const stOrderAction = stOrderSlice.actions;

export const handleGetStOrdersListing = (params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await getStOrdersListing(params);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleGetStOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await getStOrderDetailsById(orderId);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleAddStOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await addStOrder(clientId, params);
    if (data) {
      toast.success(data?.message || 'Yard Order Created Successfully');
    }
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleGetStOrderComments = (id) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await getStOrderComments(id);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleAddStOrderComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await addStOrderComment(id, comments);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleAssignStOrderRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await assignStOrderRM(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleAssignStOrderFOS = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await assignStOrderFOS(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleSubAssignStOrderRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await subAssignStOrderRM(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleSTOrderStatusUpdate =
  (clientId, orderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(stOrderAction.setIsLoading(true));
      const data = await updateOrderStatus(clientId, orderId, statusId, subStatusId);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(stOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(stOrderAction.setIsLoading(false));
    }
  };

export const handleSTAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await addSTAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleSTAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await addSTAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleUpdateSTAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await updateSTAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleUpdateSTAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await updateSTAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleSTOrderAssignConfirmStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await orderAssignConfirmStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};


export const handleAddStOrderExtComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await addStOrderExtComment(id, comments);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleGetStOrderExtComments = (id) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await getStOrderExtComments(id);
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export const handleStOrderCommentFileUpload = (commentId, files) => async (dispatch) => {
  try {
    dispatch(stOrderAction.setIsLoading(true));
    const data = await uploadStOrderCommentFile(commentId, files);
    if (data) toast.success('File uploaded successfully!');
    return data;
  } catch (error) {
    dispatch(stOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stOrderAction.setIsLoading(false));
  }
};

export default stOrderSlice;