import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ImagesLinks from 'utils/ImagesLinks';
import selectionModalStyles from './SelectionModal.styles';

export default function SelectionModal({ open, handleClose, type }) {
  const classes = selectionModalStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');

  const handleClick = (index) => {
    setSelected(index);

    if (index === 0) {
      if (type === 'property') navigate('/clients?add=property');
      if (type === 'order') navigate('/clients?add=order');
      if (type === 'callback') navigate('/clients?add=callback');
      if (type === 'loan') navigate('/clients?add=loan');
    }

    if (index === 1) navigate('/add-client');
    handleClose();
  };

  const typeActions = {
    callback: 'Add callback to existing client',
    loan: 'Add loan to existing client',
    order: 'Add order to existing client',
    property: 'Add property to existing client'
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.main}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box className={classes.openBox}>
            <Button onClick={handleClose}>
              <img alt="CloseImage" width="40%" src={ImagesLinks.CloseImageWhite} />
            </Button>
          </Box>
          <Box className={classes.addPropertyOrOrder}>
            {[
              {
                img: ImagesLinks.AddExisting,
                title: typeActions[type] || ''
              },
              {
                img: ImagesLinks.AddNew,
                title: `Add new client`
              }
            ].map((item, index) => (
              <Box
                onClick={() => handleClick(index)}
                key={index}
                className={classes.item}
                sx={{
                  border:
                    index === selected ? '2px solid #0A3A67' : '1px solid rgba(10, 58, 103, 0.1)'
                }}>
                <img src={item.img} alt="category" />
                <Typography className={classes.title}>{item.title}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

SelectionModal.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string
};
