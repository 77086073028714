import { axiosClient } from 'utils/axiosClient';

const getEventsEndpoint = 'v1/employee/events';
const getEventByIdEndpoint = 'v1/employee/event';
const addEventEndpoint = 'v1/employee/event/add';
const updateAndDeleteEventEndpoint = 'v1/employee/event';

export const getEvents = (fromDate, toDate) => {
  return axiosClient
    .get(getEventsEndpoint + `?from_date=${fromDate}&to_date=${toDate}`)
    .then((resp) => resp?.data);
};

export const getEventById = (eventId) => {
  return axiosClient.get(`${getEventByIdEndpoint}/${eventId}`).then((resp) => resp?.data);
};

export const addEvent = (params) => {
  return axiosClient.post(addEventEndpoint, params).then((resp) => resp?.data);
};

export const deleteEvent = (eventId) => {
  return axiosClient
    .delete(`${updateAndDeleteEventEndpoint}/${eventId}`)
    .then((resp) => resp?.data);
};

export const updateEvent = (eventId, params) => {
  return axiosClient
    .put(`${updateAndDeleteEventEndpoint}/${eventId}`, params)
    .then((resp) => resp?.data);
};

export const getEventComments = (eventId) => {
  return axiosClient.get(`${getEventByIdEndpoint}/${eventId}/comments`).then((resp) => resp?.data);
};

export const addEventComment = (eventId, comment) => {
  return axiosClient
    .post(`${getEventByIdEndpoint}/${eventId}/comments`, {
      comments: comment
    })
    .then((resp) => resp?.data);
};

export const getEventReport = (fromDate, toDate, empId) => {
  return axiosClient
    .get(
      `${getEventsEndpoint}/reports?from_date=${fromDate}&to_date=${toDate}${
        empId ? `&employee=${empId}` : ''
      }  `
    )
    .then((resp) => resp?.data);
};

export const getEventInsights = (fromDate, toDate, empId) => {
  return axiosClient
    .get(
      `/v1/employee/events/reports/insights?from_date=${fromDate}&to_date=${toDate}${
        empId ? `&employee=${empId}` : ''
      }`
    )
    .then((resp) => resp?.data);
};

export const uploadCommentFile = (params) => {
  return axiosClient.post(`/v2/event/fileupload?encoded=false`, params).then((resp) => resp?.data);
};

export const getCommentFile = (id) => {
  return axiosClient.get(`/v2/event/${id}/filelist`).then((resp) => resp?.data);
};

export const deleteFile = (eventId, fileId) => {
  return axiosClient
    .delete(`/v2/event/${eventId}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};
