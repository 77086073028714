import { makeStyles } from '@mui/styles';

const specialNotesStyles = makeStyles({
  avatar: {
    cursor: 'pointer',
    height: '54px !important',
    width: '54px !important'
  },
  box: {
    '@media (max-width:768px)': {
      height: '520px !important',
      marginLeft: '1rem',
      padding: '1rem',
      width: '100% !important'
    },
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    height: '620px !important',
    overflowY: 'scroll',
    padding: '2rem'
  },
  commentContainer: {
    borderRadius: '10px',

    margin: '1rem 0',
    padding: '1.2rem'
  },
  commentText: {
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontSize: '14px !important'
  },
  comments: {
    marginTop: '12px',
    paddingRight: '3rem',
    width: '100%'
  },
  createdAt: {
    color: '#6785A1',
    fontSize: '14px !important'
  },
  createdBy: {
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontWeight: '500'
  },
  flex: {
    '& p': { fontSize: '20px !important', fontWeight: '500' },
    '& svg': {
      backgroundColor: '#f4f5f7',
      borderRadius: '5px',
      cursor: 'pointer',
      marginLeft: '1rem',
      padding: '.5rem'
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  modal: {
    '@media (max-width:768px)': {
      width: '95% !important'
    },
    alignItems: 'center',
    display: 'flex',
    height: '100vh !important',
    justifyContent: 'center',
    width: '100vw !important'
  },
  searchBar: {
    backgroundColor: '#f4f5f7',
    borderRadius: '10px',
    flex: 1,
    margin: '1.5rem 0'
  },
  tuneIcon: {
    backgroundColor: '#f4f5f7',
    borderRadius: '5px',
    cursor: 'pointer',
    marginLeft: '.7rem',
    padding: '.7rem'
  }
});

export default specialNotesStyles;
