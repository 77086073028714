import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { handleGetEmployeeDropdownValues } from 'store/dropdownSlice';
import { handleUpdatePropertyVerifiedDetails } from 'store/propertySlice';
import { handleUpdateStPropertyVerifiedDetails } from 'store/stPropertySlice';
import VerifyPropertyStyles from './VerifyProperty.styles';

export default function VerifyProperty({
  openVerifyModal,
  handleCloseVerifyModal,
  property,
  client,
  verifiedDetails,
  getPropertyVerifiedDetails,
  propId,
  getPropertyDetails
}) {
  const classes = VerifyPropertyStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [employeeDropdownValues, setEmployeeDropdownValues] = useState(null);
  const activePage = pathname?.startsWith('/property-details') ? 'Properties' : 'StProperties';

  useEffect(() => {
    getEmployeeDropdownValues();
  }, []);

  const getEmployeeDropdownValues = async () => {
    const response = await dispatch(handleGetEmployeeDropdownValues());
    setEmployeeDropdownValues(response);
  };

  const initialValues = {
    detialsConfirmed: verifiedDetails?.property_details_confirmed_after_property_visit || false,
    documentsVerified: verifiedDetails?.document_uploaded_verified || false,
    fos: verifiedDetails?.assigned_fos || '',
    iaValueVerified: verifiedDetails?.ia_value_verified || false,
    imagesVerified: verifiedDetails?.images_verified || false,
    posted: verifiedDetails?.will_be_posted_on_listing_website || false,
    valueVerified: verifiedDetails?.is_sale_rental_value_verified || false,
    visitDone: verifiedDetails?.property_visited || false
  };

  const handleUpdate = async (values) => {
    let findFos = employeeDropdownValues?.relationship_manager?.find(
      (emp) => emp?.full_name === values?.fos
    );

    let params = {
      assigned_fos: findFos?.emp_id || '',
      document_uploaded_verified: values?.documentsVerified,
      ia_value_verified: values?.iaValueVerified,
      images_verified: values?.imagesVerified,
      is_sale_rental_value_verified: values?.valueVerified,
      property_details_confirmed_after_property_visit: values?.detialsConfirmed,
      property_visited: values?.visitDone,
      will_be_posted_on_listing_website: values?.posted
    };

    let res = null;

    if (activePage === 'Properties') {
      res = await dispatch(handleUpdatePropertyVerifiedDetails(propId, params));
    } else {
      res = await dispatch(handleUpdateStPropertyVerifiedDetails(propId, params));
    }

    if (res) {
      getPropertyVerifiedDetails(propId);
      handleCloseVerifyModal();
      getPropertyDetails(propId);
    }
  };

  console.log(employeeDropdownValues);

  return (
    <Modal open={openVerifyModal} onClose={handleCloseVerifyModal} className={classes.modal}>
      <Box className={classes.box}>
        <Box className={classes.flex}>
          <Typography className={classes.title}>Property Verification</Typography>
          <IconButton
            onClick={handleCloseVerifyModal}
            className={classes.closeBtn}
            sx={{ cursor: 'pointer' }}>
            <CloseIcon sx={{ color: '#0A3A67' }} />
          </IconButton>
        </Box>
        <Divider sx={{ margin: '1.5rem 0' }} />

        <Formik enableReinitialize initialValues={initialValues} onSubmit={handleUpdate}>
          {({ handleSubmit, setFieldValue, handleChange, values }) => {
            return (
              <Box sx={{ backgroundColor: 'white', borderRadius: '30px', padding: '1rem' }}>
                <Box sx={{ display: 'flex', flexDirection: { lg: 'row', xs: 'column' }, gap: 1 }}>
                  <Avatar />
                  <Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Typography sx={{ fontWeight: '500' }}>{client?.full_name}</Typography>
                      <Typography>({property?.customer_id})</Typography>
                    </Box>
                    <Grid container sx={{ gap: 3, marginTop: '.5rem' }}>
                      <Grid
                        item
                        xs={12}
                        lg={5}
                        sx={{ color: 'rgba(10, 58, 103, 0.60)', display: 'flex', gap: 1 }}>
                        <EmailOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography sx={{ fontSize: '14px' }}>{client?.email || '-'}</Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={4}
                        sx={{ color: 'rgba(10, 58, 103, 0.60)', display: 'flex', gap: 1 }}>
                        <LocalPhoneOutlinedIcon sx={{ fontSize: '18px' }} />
                        <Typography sx={{ fontSize: '14px' }}>
                          {client?.country_code
                            ? `+${client?.country_code} ${client?.mobile}`
                            : client?.mobile}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ gap: 1, margin: '1rem 0' }}>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={{
                          alignItems: 'center',
                          border: '1px solid rgba(10, 58, 103, 0.15)',
                          display: 'flex',
                          gap: 2,
                          padding: '6px'
                        }}>
                        <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)', fontSize: '14px' }}>
                          REWM
                        </Typography>
                        <Typography sx={{ fontSize: '14px', minWidth: '128px' }}>
                          {client?.portfolio_manager_details?.portfolio_manager || '-'}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={8}
                        sx={{
                          alignItems: 'center',
                          border: '1px solid rgba(10, 58, 103, 0.15)',
                          display: 'flex',
                          gap: 1,
                          padding: '6px'
                        }}>
                        {/* Sub-Assign FOS */}
                        <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)', fontSize: '14px' }}>
                          Sub-Assign FOS
                        </Typography>
                        <Select
                          variant="standard"
                          name="fos"
                          onChange={handleChange('fos')}
                          value={values?.fos}
                          sx={{
                            '& .MuiSelect-select': {
                              paddingBottom: '0',
                              paddingRight: '6px !important',
                              paddingTop: '0 !important'
                            },
                            '&::before': {
                              borderBottom: 'none !important'
                            },
                            border: 'none !important',
                            color: '#0A3A67',
                            fontSize: '14px',
                            fontWeight: '400 !important',
                            outline: 'none !important',
                            width: '180px'
                          }}>
                          {employeeDropdownValues?.relationship_manager?.map((emp) => (
                            <MenuItem
                              key={emp?.emp_id}
                              value={emp?.full_name}
                              className={classes.options}>
                              {emp?.full_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Divider sx={{ marginTop: '.5rem' }} />

                <Stack spacing={1}>
                  <FormGroup>
                    <FormControlLabel
                      value="visitDone"
                      control={
                        <Checkbox
                          checked={values?.visitDone}
                          onChange={(e) => {
                            setFieldValue('visitDone', e.target.checked);
                          }}
                        />
                      }
                      label="Property Visit done ?"
                      sx={{ margin: '.5rem 0' }}
                    />
                    <Divider />
                    <FormControlLabel
                      value="imagesVerified"
                      control={
                        <Checkbox
                          checked={values?.imagesVerified}
                          onChange={(e) => {
                            setFieldValue('imagesVerified', e.target.checked);
                          }}
                        />
                      }
                      label="Are all the images verified?"
                      sx={{ margin: '.5rem 0' }}
                    />
                    <Divider />
                    <FormControlLabel
                      value="detialsConfirmed"
                      control={
                        <Checkbox
                          checked={values?.detialsConfirmed}
                          onChange={(e) => {
                            setFieldValue('detialsConfirmed', e.target.checked);
                          }}
                        />
                      }
                      label="All property details are confirmed after the Property Visit ?"
                      sx={{ margin: '.5rem 0' }}
                    />
                    <Divider />
                    <FormControlLabel
                      value="valueVerified"
                      control={
                        <Checkbox
                          checked={values?.valueVerified}
                          onChange={(e) => {
                            setFieldValue('valueVerified', e.target.checked);
                          }}
                        />
                      }
                      label="Is the Sale/ Rental Value Verified ?"
                      sx={{ margin: '.5rem 0' }}
                    />
                    <Divider />
                    <FormControlLabel
                      value="iaValueVerified"
                      control={
                        <Checkbox
                          checked={values?.iaValueVerified}
                          onChange={(e) => {
                            setFieldValue('iaValueVerified', e.target.checked);
                          }}
                        />
                      }
                      label="Is the IA Value Verified ?"
                      sx={{ margin: '.5rem 0' }}
                    />
                    <Divider />
                    <FormControlLabel
                      value="documentsVerified"
                      control={
                        <Checkbox
                          checked={values?.documentsVerified}
                          onChange={(e) => {
                            setFieldValue('documentsVerified', e.target.checked);
                          }}
                        />
                      }
                      label="Are all the documents uploaded and verified?"
                      sx={{ margin: '.5rem 0' }}
                    />
                    <Divider />
                    <FormControlLabel
                      value="posted"
                      control={
                        <Checkbox
                          checked={values?.posted}
                          onChange={(e) => {
                            setFieldValue('posted', e.target.checked);
                          }}
                        />
                      }
                      label="Will this be posted on other listing website as well ?"
                      sx={{ margin: '.5rem 0' }}
                    />
                  </FormGroup>
                </Stack>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 1,
                    justifyContent: 'center',
                    marginTop: '1rem'
                  }}>
                  <Button
                    variant="outlined"
                    onClick={handleCloseVerifyModal}
                    className={classes.btn}
                    sx={{ border: '1px solid #0A3A67', color: '#0A3A67' }}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    className={classes.btn}
                    sx={{ background: '#0A3A67' }}>
                    Submit
                  </Button>
                </Box>
              </Box>
            );
          }}
        </Formik>
      </Box>
    </Modal>
  );
}

VerifyProperty.propTypes = {
  client: PropTypes.array,
  getPropertyDetails: PropTypes.func,
  getPropertyVerifiedDetails: PropTypes.func,
  handleCloseVerifyModal: PropTypes.func,
  openVerifyModal: PropTypes.bool,
  propId: PropTypes.string,
  property: PropTypes.array,
  setVerifiedDetails: PropTypes.func,
  verifiedDetails: PropTypes.array
};
