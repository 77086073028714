import { createSlice } from '@reduxjs/toolkit';
import {
  addAssignPRM,
  addAssignPRMTaxAssessment,
  addAssignStatus,
  addAssignStatusTaxAssessment,
  addNormalOrderSubStatus,
  addNormalPerformancePoint,
  addOrder,
  addOrderComment,
  addOrderExtComment,
  addOrderExtSubComment,
  addPayment,
  addPaymentsInNormalOrderSubStatus,
  addPaymentTaxAssessment,
  addQuotation,
  advisoryReportUpload,
  assignNonTransOrderRM,
  assignToTaxAssessment,
  assignToVista,
  cancelOrder,
  createAssessment,
  deleteFile,
  editPayment,
  getAdvisoryById,
  getAdvisoryType,
  getFiles,
  getNormalPerformancePointList,
  getOrderComments,
  getOrderDetails,
  getOrderExtComments,
  getOrderExtSubComments,
  getOrdersListing,
  getOrdersListingByClientId,
  getPaymentList,
  getPaymentsTaxAssessment,
  getPerformanceCredit,
  getPerformanceCreditTaxAssessment,
  getTaxAssessmentById,
  getTaxAssessmentDropdown,
  getTaxAssessmentListing,
  getTaxAssessmentOrdersListingByClientId,
  getTaxAssessmentOrdersListingByPropertyId,
  getVistaOrdersListingByClientId,
  getVistaOrdersListingByPropertyId,
  orderAssignConfirmStatus,
  postAdvisory,
  postTaxAssessment,
  searchInNormalComment,
  subAssignToTaxAssessment,
  subAssignToVista,
  updateAssignPRM,
  updateAssignStatus,
  updateOrder,
  updateOrderStatus,
  updateSubAssignFOS,
  updateSubAssignRM,
  updateTaxAssessmentStatus,
  uploadFile,
  uploadNormalOrderCommentFile,
  uploadTaxReceipt,
  verifyPayment,
  verifyPaymentByBranchHead,
  verifyPaymentByNationalHead,
  verifyTDS
} from 'api/order';
import { toast } from 'react-toastify';

export const nonTransOrderFilterInitialValues = {
  assignTo: '',
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  orderStatus: '',
  orderType: '',
  rewm: '',
  sortBy: '',
  srtAt: '',
  subAssignTo: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const orderSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: nonTransOrderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'order',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const orderAction = orderSlice.actions;

export const handleGetOrdersListing = (params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getOrdersListing(params);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetTaxAssessmentListing = (params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getTaxAssessmentListing(params);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetOrderDetails = (orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getOrderDetails(orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetVistaOrdersListingByClientId = (clientId, page) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getVistaOrdersListingByClientId(clientId, page);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetTaxAssessmentOrdersListingByClientId =
  (clientId, page) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await getTaxAssessmentOrdersListingByClientId(clientId, page);
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleGetVistaOrdersListingByPropertyId = (clientId, page) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getVistaOrdersListingByPropertyId(clientId, page);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetTaxAssessmentOrdersListingByPropertyId =
  (clientId, page) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await getTaxAssessmentOrdersListingByPropertyId(clientId, page);
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleGetOrdersListingByClientId = (clientId, page) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getOrdersListingByClientId(clientId, page);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addOrder(clientId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleCancelOrder = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await cancelOrder(clientId, orderId);
    if (data) toast.success(data?.message || 'Order cancelled successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleUpdateOrder = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await updateOrder(clientId, orderId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetOrderComments = (id) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getOrderComments(id);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddOrderComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addOrderComment(id, comments);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAssignNonTransOrderRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await assignNonTransOrderRM(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAssignToVista = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await assignToVista(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAssignToTaxAssessment = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await assignToTaxAssessment(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleSubAssignToVista = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await subAssignToVista(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleSubAssignToTaxAssessment = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await subAssignToTaxAssessment(clientId, orderId, params);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAdvisoryOrderReportFileUpload = (clientId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await advisoryReportUpload(clientId, params);
    if (data) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleOrderFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await uploadFile(clientId, files);
    if (data) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetOrderFiles = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getFiles(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleDeleteOrderFile = (clientId, orderId, fileId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await deleteFile(clientId, orderId, fileId);
    if (data) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetOrderExtComments = (id) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getOrderExtComments(id);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddOrderExtComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addOrderExtComment(id, comments);
    if (data) toast.success(data?.message || 'Comment added successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetOrderExtSubComments = (id) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getOrderExtSubComments(id);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddOrderExtSubComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addOrderExtSubComment(id, comments);
    if (data) toast.success(data?.message || 'Comment Added Successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleNormalOrderCommentFileUpload = (commentId, files) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await uploadNormalOrderCommentFile(commentId, files);
    if (data) toast.success('File uploaded successfully!');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleSearchInNormalComment =
  (offset, limit, search_param, orderId) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await searchInNormalComment(offset, limit, search_param, orderId);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleOrderStatusUpdate =
  (clientId, orderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await updateOrderStatus(clientId, orderId, statusId, subStatusId);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleSubAssignFOS = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await updateSubAssignFOS(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleSubAssignRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await updateSubAssignRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddNormalOrderSubStatus = (clientId, formData) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addNormalOrderSubStatus(clientId, formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddPaymentsInNormalOrderSubStatus = (formData) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addPaymentsInNormalOrderSubStatus(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddPayment = (formData) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addPayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetPerformanceCredit = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getPerformanceCredit(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetPayments = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getPaymentList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleVerifyTDS = (clientId, orderId, isTDSVerified) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await verifyTDS(clientId, orderId, isTDSVerified);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleVerifyPayment =
  (clientId, orderId, isPaymentVerified, emailId) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await verifyPayment(clientId, orderId, isPaymentVerified, emailId);
      if (data) toast.success(data?.message || 'Order updated successfully');
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleVerifyPaymentByBranchHead =
  (clientId, orderId, isPaymentVerifiedByBranchHead, prmEmailId, bhEmailId) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await verifyPaymentByBranchHead(
        clientId,
        orderId,
        isPaymentVerifiedByBranchHead,
        prmEmailId,
        bhEmailId
      );
      if (data) toast.success(data?.message || 'Order updated successfully');
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleVerifyPaymentByNationalHead =
  (clientId, orderId, isPaymentVerifiedByNationalHead, bhEmailId) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await verifyPaymentByNationalHead(
        clientId,
        orderId,
        isPaymentVerifiedByNationalHead,
        bhEmailId
      );
      if (data) toast.success(data?.message || 'Order updated successfully');
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handlePostAdvisory = (paymentId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await postAdvisory(paymentId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetAdvisoryTypeDropdown = () => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getAdvisoryType();

    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetTaxAssessmentTypeDropdown = () => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getTaxAssessmentDropdown();

    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handlePostTaxAssessment = (clientId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await postTaxAssessment(clientId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleEditPayment = (paymentId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await editPayment(paymentId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleUpdateAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await updateAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleUpdateAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await updateAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetTaxAssessmentDropdown = () => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getTaxAssessmentDropdown();

    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetTaxAssessmentDetails = (id) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getTaxAssessmentById(id);

    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetAdvisoryDetails = (id) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getAdvisoryById(id);

    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetNormalPerformancePointList = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getNormalPerformancePointList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddNormalPerformancePoint = (formData) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addNormalPerformancePoint(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleCreateTaxAssessment = (clientId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await createAssessment(clientId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleUpdateTaxAssessmentStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await updateTaxAssessmentStatus(clientId, orderId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddQuotationTaxAssessment = (clientId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addQuotation(clientId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleUploadTaxReceipt = (clientId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await uploadTaxReceipt(clientId, params);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddAssignStatusTaxAssessment =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await addAssignStatusTaxAssessment(clientId, orderId, params);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleAddAssignPRMStatusTaxAssessment =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(orderAction.setIsLoading(true));
      const data = await addAssignPRMTaxAssessment(clientId, orderId, params);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(orderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(orderAction.setIsLoading(false));
    }
  };

export const handleOrderAssignConfirmStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await orderAssignConfirmStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleAddPaymentTaxAssessment = (params) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await addPaymentTaxAssessment(params);
    if (data) toast.success('Payment added successfully');
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetPaymentsTaxAssessment = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getPaymentsTaxAssessment(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export const handleGetPerformanceCreditTaxAssessment = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(orderAction.setIsLoading(true));
    const data = await getPerformanceCreditTaxAssessment(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(orderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(orderAction.setIsLoading(false));
  }
};

export default orderSlice;