import { axiosClient } from 'utils/axiosClient';

export const addStProspectiveBuyer = (params) => {
  return axiosClient
    .post('/v2/structured_transaction/prospect/add', params)
    .then((resp) => resp?.data);
};

export const createStProspectCallBackRequest = (id, dateTimeRequested) => {
  return axiosClient
    .post(`/v2/structured_transaction/prospect/${id}/call_back`, {
      date_time_requested: dateTimeRequested || null
    })
    .then((resp) => resp?.data);
};

export const updateStProspectCallBackRequest = (id, params) => {
  return axiosClient
    .put(`/v2/structured_transaction/prospect/call_back/${id}`, {
      call_made: params.callMade || false,
      call_received: params.callReceived || false,
      remarks: params?.remarks || '',
      status: params?.status || ''
    })
    .then((resp) => resp?.data);
};

export const updateStProspect = (id, params) => {
  return axiosClient
    .put(`/v2/structured_transaction/prospect/${id}/update`, {
      temperature: params?.temperature || ''
    })
    .then((resp) => resp?.data);
};

export const addStCallbackRemarks = (callbackId, params) => {
  return axiosClient
    .post(`/v2/structured_transaction/prospect/call_back/${callbackId}/remarks`, params)
    .then((resp) => resp?.data);
};
