export const numDifferentiation = (val, hideDecimalValues = '') => {
  if (hideDecimalValues === 'hideDecimalValues') {
    if (val >= 10000000) {
      val = Math.floor(val / 10000000) + ' Cr';
    } else if (val >= 100000) {
      val = Math.floor(val / 100000) + ' Lac';
    } else if (val >= 1000) {
      val = Math.floor(val / 1000) + ' K';
    }
  } else {
    if (val >= 10000000) {
      val = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      val = (val / 100000).toFixed(2) + ' Lac';
    } else if (val >= 1000 || val >= 0) {
      val = (val / 1000).toFixed(2) + ' K';
    }
  }
  return val;
};

export const scrollToErrors = (errors) => {
  const errorKeys = Object.keys(errors);
  if (errorKeys?.length > 0) {
    document.getElementsByName(errorKeys?.[0])?.[0]?.focus();
  }
};
