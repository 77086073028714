import { makeStyles } from '@mui/styles';

const selectionModalStyles = makeStyles({
  addPropertyOrOrder: {
    '@media (max-width:768px)': {
      flexDirection: 'column',
      padding: '12px'
    },
    alignItems: 'center',
    backgroundColor: '#fff',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    color: '#0A3A67',
    display: 'flex',
    fontSize: '24px',
    fontWeight: '500',
    justifyContent: 'center',
    padding: '24px 80px',
    textAlign: 'center'
  },
  item: {
    '@media (max-width:768px)': {
      '& img': {
        width: '50%'
      },
      margin: '1rem 0'
    },
    borderRadius: '30px',
    cursor: 'pointer',
    margin: '0 12px',
    maxWidth: '240px',
    padding: '12px'
  },
  main: {
    alignItems: 'center',
    borderRadius: '20px',
    color: '#0a3a67',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    width: '100vw'
  },
  openBox: {
    alignItems: 'center',
    backgroundColor: '#0a3a67',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '50px',
    width: '100%'
  },
  title: {
    color: '#0A3A67',
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: '600',
    letterSpacing: '-0.02em',
    marginBottom: '1.5rem',
    marginTop: '.7rem',
    padding: '0 2rem',
    textAlign: 'center'
  }
});

export default selectionModalStyles;
