import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { Refresh as RefreshIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Badge, Box, Chip, Divider, Grid, Modal, Typography } from '@mui/material';
import NotificationCard from 'components/NotificationCard';
import PropTypes from 'prop-types';
import { handleGetNotifications } from 'store/notificationSlice';
import notificationModalStyles from './NotificationModal.styles';

// Notification Filter Chip
const notificationFilter = {
  '&: hover': {
    backgroundColor: '#0e3e6a !important',
    color: '#fff !important'
  },
  backgroundColor: '#0A3A670D !important',
  border: '1px solid rgba(10, 58, 103, 0.15) !important',
  marginBottom: '4px',
  padding: '6px 4px !important',
  textTransform: 'capitalize'
};
// Active Notification Filter Chip
const activeNotificationFilter = {
  backgroundColor: '#0e3e6a !important',
  border: '1px solid rgba(10, 58, 103, 0.15) !important',
  color: '#fff !important',
  marginBottom: '4px',
  padding: '6px 4px !important',
  textTransform: 'capitalize'
};

// Badge Notification Count Icon CSS
const badgeNotification = {
  '& .MuiBadge-badge': {
    backgroundColor: '#FF6B6B'
  },
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: '10px'
};

export default function NotificationModal({
  isNotificationModalVisible = false,
  setIsNotificationModalVisible = () => {},
  notifications = {},
  notificationsCount = 0
}) {
  const classes = notificationModalStyles();
  const dispatch = useDispatch();

  const [notificationByDate, setNotificationByDate] = React.useState([]);
  const [activeNotification, setActiveNotication] = useState('all_notification');

  useEffect(() => {
    handleGetList(activeNotification || 'all_notification');
  }, [activeNotification, notificationsCount]);

  const handleGetList = (keyValues) => {
    const notification = [];
    let notification1 = null;
    const keyNames = Object?.keys(notifications?.[0] || {});
    setActiveNotication(keyValues);

    if (keyValues === 'all_notification') {
      keyNames.forEach((items) => {
        if (notifications?.[0]?.[items]?.[0]?.details) {
          notifications?.[0]?.[items]?.[0]?.details?.map((value) => {
            notification.push(value);
          });
        }
      });
    } else {
      if (notifications?.[0]?.[keyValues]?.[0]?.details?.length) {
        notification1 = notifications?.[0]?.[keyValues]?.[0]?.details;
      }
    }
    const notificationListItems = keyValues === 'all_notification' ? notification : notification1;
    sortedByDate(notificationListItems);
  };
  const getNotifications = async () => await dispatch(handleGetNotifications());

  const sortedByDate = (notificationList) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const categorized = {
      older: [],
      today: [],
      yesterday: []
    };

    notificationList?.forEach((notificationList) => {
      const notificationDate = new Date(notificationList?.created_at);
      if (
        notificationDate.getDate() === today.getDate() &&
        notificationDate.getMonth() === today.getMonth() &&
        notificationDate.getFullYear() === today.getFullYear()
      ) {
        categorized.today.push(notificationList);
      } else if (
        notificationDate.getDate() === yesterday.getDate() &&
        notificationDate.getMonth() === yesterday.getMonth() &&
        notificationDate.getFullYear() === yesterday.getFullYear()
      ) {
        categorized.yesterday.push(notificationList);
      } else {
        categorized.older.push(notificationList);
      }
    });

    setNotificationByDate(categorized);
  };

  return (
    <Modal open={isNotificationModalVisible} onClose={() => setIsNotificationModalVisible(false)}>
      <Box className={classes.main}>
        <Box
          sx={{
            backgroundColor: '#0A3A6726',
            padding: '1rem 0'
          }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 1rem'
            }}>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
              <Typography className={classes.heading}>Notification</Typography>
              <Typography className={classes.heading}>({notificationsCount})</Typography>
            </Box>
            <CloseIcon
              onClick={() => setIsNotificationModalVisible(false)}
              sx={{
                '&: hover': {
                  background: '#0A3A670D'
                },
                border: '1px solid rgba(10, 58, 103, 0.6)',
                borderRadius: '6px',
                color: '#0A3A67',
                cursor: 'pointer',
                padding: '.3rem'
              }}
            />
          </Box>
        </Box>
        {Boolean(notificationsCount) && (
          <>
            <Box
              rowGap={1}
              columnGap={1}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                margin: '1rem 0 .5rem 0',
                padding: '0 1rem'
              }}>
              {/* All Notification */}
              <Grid>
                <Badge
                  sx={badgeNotification}
                  color="secondary"
                  badgeContent={notificationsCount || 0}
                  max={99}
                  invisible={false}
                />
                <Chip
                  label={`All Notifications`}
                  onClick={() => {
                    handleGetList('all_notification');
                  }}
                  sx={
                    activeNotification === 'all_notification'
                      ? activeNotificationFilter
                      : notificationFilter
                  }
                />
              </Grid>

              {Object.keys(notifications?.[0]).map((value) => {
                return (
                  <>
                    {notifications?.[0]?.[value]?.[0]?.details?.length && (
                      <Grid>
                        <Badge
                          sx={badgeNotification}
                          color="secondary"
                          badgeContent={notifications?.[0]?.[value]?.[0]?.notification_count || 0}
                          max={99}
                          invisible={false}
                        />
                        <Chip
                          label={`${value === 'prospects' ? 'Buyers' : value.replaceAll('_', ' ')}`}
                          onClick={() => {
                            handleGetList(`${value}`);
                          }}
                          sx={
                            activeNotification === value
                              ? activeNotificationFilter
                              : notificationFilter
                          }
                        />
                      </Grid>
                    )}
                  </>
                );
              })}
            </Box>
            <Divider />
          </>
        )}

        <Box sx={{ height: '500px', overflow: 'scroll' }}>
          {notificationByDate?.today?.length ||
          notificationByDate?.yesterday?.length ||
          notificationByDate?.older?.length ? (
            <>
              {Boolean(notificationByDate?.today?.length) && (
                <Box sx={{ marginBottom: '5px' }}>
                  <Typography
                    sx={{
                      color: '#0A3A67',
                      fontSize: '15px',
                      fontWeight: 500,
                      margin: '.5rem 0',
                      paddingLeft: '16px'
                    }}>
                    Today ({notificationByDate?.today?.length || 0})
                  </Typography>
                  <Divider />
                  {notificationByDate?.today?.length > 0 &&
                    notificationByDate?.today?.map((item) => (
                      <NotificationCard
                        key={item?.notification_id}
                        notification={item}
                        handleGetUpdateNotifiList={handleGetList}
                        setIsNotificationModalVisible={setIsNotificationModalVisible}
                      />
                    ))}
                </Box>
              )}
              {Boolean(notificationByDate?.yesterday?.length) && (
                <Box sx={{ marginBottom: '5px' }}>
                  <Typography
                    sx={{
                      color: '#0A3A67',
                      fontSize: '15px',
                      fontWeight: 500,
                      margin: '.5rem 0',
                      paddingLeft: '16px'
                    }}>
                    Yesterday ({notificationByDate?.yesterday?.length || 0})
                  </Typography>
                  <Divider />
                  {notificationByDate?.yesterday?.length > 0 &&
                    notificationByDate?.yesterday?.map((item) => (
                      <NotificationCard
                        key={item?.notification_id}
                        notification={item}
                        handleGetUpdateNotifiList={handleGetList}
                        setIsNotificationModalVisible={setIsNotificationModalVisible}
                      />
                    ))}
                </Box>
              )}
              {Boolean(notificationByDate?.older?.length) && (
                <Box sx={{ marginBottom: '5px' }}>
                  <Typography
                    sx={{
                      color: '#0A3A67',
                      fontSize: '15px',
                      fontWeight: 500,
                      margin: '.5rem 0',
                      paddingLeft: '16px'
                    }}>
                    Older ({notificationByDate?.older?.length || 0})
                  </Typography>

                  {notificationByDate?.older?.length > 0 &&
                    notificationByDate?.older?.map((item) => (
                      <NotificationCard
                        key={item?.notification_id}
                        notification={item}
                        handleGetUpdateNotifiList={handleGetList}
                        setIsNotificationModalVisible={setIsNotificationModalVisible}
                      />
                    ))}
                </Box>
              )}
            </>
          ) : (
            <Typography
              sx={{
                alignItems: 'center',
                color: '#0A3A67',
                display: 'flex',
                fontSize: '15px',
                fontWeight: 500,
                justifyContent: 'center',
                marginBlockStart: '2rem'
              }}>
              No Notification Found
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: '#0A3A6726',
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '.5rem 1.5rem '
          }}>
          <Typography
            onClick={() => {
              getNotifications();
              handleGetList(activeNotification || 'all_notification');
            }}
            sx={{
              color: '#0A3A67',
              cursor: 'pointer',
              fontSize: '15px',
              fontWeight: 500,
              paddingLeft: '16px',
              textDecoration: 'underline'
            }}>
            Refresh
          </Typography>
          {/* <RefreshIcon
            onClick={() => {
              getNotifications();
              handleGetList(activeNotification || 'all_notification');
            }}
            sx={{ color: '#0A3A67' }}
          /> */}
          {/* <IconButton
            sx={{ background: '#fff', borderRadius: '10px' }}
            onClick={() => {
              getNotifications();
              handleGetList(activeNotification || 'all_notification');
            }}>
            <RefreshIcon sx={{ color: '#0A3A67' }} />
          </IconButton> */}
          {/* <Typography
            sx={{
              color: '#0A3A67',
              fontSize: '15px',
              fontWeight: 500,
              paddingLeft: '16px',
              textDecoration: 'underline'
            }}>
            Clear All
          </Typography> */}
        </Box>
      </Box>
    </Modal>
  );
}

NotificationModal.propTypes = {
  isNotificationModalVisible: PropTypes.bool,
  notifications: PropTypes.array,
  notificationsCount: PropTypes.number,
  setIsNotificationModalVisible: PropTypes.func
};
