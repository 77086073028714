import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MenuItem, Select } from '@mui/material';
import { handleAssignPortfolioManagerToPrimarySalesBuilder } from 'features/PrimarySales/Builders/primarySalesBuilderSlice';
import PropTypes from 'prop-types';
import { handleAssignPortfolioManager } from 'store/clientSlice';
import { handleAssignPortfolioManagerToStClient } from 'store/stClientSlice';
import assignPRMStyles from './AssignPRM.styles';

function AssignPRM({ disabled = false, handleCallback = () => {}, params = {}, size = 'small' }) {
  const classes = assignPRMStyles();

  const dispatch = useDispatch();
  const pms = useSelector((state) => state.main?.pms);
  const { pathname } = useLocation();
  const [rewmId, setRewmId] = useState('');

  useEffect(() => {
    getTheId();
  }, [params?.value]);

  const getTheId = () => {
    const rewm_id = pms?.filter((value) => value?.portfolio_manager_name === params?.value);
    setRewmId(rewm_id?.[0]?.portfolio_manager_id);
  };

  const activePage =
    pathname?.startsWith('/client-details') || pathname?.startsWith('/clients')
      ? 'Clients'
      : pathname?.startsWith('/primary-sales')
      ? 'PrimarySalesClients'
      : 'StClients';

  const assignPRM = async (assignedToPrm, clientId) => {
    let res = null;

    if (activePage === 'Clients') {
      res = await dispatch(handleAssignPortfolioManager(clientId, assignedToPrm));
    } else if (activePage === 'PrimarySalesClients') {
      res = await dispatch(
        handleAssignPortfolioManagerToPrimarySalesBuilder(clientId, assignedToPrm)
      );
    } else {
      res = await dispatch(handleAssignPortfolioManagerToStClient(clientId, assignedToPrm));
    }
    setRewmId(assignedToPrm);
    if (res) await handleCallback();
  };

  return (
    <Select
      placeholder="Assign"
      size={size}
      disabled={disabled}
      className={classes.select}
      name="subscriptionType"
      defaultValue={rewmId}
      value={rewmId}
      onChange={(e) => assignPRM(e.target.value, params?.row?.clientUuid || params?.id)}>
      {pms?.map((prm) => (
        <MenuItem
          key={prm?.portfolio_manager_id}
          value={prm?.portfolio_manager_id}
          className={classes.options}>
          {prm?.portfolio_manager_name}
        </MenuItem>
      ))}
    </Select>
  );
}

AssignPRM.propTypes = {
  customStyles: PropTypes.object,
  disabled: PropTypes.bool,
  handleCallback: PropTypes.func,
  params: PropTypes.object,
  portfolioRelationshipManagers: PropTypes.array,
  size: PropTypes.string
};

export default memo(AssignPRM);
