import { createSlice } from '@reduxjs/toolkit';
import {
  addStClient,
  addStClientComment,
  addStClientPaymentDetails,
  assignPortfolioManagerToStClient,
  getStClientComments,
  getStClientDetails,
  getStClientInsights,
  getStClientPayment,
  getStClientPayments,
  getStClients,
  updateStClient,
  updateStClientPaymentDetails,
  updateStClientPremiumType
} from 'api/stClient';
import { toast } from 'react-toastify';

export const stClientFilterInitialValues = {
  appDownloads: '',
  branch: '',
  branchHead: '',
  channelPartner: '',
  country: '',
  createdFromDate: '',
  createdToDate: '',
  customerType: '',
  deleted: '',
  ekyc: '',
  insights: '',
  paymentFromDate: '',
  paymentToDate: '',
  premiumType: '',
  prm: '',
  referralType: '',
  revenueCollectedBy: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const stClientSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: stClientFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'stClient',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const stClientActions = stClientSlice.actions;

export const handleGetStClients = (filters) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await getStClients(filters);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleGetStClientDetails = (id) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await getStClientDetails(id);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleGetStClientInsights = (clientId) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await getStClientInsights(clientId);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleAssignPortfolioManagerToStClient =
  (clientId, assignedTo) => async (dispatch) => {
    try {
      dispatch(stClientActions.setIsLoading(true));
      const data = await assignPortfolioManagerToStClient(clientId, assignedTo);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(stClientActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(stClientActions.setIsLoading(false));
    }
  };

export const handleAddStClient = (params) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await addStClient(params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleUpdateStClient = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await updateStClient(clientId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));

    for (const values in error?.response?.data) {
      toast.error(
        values + ': ' + error?.response?.data?.[values]?.[0] || 'Oops ! some error occurred!'
      );
    }
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleGetStClientComments = (id) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await getStClientComments(id);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleAddStClientComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await addStClientComment(id, comments);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleGetStClientPayments = (id) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await getStClientPayments(id);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    console.log(errorMessage);
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleAddStClientPaymentDetails = (formData) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await addStClientPaymentDetails(formData);
    if (data) {
      toast.success('Payment details added successfully');
    }
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleUpdateStClientPaymentDetails = (formData, paymentId) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await updateStClientPaymentDetails(formData, paymentId);
    if (data) {
      toast.success('Payment details upgraded successfully');
    }
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleUpdateStClientPremiumType = (clientId, premiumType) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await updateStClientPremiumType(clientId, premiumType);
    if (data) toast.success(data?.message || 'Club type updated successfully');
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export const handleGetStClientPayment = (clientId, paymentId) => async (dispatch) => {
  try {
    dispatch(stClientActions.setIsLoading(true));
    const data = await getStClientPayment(clientId, paymentId);
    return data;
  } catch (error) {
    dispatch(stClientActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    console.log(errorMessage);
  } finally {
    dispatch(stClientActions.setIsLoading(false));
  }
};

export default stClientSlice;