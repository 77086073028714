import { createSlice } from '@reduxjs/toolkit';
import {
  addPrimarySalesBillingAddress,
  deletePrimarySalesBillingAddress,
  getPrimarySalesBillingAddressById,
  getPrimarySalesBillingAddressesByClientId,
  updatePrimarySalesBillingAddress
} from 'api/primarySalesBillingAddress';
import { toast } from 'react-toastify';

const primarySalesBillingAddressSlice = createSlice({
  initialState: {},
  name: 'primarySalesBillingAddress',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const primarySalesBillingAddressActions = primarySalesBillingAddressSlice.actions;

export const handleGetPrimarySalesBillingAddressesByClientId = (clientId) => async (dispatch) => {
  try {
    dispatch(primarySalesBillingAddressActions.setIsLoading(true));
    const data = await getPrimarySalesBillingAddressesByClientId(clientId);
    return data;
  } catch (error) {
    dispatch(primarySalesBillingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesBillingAddressActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesBillingAddressById =
  (clientId, billingAddressId) => async (dispatch) => {
    try {
      dispatch(primarySalesBillingAddressActions.setIsLoading(true));
      const data = await getPrimarySalesBillingAddressById(clientId, billingAddressId);
      return data;
    } catch (error) {
      dispatch(primarySalesBillingAddressActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(primarySalesBillingAddressActions.setIsLoading(false));
    }
  };

export const handleAddPrimarySalesBillingAddress = (clientId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesBillingAddressActions.setIsLoading(true));
    const data = await addPrimarySalesBillingAddress(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesBillingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(primarySalesBillingAddressActions.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesBillingAddress =
  (clientId, billingAddressId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesBillingAddressActions.setIsLoading(true));
      const data = await updatePrimarySalesBillingAddress(clientId, billingAddressId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesBillingAddressActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesBillingAddressActions.setIsLoading(false));
    }
  };

export const handleDeletePrimarySalesBillingAddress =
  (clientId, callbackId) => async (dispatch) => {
    try {
      dispatch(primarySalesBillingAddressActions.setIsLoading(true));
      const data = await deletePrimarySalesBillingAddress(clientId, callbackId);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesBillingAddressActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesBillingAddressActions.setIsLoading(false));
    }
  };

export default primarySalesBillingAddressSlice;
