import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const headerStyles = makeStyles({
  addButton: {
    '@media (max-width:900px)': {
      '& .css-1d6wzja-MuiButton-startIcon': {
        marginRight: '0 !important'
      },
      marginRight: '10px',
      paddingLeft: '0 !important',
      paddingRight: '0 !important'
    },
    backgroundColor: COLORS.PRIMARY,
    height: '100%',
    textTransform: 'capitalize'
  },
  addMenuItem: {
    color: COLORS.PRIMARY,
    fontFamily: 'Lato',
    fontSize: '16px',
    padding: '8px 16px'
  },
  avatar: {
    '@media (max-width:768px)': {
      height: '44px !important',
      width: '44px !important'
    },
    cursor: 'pointer',
    height: '54px !important',
    width: '54px !important'
  },
  backButton: {
    '@media (max-width:768px)': {
      marginRight: '0 !important',
      padding: '8px 0 !important'
    },
    backgroundColor: '#fff !important',
    border: '0.5px solid rgba(10, 58, 103, 0.4) !important',
    borderRadius: '10px !important',
    marginRight: '20px !important',
    padding: '14px !important'
  },
  category: {
    color: 'rgba(10, 58, 103, 0.6)',
    fontFamily: 'Lato',
    fontSize: '14px !important',
    fontWeight: 400,
    lineHeight: '18px',
    paddingTop: '4px'
  },
  closeIcon: {
    '&:hover': {
      backgroundColor: 'rgba(10, 58, 103,0.1)'
    },
    borderRadius: '50%',
    color: 'rgba(10, 58, 103, 0.7)',
    cursor: 'pointer'
  },

  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftHeaderSec: {
    '@media (max-width:768px)': {
      marginRight: '4rem'
    },
    alignItems: 'center',
    display: 'flex',
    height: '100%'
  },
  main: {
    alignItems: 'center !important',
    height: '54px !important',
    justifyContent: 'space-between !important'
  },
  notificationButton: {
    '@media (max-width:768px)': {
      margin: '0 10px !important'
    },
    backgroundColor: '#fff !important',
    borderRadius: '10px !important',
    height: '100% !important',
    margin: '0 25px !important',
    position: 'relative'
  },
  resultShowingText: {
    color: 'rgba(10, 58, 103, 0.6)',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '21px',
    paddingLeft: '12px'
  },
  rightHeaderSec: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end'
  },
  searchBar: {
    '& fieldset': { border: '0.5px solid rgba(10, 58, 103, 0.4)', borderRadius: '8px' },
    backgroundColor: '#fff',
    border: 'none !important',
    marginBottom: '0 !important',
    outline: 'none',
    width: '100%'
  },
  searchResult: {
    '@media (max-width:768px)': {
      marginTop: '.5rem',
      maxHeight: '500px',
      width: '75%'
    },
    backgroundColor: '#fff',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    boxShadow: '2px 2px 16px 0px rgba(10, 58, 103, 0.5)',
    boxSizing: 'border-box',
    marginTop: '1rem',
    maxHeight: '400px',
    overflowY: 'scroll',
    padding: '12px 16px',
    position: 'absolute',
    width: '40%',
    zIndex: 12
  },
  searchResultItem: {
    alignItems: 'center !important',
    color: 'rgba(10, 58, 103, 0.7) !important',
    display: 'flex !important',
    fontFamily: 'Lato',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '21px',
    padding: '8px 12px !important',
    textDecoration: 'none !important',
    textTransform: 'capitalize'
  },
  viewAll: {
    color: '#0A3A67 !important',
    textAlign: 'center',
    textDecoration: 'underline !important',
    textDecorationColor: '#0A3A67 !important',
    textTransform: 'capitalize !important',
    width: '100%'
  }
});

export default headerStyles;
