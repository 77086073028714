import { createSlice } from '@reduxjs/toolkit';
import {
  addPrimarySalesBuilder,
  addPrimarySalesBuilderComment,
  addPrimarySalesPaymentDetails,
  assignPortfolioManagerForPrimarySales,
  getPrimarySalesBuilderComments,
  getPrimarySalesBuilderDetails,
  getPrimarySalesBuilderInsights,
  getPrimarySalesBuilders,
  getPrimarySalesPayments,
  updatePrimarySalesBuilder,
  updatePrimarySalesPaymentDetails,
  updatePrimarySalesPremiumType
} from 'features/PrimarySales/Builders/primarySalesBuilder';
import { toast } from 'react-toastify';

export const primarySalesBuilderFilterInitialValues = {
  branch: '',
  branchHead: '',
  createdFromDate: '',
  createdToDate: '',
  customerType: '',
  limit: 10,
  page: 0,
  premiumType: '',
  rewm: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const primarySalesBuilderSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: primarySalesBuilderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'primarySalesBuilder',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const primarySalesBuilderActions = primarySalesBuilderSlice.actions;

export const handleGetPrimarySalesBuilders = (filters) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await getPrimarySalesBuilders(filters);
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesBuilderDetails = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await getPrimarySalesBuilderDetails(id);
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesBuilderInsights = (builderId) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await getPrimarySalesBuilderInsights(builderId);
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleAssignPortfolioManagerToPrimarySalesBuilder =
  (builderId, assignedTo) => async (dispatch) => {
    try {
      dispatch(primarySalesBuilderActions.setIsLoading(true));
      const data = await assignPortfolioManagerForPrimarySales(builderId, assignedTo);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesBuilderActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesBuilderActions.setIsLoading(false));
    }
  };

export const handleAddPrimarySalesBuilder = (params) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await addPrimarySalesBuilder(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesBuilder = (builderId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await updatePrimarySalesBuilder(builderId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));

    for (const values in error?.response?.data) {
      toast.error(
        values + ': ' + error?.response?.data?.[values]?.[0] || 'Oops ! some error occurred!'
      );
    }
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesBuilderComments = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await getPrimarySalesBuilderComments(id);
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleAddPrimarySalesBuilderComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await addPrimarySalesBuilderComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesBuilderPayments = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await getPrimarySalesPayments(id);
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    console.log(errorMessage);
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleAddPrimarySalesBuilderPaymentDetails = (formData) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await addPrimarySalesPaymentDetails(formData);
    if (data) {
      toast.success('Payment details added successfully');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesBuilderPaymentDetails =
  (formData, paymentId) => async (dispatch) => {
    try {
      dispatch(primarySalesBuilderActions.setIsLoading(true));
      const data = await updatePrimarySalesPaymentDetails(formData, paymentId);
      if (data) {
        toast.success('Payment details upgraded successfully');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesBuilderActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }

      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesBuilderActions.setIsLoading(false));
    }
  };

export const handleUpdatePrimarySalesBuilderPremiumType =
  (builderId, premiumType) => async (dispatch) => {
    try {
      dispatch(primarySalesBuilderActions.setIsLoading(true));
      const data = await updatePrimarySalesPremiumType(builderId, premiumType);
      if (data?.message) {
        toast.success(data?.message || 'Premium type updated successfully');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesBuilderActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }

      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesBuilderActions.setIsLoading(false));
    }
  };

export const handleGetPrimarySalesBuilderPayment = (builderId, paymentId) => async (dispatch) => {
  try {
    dispatch(primarySalesBuilderActions.setIsLoading(true));
    const data = await getPrimarySalesPayments(builderId, paymentId);
    return data;
  } catch (error) {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    console.log(errorMessage);
  } finally {
    dispatch(primarySalesBuilderActions.setIsLoading(false));
  }
};

export default primarySalesBuilderSlice;
