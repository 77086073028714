import { createSlice } from '@reduxjs/toolkit';
import {
  getAumBranch,
  getAumPrm,
  getBuyersCount,
  getDSRData,
  getExpiringAds,
  getInsights,
  getRedBoxData,
  getRevenue,
  getRevenueBooked,
  getRevenueCollected,
  getRevenuePipeline
} from './dashboard.api';

const initialState = {
  currentTablePage: 0,
  filters: {
    appDownloads: { branch: '', fromDate: null, selectedDateFilter: 'LifeTime', toDate: null },
    aumByBranch: { fromDate: null, prm: '', selectedDateFilter: 'LifeTime', toDate: null },
    aumByRewm: { branch: '', fromDate: null, selectedDateFilter: 'LifeTime', toDate: null },
    buyers: {
      bh: '',
      branch: '',
      fromDate: null,
      prm: '',
      rm: '',
      selectedDateFilter: 'LifeTime',
      toDate: null
    },
    expiringAds: {
      bh: '',
      branch: '',
      date: null,
      prm: '',
      rm: ''
    },
    keyPortfolioIndicators: {
      bh: '',
      branch: '',
      customer_type: '',
      fromDate: null,
      prm: '',
      rm: '',
      selectedDateFilter: 'LifeTime',
      toDate: null
    },
    redboxCarousel: {
      fromDate: null,
      selectedDateFilter: 'LifeTime',
      toDate: null
    },
    revenueBooked: {
      bh: '',
      branch: '',
      fromDate: null,
      prm: '',
      rm: '',
      selectedDateFilter: 'LifeTime',
      toDate: null
    },
    revenueBookedListing: {
      bh: '',
      branch: '',
      customerType: '',
      fromDate: null,
      limit: 10,
      page: 0,
      prm: '',
      revenueType: '',
      rm: '',
      sortAt: '',
      sortBy: '',
      source: '',
      toDate: null
    },
    revenueCollectedByRM: {
      branch: '',
      fromDate: null,
      selectedDateFilter: 'LifeTime',
      toDate: null
    },
    revenuePipeline: {
      bh: '',
      branch: '',
      fromDate: null,
      prm: '',
      rm: '',
      selectedDateFilter: 'LifeTime',
      toDate: null
    },
    totalAUM: {
      bh: '',
      branch: '',
      customerType: '',
      fromDate: null,
      prm: '',
      rm: '',
      selectedDateFilter: 'LifeTime',
      toDate: null
    }
  },
  isLoading: false,
  rowPerPage: 10
};

const dashboardSlice = createSlice({
  initialState,
  name: 'dashboard',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const dashboardActions = dashboardSlice.actions;

export const handleGetInsights =
  (fromDate, toDate, rm, prm, bh, branch, customerType) => async (dispatch) => {
    try {
      dispatch(dashboardActions.setIsLoading(true));
      const data = await getInsights(fromDate, toDate, rm, prm, bh, branch, customerType);
      return data;
    } catch (error) {
      dispatch(dashboardActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(dashboardActions.setIsLoading(false));
    }
  };

export const handleGetAumPrm = (fromDate, toDate, branch) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getAumPrm(fromDate, toDate, branch);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetAumBranch = (fromDate, toDate, prm) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getAumBranch(fromDate, toDate, prm);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetRedBoxData = (fromDate, toDate, rm, prm, bh, branch) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getRedBoxData(fromDate, toDate, rm, prm, bh, branch);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetRevenue = (fromDate, toDate, rm, prm, bh, branch) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getRevenue(fromDate, toDate, rm, prm, bh, branch);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetRevenueCollected =
  (fromDate, toDate, rm, prm, bh, branch) => async (dispatch) => {
    try {
      dispatch(dashboardActions.setIsLoading(true));
      const data = await getRevenueCollected(fromDate, toDate, rm, prm, bh, branch);
      return data;
    } catch (error) {
      dispatch(dashboardActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(dashboardActions.setIsLoading(false));
    }
  };

export const handleGetDSRData = (fromDate, toDate, rm, prm, bh, branch) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getDSRData(fromDate, toDate, rm, prm, bh, branch);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetBuyersCount = (fromDate, toDate, rm, prm, bh, branch) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getBuyersCount(fromDate, toDate, rm, prm, bh, branch);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetExpiringAds = (rm, prm, bh, branch, date) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getExpiringAds(rm, prm, bh, branch, date);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export const handleGetRevenuePipeline =
  (fromDate, toDate, rm, prm, bh, branch) => async (dispatch) => {
    try {
      dispatch(dashboardActions.setIsLoading(true));
      const data = await getRevenuePipeline(fromDate, toDate, rm, prm, bh, branch);
      return data;
    } catch (error) {
      dispatch(dashboardActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage || 'Something went wrong');
    } finally {
      dispatch(dashboardActions.setIsLoading(false));
    }
  };

export const handleGetRevenueBooked = (filters) => async (dispatch) => {
  try {
    dispatch(dashboardActions.setIsLoading(true));
    const data = await getRevenueBooked(filters);
    return data;
  } catch (error) {
    dispatch(dashboardActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(dashboardActions.setIsLoading(false));
  }
};

export default dashboardSlice;
