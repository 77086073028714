import { createSlice } from '@reduxjs/toolkit';
import {
  addAssignPRM,
  addOrderAssignConfirmStatus,
  addPartialRevenuePayment,
  addPaymentsInOrderSubStatus,
  addPerformancePoint,
  addPsOrderAssignConfirmStatus,
  addTokenAdvancePayment,
  addTotalRevenuePayment,
  addTransactionalOrder,
  addTransAssignConfirmStatus,
  addTransAssignStatus,
  addTransOrderComment,
  addTransOrderExtComment,
  addTransOrderExtSubComment,
  assignTransOrderRM,
  cancelTransactionalOrder,
  deleteFile,
  getFiles,
  getTransactionalOrderDetailsById,
  getTransactionalOrdersListing,
  getTransactionalOrdersListingByClientId,
  getTransOrderComments,
  getTransOrderExtComments,
  getTransOrderExtSubComments,
  getTransPaymentList,
  getTransPerformanceCredit,
  getTransPerformancePointList,
  getVistaListing,
  orderSubStatusAdd,
  orderSubStatusUpdate,
  searchInTransComment,
  transactionCart,
  updateAssignPRM,
  updatePaymentsInOrderSubStatus,
  updateSaleAdvisoryOrderStatus,
  updateSubAssignFOS,
  updateSubAssignRM,
  updateTransactionalOrder,
  updateTransactionalOrderStatus,
  updateTransAssignStatus,
  uploadFile,
  uploadTransOrderCommentFile
} from 'api/transactionalOrder';
import { toast } from 'react-toastify';

export const transOrderFilterInitialValues = {
  assignTo: '',
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  orderStatus: '',
  orderType: '',
  rewm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const transactionalOrderSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: transOrderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'transactionalOrder',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const transactionalOrderAction = transactionalOrderSlice.actions;

export const handleGetTransactionalOrdersListing = (params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransactionalOrdersListing(params);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetVistaListing = (params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getVistaListing(params);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransactionalOrderDetailsById = (orderId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransactionalOrderDetailsById(orderId);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransactionalOrdersListingByClientId =
  (clientId, page) => async (dispatch) => {
    try {
      dispatch(transactionalOrderAction.setIsLoading(true));
      const data = await getTransactionalOrdersListingByClientId(clientId, page);
      return data;
    } catch (error) {
      dispatch(transactionalOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(transactionalOrderAction.setIsLoading(false));
    }
  };

export const handleAddTransactionalOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTransactionalOrder(clientId, params);
    if (data) {
      toast.success(data?.message || 'Order added successfully');
    }
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleCancelTransactionalOrder = (clientId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await cancelTransactionalOrder(clientId);
    if (data) toast.success(data?.message || 'Order cancelled successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleUpdateTransactionalOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await updateTransactionalOrder(clientId, params);
    if (data) {
      toast.success(data?.message || 'Order updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleVistaOrderStatusUpdate =
  (clientId, transOrderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(transactionalOrderAction.setIsLoading(true));
      const data = await updateSaleAdvisoryOrderStatus(
        clientId,
        transOrderId,
        statusId,
        subStatusId
      );
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(transactionalOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(transactionalOrderAction.setIsLoading(false));
    }
  };

export const handleGetTransOrderComments = (id) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransOrderComments(id);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddTransOrderComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTransOrderComment(id, comments);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleCreateTransactionCart = (clientId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await transactionCart(clientId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAssignTransOrderRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await assignTransOrderRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleTransOrderFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await uploadFile(clientId, files);
    if (data) toast.success('File uploaded successfully!');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransOrderFiles = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getFiles(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleDeleteTransOrderFile = (clientId, orderId, fileId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await deleteFile(clientId, orderId, fileId);
    if (data) toast.success('File deleted successfully!');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddTransOrderExtComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTransOrderExtComment(id, comments);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransOrderExtComments = (id) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransOrderExtComments(id);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddTransOrderExtSubComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTransOrderExtSubComment(id, comments);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransOrderExtSubComments = (id) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransOrderExtSubComments(id);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleTransOrderCommentFileUpload = (id, files) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await uploadTransOrderCommentFile(id, files);
    if (data) toast.success('File uploaded successfully!');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleSearchInTransComment =
  (offset, limit, search_param, orderId) => async (dispatch) => {
    try {
      dispatch(transactionalOrderAction.setIsLoading(true));
      const data = await searchInTransComment(offset, limit, search_param, orderId);
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(transactionalOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(transactionalOrderAction.setIsLoading(false));
    }
  };

export const handleTransOrderStatusUpdate =
  (clientId, transOrderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(transactionalOrderAction.setIsLoading(true));
      const data = await updateTransactionalOrderStatus(
        clientId,
        transOrderId,
        statusId,
        subStatusId
      );
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(transactionalOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(transactionalOrderAction.setIsLoading(false));
    }
  };

export const handleSaleAdvisoryStatusUpdate =
  (clientId, transOrderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(transactionalOrderAction.setIsLoading(true));
      const data = await updateSaleAdvisoryOrderStatus(
        clientId,
        transOrderId,
        statusId,
        subStatusId
      );
      if (data) toast.success(data?.message);
      return data;
    } catch (error) {
      dispatch(transactionalOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(transactionalOrderAction.setIsLoading(false));
    }
  };

export const handleSubAssignFOSTrans = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await updateSubAssignFOS(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleSubAssignRMTrans = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await updateSubAssignRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddOrderSubStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await orderSubStatusAdd(clientId, orderId, params);
    if (data) toast.success(data?.message || 'Status updated successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddPaymentsInOrderSubStatus = (formData) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addPaymentsInOrderSubStatus(formData);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleUpdateOrderSubStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await orderSubStatusUpdate(clientId, orderId, params);
    if (data) toast.success(data?.message || 'Status updated successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleUpdatePaymentsInOrderSubStatus = (paymentId, formData) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await updatePaymentsInOrderSubStatus(paymentId, formData);
    if (data) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleTransAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTransAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleTransAssignConfirmStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTransAssignConfirmStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handlePsOrderAssignConfirmStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addPsOrderAssignConfirmStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleOrderAssignConfirmStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addOrderAssignConfirmStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleTransAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddTokenPayment = (formData) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTokenAdvancePayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddTotalRevenuePayment = (formData) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addTotalRevenuePayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddPartialRevenuePayment = (formData) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addPartialRevenuePayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetPerformanceCredit = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransPerformanceCredit(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransPayments = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransPaymentList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleUpdateTransAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await updateTransAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleUpdateTransAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await updateAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleGetTransPerformancePointList = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await getTransPerformancePointList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export const handleAddPerformancePoint = (formData) => async (dispatch) => {
  try {
    dispatch(transactionalOrderAction.setIsLoading(true));
    const data = await addPerformancePoint(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(transactionalOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(transactionalOrderAction.setIsLoading(false));
  }
};

export default transactionalOrderSlice;
