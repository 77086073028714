import { createSlice } from '@reduxjs/toolkit';
import { getNotifications, markReadNotification } from 'api/notification';

const notificationSlice = createSlice({
  initialState: {
    isLoading: false,
    notifications: [],
    notificationsCount: 0
  },
  name: 'notification',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    setNotificationsCount(state, action) {
      state.notificationsCount = action.payload;
    }
  }
});

export const notificationActions = notificationSlice.actions;

export const handleGetNotifications = () => async (dispatch) => {
  try {
    // dispatch(notificationActions.setIsLoading(true));
    const data = await getNotifications();

    if (data) {
      dispatch(notificationActions.setNotifications(data?.notifications));
      dispatch(notificationActions.setNotificationsCount(data?.total_notification_count));
    }

    return data;
  } catch (error) {
    // dispatch(notificationActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(notificationActions.setIsLoading(false));
  }
};

export const handleMarkReadNotification = (notificationId) => async (dispatch) => {
  try {
    dispatch(notificationActions.setIsLoading(true));
    const data = await markReadNotification(notificationId);
    return data;
  } catch (error) {
    dispatch(notificationActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(notificationActions.setIsLoading(false));
  }
};

export default notificationSlice;
