import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  OutlinedInput,
  Select
} from '@mui/material';
import PropTypes from 'prop-types';

export default function AmenitiesField({
  amenitiesDropdownValues,
  errors,
  isEditable,
  setFieldValue,
  touched,
  values
}) {
  return (
    <FormControl
      fullWidth
      disabled={!isEditable}
      sx={{
        '& .MuiInputBase-input': {
          background: 'rgba(10, 58, 103, 0.05) !important',
          border: 'none',
          borderRadius: '50px !important',
          padding: '12px 1rem !important'
        },
        '& .MuiInputBase-root': {
          borderRadius: '50px !important'
        }
      }}>
      <FormLabel>Amenities</FormLabel>
      <Select
        name="amenities"
        variant="outlined"
        multiple
        value={values?.amenities}
        onChange={(event) => {
          const {
            target: { value }
          } = event;
          setFieldValue('amenities', typeof value === 'string' ? value?.split(',') : value);
        }}
        error={touched?.amenities && Boolean(errors?.amenities)}
        helperText={touched?.amenities && errors?.amenities}
        input={<OutlinedInput id="amenities" label="Chip" />}>
        {amenitiesDropdownValues?.map((item) => (
          <MenuItem key={item?.amenities_id} value={item?.amenities_id}>
            {item?.amenities}
          </MenuItem>
        ))}
      </Select>
      {touched.amenities && errors.amenities ? (
        <FormHelperText sx={{ color: '#bf3333' }}>
          {touched.amenities && errors.amenities}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

AmenitiesField.propTypes = {
  amenitiesDropdownValues: PropTypes.array,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  isEditable: PropTypes.bool,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object
};
