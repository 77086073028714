import { axiosClient } from '../utils/axiosClient';

const transactionalOrdersEndpoint = '/v2/structured_transaction/clients/transaction_orders';
const transactionalOrdersListingByClientIdEndpoint = '/v2/structured_transaction/clients/';
const transactionalOrderEndpoint = '/v2/structured_transaction/client/';

export const getStTransactionalOrdersListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    rm,
    createdFromDate,
    createdToDate,
    orderType,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate,
    assignTo
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(transactionalOrdersEndpoint + queryString).then((resp) => resp?.data);
};

export const getStTransactionalOrderDetailsById = (orderId) => {
  return axiosClient
    .get('/v2/structured_transaction/clients/transaction_order/' + orderId)
    .then((resp) => resp?.data);
};

export const getStTransactionalOrdersListingByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient
    .get(
      transactionalOrdersListingByClientIdEndpoint +
        clientId +
        `/transaction_orders?limit=${limit}&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const getStNormalOrdersListingByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient
    .get(
      transactionalOrdersListingByClientIdEndpoint +
        clientId +
        `/normal_orders?limit=${limit}&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const addStTransactionalOrder = (clientId, params) => {
  return axiosClient
    .post(transactionalOrderEndpoint + clientId + '/transaction_orders', params)
    .then((resp) => resp?.data);
};

export const cancelStTransactionalOrder = (clientId, orderId) => {
  return axiosClient
    .patch(transactionalOrderEndpoint + clientId + '/transaction_orders/' + orderId + '/cancel')
    .then((resp) => resp?.data);
};

export const updateStTransactionalOrder = (clientId, orderId, params) => {
  return axiosClient
    .patch(
      transactionalOrderEndpoint + clientId + '/transaction_orders/' + orderId + '/update',
      params
    )
    .then((resp) => resp?.data);
};

export const getStTransOrderComments = (orderId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/transaction/order/${orderId}/comments`)
    .then((resp) => resp?.data);
};

export const addStTransOrderComment = (orderId, comments) => {
  return axiosClient
    .post(`/v2/structured_transaction/client/transaction/order/${orderId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const stTransactionCart = (clientId, params) => {
  return axiosClient
    .put(`/v2/structured_transaction/client/${clientId}/transaction_carts`, params)
    .then((resp) => resp?.data);
};

export const assignStTransOrderRM = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `/v2/structured_transaction/client/${clientId}/transaction_orders/${orderId}/assign_rm`,
      params
    )
    .then((resp) => resp?.data);
};

export const uploadStTransOrderFile = (clientId, files) => {
  return axiosClient
    .post(
      `/v2/structured_transaction/client/${clientId}/transaction/order/fileupload?encoded=false`,
      files
    )
    .then((resp) => resp?.data);
};

export const uploadStOrderFile = (clientId, files) => {
  return axiosClient
    .post(
      `/v1/structured_transaction/client/${clientId}/normal/order/fileupload`,
      files
    )
    .then((resp) => resp?.data);
};

export const getStTransOrderFiles = (clientId, orderId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${clientId}/transaction/order/${orderId}/filelist`)
    .then((resp) => resp?.data);
};

export const getStOrderFiles = (clientId, orderId) => {
  return axiosClient
    .get(`/v1/structured_transaction/client/${clientId}/normal/order/${orderId}/filelist`)
    .then((resp) => resp?.data);
};

export const deleteStTransOrderFile = (clientId, orderId, fileId) => {
  return axiosClient
    .delete(
      `/v2/structured_transaction/client/${clientId}/transaction/order/${orderId}/file/${fileId}/delete`
    )
    .then((resp) => resp?.data);
};

export const deleteStOrderFile = (clientId, orderId, fileId) => {
  return axiosClient
    .delete(
      `/v1/structured_transaction/client/${clientId}/normal/order/${orderId}/file/${fileId}/delete`
    )
    .then((resp) => resp?.data);
};

export const addStTransOrderExtComment = (orderId, comments) => {
  return axiosClient
    .post(`/v2/structured_transaction/client/transaction/order/${orderId}/external/comments`, {
      comments
    })
    .then((resp) => resp?.data);
};

export const getStTransOrderExtComments = (orderId) => {
  return axiosClient
    .get(
      `/v2/structured_transaction/client/transaction/order/${orderId}/external/comments?offset=0&limit=100`
    )
    .then((resp) => resp?.data);
};

export const addStTransOrderExtSubComment = (parentId, comments) => {
  return axiosClient
    .post(`/v2/structured_transaction/client/transaction/order/external/sub/comments/${parentId}`, {
      comments
    })
    .then((resp) => resp?.data);
};

export const getStTransOrderExtSubComments = (parentId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/transaction/order/external/comments/${parentId}`)
    .then((resp) => resp?.data);
};

export const uploadStTransOrderCommentFile = (id, files) => {
  return axiosClient
    .post(
      `/v2/structured_transaction/client/transaction/order/external/comments/${id}/fileupload?encoded=False`,
      files
    )
    .then((resp) => resp?.data);
};

export const searchInStTransComment = (offset = 0, limit = 100, search_param, orderId) => {
  return axiosClient
    .get(
      `/v2/structured_transaction/client/transaction/order/${orderId}/search?offset=${offset}&limit=${limit}&search_param=${search_param}`
    )
    .then((resp) => resp?.data);
};

export const updateStTransactionalOrderStatus = (clientId, transOrderId, statusId, subStatusId) => {
  return axiosClient
    .patch(
      `/v2/structured_transaction/client/${clientId}/transaction_orders/${transOrderId}/status/update`,
      {
        order_status: statusId,
        order_sub_status: subStatusId
      }
    )
    .then((resp) => resp?.data);
};

export const updateStSubAssignFOS = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `/v2/structured_transaction/client/${clientId}/transaction_orders/${orderId}/sub_assign_fos`,
      params
    )
    .then((resp) => resp?.data);
};

export const updateStTransOrderSubAssignRM = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `/v2/structured_transaction/client/${clientId}/transaction_orders/${orderId}/sub_assign_rm`,
      params
    )
    .then((resp) => resp?.data);
};

export const stTransOrderSubStatusAdd = (clientId, orderId, params) => {
  return axiosClient
    .post(
      `/v2/structured_transaction/client/${clientId}/transaction_orders/${orderId}/sub_status/details/add`,
      params
    )
    .then((resp) => resp?.data);
};

export const addPaymentsInStTransOrderSubStatus = (formData) => {
  return axiosClient
    .post('/v2/structured_transaction/client/order/payments/fileupload?encoded=False', formData)
    .then((resp) => resp?.data);
};

export const stTransOrderSubStatusUpdate = (clientId, orderId, params) => {
  return axiosClient
    .patch(
      `/v2/structured_transaction/client/${clientId}/transaction_orders/${orderId}/sub_status/details/update`,
      params
    )
    .then((resp) => resp?.data);
};

export const updatePaymentsInStTransOrderSubStatus = (paymentId, formData) => {
  return axiosClient
    .patch(
      `/v2/structured_transaction/client/order/payments/${paymentId}/fileupload?encoded=False`,
      formData
    )
    .then((resp) => resp?.data);
};

export const addOrderSubStatus = (clientId, formData) => {
  return axiosClient
    .post(`v2/structured_transaction/client/${clientId}/normal_orders/status/fileupload`, formData)
    .then((resp) => resp?.data);
};

export const addStPayment = (formData) => {
  return axiosClient
    .post('v2/structured_transaction/client/normal_orders/payments/fileupload', formData)
    .then((resp) => resp?.data);
};

export const getStPaymentList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/payments`)
    .then((resp) => resp?.data);
};

export const getPerformanceCredit = (clientId, orderId) => {
  return axiosClient
    .get(`v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/performance_credit`)
    .then((resp) => resp?.data);
};

export const addStPerformancePoint = (formData) => {
  return axiosClient
    .post('v2/structured_transaction/client/normal_orders/payments/fileupload', formData)
    .then((resp) => resp?.data);
};

export const getStPerformancePoint = (clientId, orderId) => {
  return axiosClient
    .get(`v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/performance_point_payments`)
    .then((resp) => resp?.data);
};