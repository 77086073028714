import { axiosClient } from '../utils/axiosClient';

export const getBrokerListData = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    createdFromDate,
    createdToDate,
    updatedFromDate,
    updatedToDate,
    rewm,
    leadSource,
    branch,
    sortAt,
    sortBy
  } = filters;

  const params = {
    branch,
    from_date: createdFromDate,
    lead_prm: rewm,
    limit,
    offset: limit * page,
    sort_at: sortAt,
    sort_by: sortBy,
    source: leadSource,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';
  return axiosClient.get(`v2/brokers${queryString}`).then((resp) => resp?.data);
};

export const postBrokerData = (payload) => {
  return axiosClient.post(`v2/broker/add`, payload).then((resp) => resp?.data);
};

export const putBrokerData = (id, payload) => {
  return axiosClient.put(`v2/broker/${id}/update`, payload).then((resp) => resp?.data);
};

export const getBrokerCount = () => {
  return axiosClient.get(`v2/broker/insights`).then((resp) => resp?.data);
};

export const getByIdBroker = (id) => {
  return axiosClient.get(`v2/broker/details/${id}`).then((resp) => resp?.data);
};

export const addCallback = (id, params) => {
  return axiosClient.post(`v2/broker/${id}/call_back`, params).then((resp) => resp?.data);
};

export const getCallback = (id) => {
  return axiosClient.get(`v2/broker/call_back/${id}/details`).then((resp) => resp?.data);
};

export const updateCallback = (id, params) => {
  return axiosClient.put(`v2/broker/call_back/${id}`, params).then((resp) => resp?.data);
};
