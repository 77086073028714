import { createSlice } from '@reduxjs/toolkit';
import {
  addStBillingAddress,
  deleteStBillingAddress,
  getStBillingAddressById,
  getStBillingAddressesByClientId,
  updateStBillingAddress
} from 'api/stBillingAddress';
import { toast } from 'react-toastify';

const stBillingAddressSlice = createSlice({
  initialState: {},
  name: 'stBillingAddress',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const stBillingAddressActions = stBillingAddressSlice.actions;

export const handleGetStBillingAddressesByClientId = (clientId) => async (dispatch) => {
  try {
    dispatch(stBillingAddressActions.setIsLoading(true));
    const data = await getStBillingAddressesByClientId(clientId);
    return data;
  } catch (error) {
    dispatch(stBillingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stBillingAddressActions.setIsLoading(false));
  }
};

export const handleGetStBillingAddressById = (clientId, billingAddressId) => async (dispatch) => {
  try {
    dispatch(stBillingAddressActions.setIsLoading(true));
    const data = await getStBillingAddressById(clientId, billingAddressId);
    return data;
  } catch (error) {
    dispatch(stBillingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stBillingAddressActions.setIsLoading(false));
  }
};

export const handleAddStBillingAddress = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stBillingAddressActions.setIsLoading(true));
    const data = await addStBillingAddress(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stBillingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stBillingAddressActions.setIsLoading(false));
  }
};

export const handleUpdateStBillingAddress =
  (clientId, billingAddressId, params) => async (dispatch) => {
    try {
      dispatch(stBillingAddressActions.setIsLoading(true));
      const data = await updateStBillingAddress(clientId, billingAddressId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(stBillingAddressActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(stBillingAddressActions.setIsLoading(false));
    }
  };

export const handleDeleteStBillingAddress = (clientId, callbackId) => async (dispatch) => {
  try {
    dispatch(stBillingAddressActions.setIsLoading(true));
    const data = await deleteStBillingAddress(clientId, callbackId);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(stBillingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stBillingAddressActions.setIsLoading(false));
  }
};

export default stBillingAddressSlice;
