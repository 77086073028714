import { makeStyles } from '@mui/styles';

const dataGridStyles = makeStyles({
  main: {
    backgroundColor: 'transparent',
    borderRadius: '24px',
    marginTop: '32px'
  },
  table: {
    '& .MuiDataGrid-cell': {
      border: '0 !important',
      color: '#0A3A67',
      fontSize: '14px',
      textTransform: 'capitalize',
      whiteSpace: 'normal !important'
      // wordWrap: 'break-word !important'
    },
    '& .MuiDataGrid-columnHeaders': {
      border: 'none',
      borderRadius: '10px',
      fontFamily: 'Lato',
      fontSize: '15px',
      whiteSpace: 'normal !important'
      // wordWrap: 'break-word !important'
    },
    '& .MuiDataGrid-columnHeadersInner': {
      color: '#fff'
    },
    '& .MuiDataGrid-columnSeparator--sideRight': {
      display: 'none !important'
    },
    '& .MuiDataGrid-footerContainer': {
      background: '#fff',
      border: '1px solid rgba(10, 58, 103, 0.2)',
      borderRadius: '10px',
      marginTop: '6px'
    },
    '& .MuiDataGrid-row': {
      background: '#fff',
      border: '1px solid rgba(10, 58, 103, 0.2)',
      borderRadius: '10px',
      margin: '3px 0'
    },
    border: 'none !important',
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontSize: '14px !important'
  },
  title: {
    backgroundColor: '#0A3A67',
    borderRadius: '20px 20px 0px 0px',
    color: '#fff',
    padding: '16px 30px'
  }
});

export default dataGridStyles;
