import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  addEvent,
  addEventComment,
  deleteEvent,
  deleteFile,
  getCommentFile,
  getEventById,
  getEventComments,
  getEventInsights,
  getEventReport,
  getEvents,
  updateEvent,
  uploadCommentFile
} from './event.api';

const eventSlice = createSlice({
  initialState: {
    calendarView: 'day',
    dateFrom: moment().format('YYYY-MM-DD'),
    dateTo: moment().format('YYYY-MM-DD'),
    events: [],
    isLoading: false,
    selectedTeammate: ''
  },
  name: 'event',
  reducers: {
    setCalendarView(state, action) {
      state.calendarView = action.payload;
    },
    setDateFrom(state, action) {
      state.dateFrom = action.payload;
    },
    setDateTo(state, action) {
      state.dateTo = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setProspectiveBuyersDropdownValues(state, action) {
      state.prospectiveBuyersDropdownValues = action.payload;
    },
    setSelectedTeammate(state, action) {
      state.selectedTeammate = action.payload;
    }
  }
});

export const eventActions = eventSlice.actions;

export const handleGetEvents = (fromDate, toDate) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await getEvents(fromDate, toDate);
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleGetEventById = (eventId) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await getEventById(eventId);
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleAddEvent = (params) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await addEvent(params);
    if (data) {
      toast.success(data?.message || 'Event added successfully');
    }
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleUpdateEvent = (id, params) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await updateEvent(id, params);
    if (data) {
      toast.success(data?.message || 'Event updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleDeleteEvent = (id) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await deleteEvent(id);
    if (data) {
      toast.success(data?.message || 'Event deleted successfully');
    }
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleGetEventComments = (eventId) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await getEventComments(eventId);
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleAddEventComment = (eventId, comment) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await addEventComment(eventId, comment);
    if (data) {
      toast.success(data?.message || 'Comment added successfully');
    }
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleGetEventReport = (fromDate, toDate, empId) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await getEventReport(fromDate, toDate, empId);
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleGetEventInsights = (fromDate, toDate, empId) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await getEventInsights(fromDate, toDate, empId);
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleFileUpload = (params) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await uploadCommentFile(params);
    if (data) toast.success('File uploaded successfully!');
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleGetCommentFiles = (eventId) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await getCommentFile(eventId);
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export const handleDeleteFile = (eventId, fileId) => async (dispatch) => {
  try {
    dispatch(eventActions.setIsLoading(true));
    const data = await deleteFile(eventId, fileId);
    if (data) toast.success('File deleted successfully!');
    return data;
  } catch (error) {
    dispatch(eventActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eventActions.setIsLoading(false));
  }
};

export default eventSlice;
