import { makeStyles } from '@mui/styles';

const searchStyles = makeStyles({
  SearchBox: {
    backgroundColor: '#ffffff',
    border: 'none',
    borderRadius: '10px',
    color: '#0a3a67',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontStyle: 'italic',
    fontWeight: '400',
    height: '5vh',
    marginRight: '350px',
    outline: 'none',
    paddingLeft: '35px',
    width: '40vw'
  },
  imageSize: {
    height: '2vh',
    left: '1.5%',
    position: 'absolute',
    top: '30%'
  }
});

export default searchStyles;
