import { sendOtpClient, verifyOtpClient } from '../utils/axiosClient';

const sendOtpForLoginUsingMobileEndpoint = 'v1/employee/mobile_login/send_otp';
const sendOtpForLoginUsingEmailEndpoint = 'v1/employee/email_login/send_otp';
const verifyOtpForLoginUsingMobileEndpoint = 'v1/employee/verify_otp';
const verifyOtpForLoginUsingEmailEndpoint = 'v1/employee/email_login/verify_otp';

export const sendOtpForLoginUsingMobile = (mobile) => {
  return sendOtpClient
    .post(sendOtpForLoginUsingMobileEndpoint, {
      country_code: '91',
      mobile
    })
    .then((resp) => resp?.data);
};

export const sendOtpForLoginUsingEmail = (email) => {
  return sendOtpClient
    .post(sendOtpForLoginUsingEmailEndpoint, {
      email
    })
    .then((resp) => resp?.data);
};

export const verifyOtpForLoginUsingMobile = (otp, mobile) => {
  return verifyOtpClient
    .post(verifyOtpForLoginUsingMobileEndpoint, {
      channel: 'ios',
      country_code: '91',
      mobile,
      otp
    })
    .then((resp) => resp?.data);
};

export const verifyOtpForLoginUsingEmail = (otp, email) => {
  return verifyOtpClient
    .post(verifyOtpForLoginUsingEmailEndpoint, {
      channel: 'ios',
      email,
      otp
    })
    .then((resp) => resp?.data);
};

export const sendOtpForClientMobileVerification = (clientId, countryCode, mobile) => {
  return sendOtpClient
    .post(`v1/client/${clientId}/mobile/send_otp`, {
      country_code: countryCode,
      mobile
    })
    .then((resp) => resp?.data);
};

export const verifyOtpForClientMobileVerification = (clientId, countryCode, mobile, otp) => {
  return verifyOtpClient
    .post(`v1/client/${clientId}/mobile/verify_otp`, {
      channel: 'ios',
      country_code: countryCode,
      mobile,
      otp
    })
    .then((resp) => resp?.data);
};

export const sendOtpForClientEmailVerification = (clientId, email) => {
  return sendOtpClient
    .post(`v1/client/${clientId}/email/send_otp`, {
      email
    })
    .then((resp) => resp?.data);
};

export const verifyOtpForClientEmailVerification = (clientId, email, otp) => {
  return verifyOtpClient
    .post(`v1/client/${clientId}/email/verify_otp`, {
      channel: 'ios',
      email,
      otp
    })
    .then((resp) => resp?.data);
};
