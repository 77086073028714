import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Divider, ListItemButton, Typography, useMediaQuery } from '@mui/material';
import headerStyles from 'components/Header/Header.styles';
import PropTypes from 'prop-types';
import { rolesEnums } from 'utils/enums';

export default function SearchResult({ searchResult, searchText, setIsSearchResultModalVisible }) {
  const classes = headerStyles();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.role);

  const handleClose = () => setIsSearchResultModalVisible(false);

  const isLargeScreen = useMediaQuery('(min-width: 768px)');

  return (
    <Box open={open} className={classes.searchResult}>
      <>
        <Box className={classes.flex}>
          <Typography className={classes.resultShowingText}>
            Results showing for {searchText}...
          </Typography>
          {useMediaQuery('(min-width: 1024px)') && (
            <CloseIcon className={classes.closeIcon} onClick={handleClose} />
          )}
        </Box>

        {/* clients search result */}
        {searchResult?.clients?.[0]?.clients?.length ? (
          <Box sx={{ padding: '12px 0' }}>
            {searchResult?.clients?.[0]?.clients?.map((client) => (
              <ListItemButton
                href={`/client-details/${client?.app_user_id}`}
                className={classes.searchResultItem}
                key={client?.customer_id}>
                <SearchIcon sx={{ marginRight: '8px' }} />
                {client?.full_name} {client?.customer_id ? `| Id: ${client?.customer_id}` : ''}
              </ListItemButton>
            ))}
            <Divider />
            <Typography className={classes.category}>In Clients</Typography>
          </Box>
        ) : null}

        {/* properties search result */}
        {searchResult?.properties?.[0]?.properties?.length ? (
          <Box sx={{ padding: '12px 0' }}>
            {searchResult?.properties?.[0]?.properties?.map((property) => (
              <ListItemButton
                href={`/property-details/${property?.app_user_id}/${property?.property_id}`}
                className={classes.searchResultItem}
                key={property?.property_id}>
                <SearchIcon sx={{ marginRight: '8px' }} /> {property?.full_name} | Id:{' '}
                {property?.prop_id}
              </ListItemButton>
            ))}
            <Divider />
            <Typography className={classes.category}>In Properties</Typography>
          </Box>
        ) : null}

        {/* transactional orders search result */}
        {userRole !== rolesEnums.CHANNEL_PARTNER &&
        searchResult?.transaction_orders?.[0]?.orders?.length ? (
          <Box sx={{ padding: '12px 0' }}>
            {searchResult?.transaction_orders?.[0]?.orders?.map((order) => (
              <ListItemButton
                href={`/order/transactional-details/${order?.transaction_order_id}`}
                className={classes.searchResultItem}
                key={order?.transaction_order_id}>
                <SearchIcon sx={{ marginRight: '8px' }} /> {order?.full_name} | Id:
                {order?.transaction_order_no}
              </ListItemButton>
            ))}
            <Divider />
            <Typography className={classes.category}>In Transactional Orders</Typography>
          </Box>
        ) : null}

        {/* Non-transactional orders search result */}
        {userRole !== rolesEnums.CHANNEL_PARTNER && searchResult?.normal_orders?.[0]?.orders?.length
          ? isLargeScreen && (
              <Box sx={{ padding: '12px 0' }}>
                {searchResult?.normal_orders?.[0]?.orders?.map((order) => (
                  <ListItemButton
                    href={`/order/non-transactional-details/${order?.order_uuid}`}
                    className={classes.searchResultItem}
                    key={order?.order_uuid}>
                    <SearchIcon sx={{ marginRight: '8px' }} /> {order?.full_name} | Id:{' '}
                    {order?.normal_order_id}
                  </ListItemButton>
                ))}
                <Divider />
                <Typography className={classes.category}>In Non-Transactional Orders</Typography>
              </Box>
            )
          : null}

        {/* leads search result */}
        {searchResult?.leads?.[0]?.leads?.length ? (
          <Box sx={{ padding: '12px 0' }}>
            {searchResult?.leads?.[0]?.leads?.map((lead) => (
              <ListItemButton
                href={`/lead-details/${lead?.lead_id}`}
                className={classes.searchResultItem}
                key={lead?.lead_id}>
                <SearchIcon sx={{ marginRight: '8px' }} />
                {lead?.full_name}
              </ListItemButton>
            ))}
            <Divider />
            <Typography className={classes.category}>In Leads</Typography>
          </Box>
        ) : null}

        {/* buyers/prospects search result */}
        {userRole !== rolesEnums.CHANNEL_PARTNER &&
        searchResult?.prospects?.[0]?.prospects?.length ? (
          <Box sx={{ padding: '12px 0' }}>
            {searchResult?.prospects?.[0]?.prospects?.map((prospect) => (
              <ListItemButton
                href={`/order/transactional-details/${prospect.transaction_order_id}`}
                className={classes.searchResultItem}
                key={prospect?.prospect_name}>
                <SearchIcon sx={{ marginRight: '8px' }} />
                {prospect?.prospect_name}
              </ListItemButton>
            ))}
            <Divider />
            <Typography className={classes.category}>In Buyers</Typography>
          </Box>
        ) : null}
      </>

      <Button
        onClick={() => {
          setIsSearchResultModalVisible(false);
          navigate(`/search?search_param=${searchText}`);
        }}
        className={classes.viewAll}>
        View All
      </Button>
    </Box>
  );
}

SearchResult.propTypes = {
  searchResult: PropTypes.func,
  searchText: PropTypes.string,
  setIsSearchResultModalVisible: PropTypes.func
};
