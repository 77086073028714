import { makeStyles } from '@mui/styles';

const amountInWordsStyles = makeStyles({
  amount: {
    color: '#75013D',
    fontWeight: '600 !important'
  }
});

export default amountInWordsStyles;
