import { createSlice } from '@reduxjs/toolkit';
import {
  addAssignPRM,
  addPartialRevenuePayment,
  addPaymentsInPrimarySalesTransOrderSubStatus,
  addPerformancePoint,
  addPrimarySalesTransactionalOrder,
  addPrimarySalesTransOrderComment,
  addPrimarySalesTransOrderExtComment,
  addPrimarySalesTransOrderExtSubComment,
  addTokenAdvancePayment,
  addTotalRevenuePayment,
  addTransAssignStatus,
  assignPrimarySalesTransOrderRM,
  cancelPrimarySalesTransactionalOrder,
  deletePrimarySalesTransOrderFile,
  getPrimarySalesTransactionalOrderDetailsById,
  getPrimarySalesTransactionalOrdersListing,
  getPrimarySalesTransactionalOrdersListingByClientId,
  getPrimarySalesTransOrderComments,
  getPrimarySalesTransOrderExtComments,
  getPrimarySalesTransOrderExtSubComments,
  getPrimarySalesTransOrderFiles,
  getPSPerformancePointList,
  getPSTransPaymentList,
  getPSTransPerformanceCredit,
  primarySalesTransactionCart,
  primarySalesTransOrderSubStatusAdd,
  primarySalesTransOrderSubStatusUpdate,
  searchInPrimarySalesTransComment,
  updateAssignPRM,
  updatePaymentsInPrimarySalesTransOrderSubStatus,
  updatePrimarySalesSubAssignFOS,
  updatePrimarySalesTransactionalOrderStatus,
  updatePrimarySalesTransOrderSubAssignRM,
  updatePSTransAssignStatus,
  uploadPrimarySalesTransOrderCommentFile,
  uploadPrimarySalesTransOrderFile,
  verifyPayment,
  verifyPaymentByBranchHead,
  verifyPaymentByNationalHead,
  verifyTDS
} from 'features/PrimarySales/TransactionalOrders/primarySalesTransOrder';
import { toast } from 'react-toastify';

export const primarySalesTransOrderFilterInitialValues = {
  assignTo: '',
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  orderStatus: '',
  orderType: '',
  rewm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const primarySalesTransOrderSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: primarySalesTransOrderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'primarySalesTransOrder',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const primarySalesTransOrderAction = primarySalesTransOrderSlice.actions;

export const handleGetPrimarySalesTransactionalOrdersListing = (params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPrimarySalesTransactionalOrdersListing(params);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPrimarySalesTransactionalOrderDetailsById = (orderId) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPrimarySalesTransactionalOrderDetailsById(orderId);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPrimarySalesTransactionalOrdersListingByClientId =
  (clientId, page) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await getPrimarySalesTransactionalOrdersListingByClientId(clientId, page);
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleAddPrimarySalesTransactionalOrder = (clientId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPrimarySalesTransactionalOrder(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleCancelPrimarySalesTransactionalOrder = (clientId) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await cancelPrimarySalesTransactionalOrder(clientId);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesTransactionalOrder =
  (clientId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await handleUpdatePrimarySalesTransactionalOrder(clientId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleGetPrimarySalesTransOrderComments = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPrimarySalesTransOrderComments(id);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAddPrimarySalesTransOrderComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPrimarySalesTransOrderComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleCreatePrimarySalesTransactionCart = (clientId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await primarySalesTransactionCart(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAssignPrimarySalesTransOrderRM =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await assignPrimarySalesTransOrderRM(clientId, orderId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handlePrimarySalesTransOrderFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await uploadPrimarySalesTransOrderFile(clientId, files);
    if (data?.message) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPrimarySalesTransOrderFiles = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPrimarySalesTransOrderFiles(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleDeletePrimarySalesTransOrderFile =
  (clientId, orderId, fileId) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await deletePrimarySalesTransOrderFile(clientId, orderId, fileId);
      if (data?.message) {
        toast.success('File deleted successfully!');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleAddPrimarySalesTransOrderExtComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPrimarySalesTransOrderExtComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPrimarySalesTransOrderExtComments = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPrimarySalesTransOrderExtComments(id);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAddPrimarySalesTransOrderExtSubComment = (id, comments) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPrimarySalesTransOrderExtSubComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPrimarySalesTransOrderExtSubComments = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPrimarySalesTransOrderExtSubComments(id);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handlePrimarySalesTransOrderCommentFileUpload = (id, files) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await uploadPrimarySalesTransOrderCommentFile(id, files);
    if (data?.message) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleSearchInPrimarySalesTransComment =
  (offset, limit, search_param, orderId) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await searchInPrimarySalesTransComment(offset, limit, search_param, orderId);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handlePrimarySalesTransOrderStatusUpdate =
  (clientId, transOrderId, statusId, subStatusId) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await updatePrimarySalesTransactionalOrderStatus(
        clientId,
        transOrderId,
        statusId,
        subStatusId
      );
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleSubAssignFOSPrimarySalesTrans =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await updatePrimarySalesSubAssignFOS(clientId, orderId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleSubAssignRMPrimarySalesTrans =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await updatePrimarySalesTransOrderSubAssignRM(clientId, orderId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleAddPrimarySalesOrderSubStatus =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await primarySalesTransOrderSubStatusAdd(clientId, orderId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleAddPaymentsInPrimarySalesOrderSubStatus = (formData) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPaymentsInPrimarySalesTransOrderSubStatus(formData);
    if (data) toast.success(data?.message || 'Details added successfully');
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesOrderSubStatus =
  (clientId, orderId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await primarySalesTransOrderSubStatusUpdate(clientId, orderId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleUpdatePaymentsInPrimarySalesOrderSubStatus =
  (paymentId, formData) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await updatePaymentsInPrimarySalesTransOrderSubStatus(paymentId, formData);
      if (data) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handlePSTransAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addTransAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handlePSTransAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPerformanceCredit = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPSTransPerformanceCredit(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleGetPSTransPayments = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPSTransPaymentList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAddPSTokenPayment = (formData) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addTokenAdvancePayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAddPSTotalRevenuePayment = (formData) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addTotalRevenuePayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAddPSPartialRevenuePayment = (formData) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPartialRevenuePayment(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleUpdatePSTransAssignStatus = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await updatePSTransAssignStatus(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleUpdatePSTransAssignPRM = (clientId, orderId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await updateAssignPRM(clientId, orderId, params);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleVerifyTDSPS = (clientId, orderId, isTDSVerified) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await verifyTDS(clientId, orderId, isTDSVerified);
    if (data) toast.success(data?.message || 'Order updated successfully');
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleVerifyPaymentPS =
  (clientId, orderId, isPaymentVerified, emailId) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await verifyPayment(clientId, orderId, isPaymentVerified, emailId);
      if (data) toast.success(data?.message || 'Order updated successfully');
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleVerifyPaymentByBranchHeadPS =
  (clientId, orderId, isPaymentVerifiedByBranchHead, prmEmailId, bhEmailId) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await verifyPaymentByBranchHead(
        clientId,
        orderId,
        isPaymentVerifiedByBranchHead,
        prmEmailId,
        bhEmailId
      );
      if (data) toast.success(data?.message || 'Order updated successfully');
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handleVerifyPaymentByNationalHeadPS =
  (clientId, orderId, isPaymentVerifiedByNationalHead, bhEmailId) => async (dispatch) => {
    try {
      dispatch(primarySalesTransOrderAction.setIsLoading(true));
      const data = await verifyPaymentByNationalHead(
        clientId,
        orderId,
        isPaymentVerifiedByNationalHead,
        bhEmailId
      );
      if (data) toast.success(data?.message || 'Order updated successfully');
      return data;
    } catch (error) {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesTransOrderAction.setIsLoading(false));
    }
  };

export const handlePSPerformancePointList = (clientId, orderId) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await getPSPerformancePointList(clientId, orderId);
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export const handleAddPSPerformancePoint = (formData) => async (dispatch) => {
  try {
    dispatch(primarySalesTransOrderAction.setIsLoading(true));
    const data = await addPerformancePoint(formData);
    if (data) toast.success('Details added successfully');
    return data;
  } catch (error) {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesTransOrderAction.setIsLoading(false));
  }
};

export default primarySalesTransOrderSlice;