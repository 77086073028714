import React from 'react';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

export default function FilterChip({ label, value, onDelete }) {
  return (
    value && (
      <Chip
        label={`${label ? label + ' : ' : ''}${value}`}
        variant="outlined"
        onDelete={onDelete}
        sx={{
          background: 'rgba(10, 58, 103, 0.05)',
          border: '1px solid rgba(10, 58, 103, 0.15) !important',
          marginBottom: '4px',
          padding: '6px 4px !important',
          textTransform: 'capitalize'
        }}
      />
    )
  );
}

FilterChip.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.string,
  value: PropTypes.string
};
