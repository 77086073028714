import { axiosClient } from '../utils/axiosClient';

const employeeInsightsEndpoint = 'v1/employee/insights';
const employeeRolesEndpoint = 'v1/employee/roles';
const teammatesEndpoint = 'v1/employee/teams';
const portfolioManagersEndpoint = 'v1/employee/portfolio_managers';
const productCatalogSearchEndpoint = 'v1/products/catalog/search';

export const getEmployeeInsights = () => {
  return axiosClient.get(employeeInsightsEndpoint).then((resp) => resp?.data);
};

export const search = (searchText, limit = 10, offset = 0) => {
  return axiosClient
    .get(`v1/search?limit=${limit}&offset=${offset}&search_param=${searchText}`)
    .then((resp) => resp?.data);
};

export const uploadEmployeeProfileImg = (file) => {
  return axiosClient
    .post(`v1/employee/profile/image?encoded=False`, file)
    .then((resp) => resp?.data);
};

export const deleteEmployeeProfileImg = () => {
  return axiosClient.delete(`v1/employee/profile/image`);
};

export const getEmployeeRoles = () => {
  return axiosClient.get(employeeRolesEndpoint).then((resp) => resp?.data);
};

export const getTeammates = () => {
  return axiosClient.get(teammatesEndpoint).then((resp) => resp?.data);
};

export const getPortfolioManagers = () => {
  return axiosClient.get(portfolioManagersEndpoint).then((resp) => resp?.data);
};

export const productCatalogSearch = (searchParam) => {
  return axiosClient
    .get(productCatalogSearchEndpoint + `?search_param=${searchParam}`)
    .then((resp) => resp?.data);
};
