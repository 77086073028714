import axios from 'axios';

const key = process.env.REACT_APP_GPLACE_KEY;

export const googlePlacesTextSearchLatLng = (querySearch = '') => {
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  return axios
    .get(
      `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${querySearch}&key=${key}`
    )
    .then((resp) => resp?.data);
};
