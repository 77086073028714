import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import LocalStorageService from 'utils/LocalStorageService';

const localStorageService = LocalStorageService.getService();

export const sendOtpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {}
});

export const verifyOtpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { 'Grant-Type': 'password' }
});

export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {}
});

axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorageService.getAccessToken();

    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

const refreshAuthLogic = (failedRequest) =>
  sendOtpClient
    .post('v1/employee/refresh_token', null, {
      headers: {
        Authorization: `Bearer ${localStorageService.getRefreshToken()}`,
        'Grant-Type': 'refresh_token'
      }
    })
    .then((tokenRefreshResponse) => {
      localStorageService.setToken(tokenRefreshResponse?.data);

      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + tokenRefreshResponse.data.access_token;
      return Promise.resolve();
    })
    .catch((err) => {
      // clear tokens and force to login
      localStorage.clear();
      window.location.href = '/login';
      return Promise.reject(err);
    });

createAuthRefreshInterceptor(axiosClient, refreshAuthLogic);
