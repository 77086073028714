import { makeStyles } from '@mui/styles';

const dashboardOrderListStyles = makeStyles({
  box: {
    '& div': {
      width: '16px'
    },
    alignItems: 'center',
    background: '#ECFBEA',
    borderRadius: '20px',
    display: 'flex',
    padding: '5px 1rem',
    width: '30%'
  },
  circleIcon: {
    color: '#0A3A67',
    marginRight: '3rem'
  },
  main: {
    alignItems: 'center',
    backgroundColor: '#ffffff',
    border: '1px solid rgba(10, 58, 103, 0.15)',
    borderRadius: '10px',
    display: 'flex',
    flexWrap: ['wrap', 'nowrap'],
    fontFamily: 'Lato',
    margin: '0 1rem',
    marginBottom: '1rem',
    padding: '1rem'
  },
  numbers: {
    color: '#0A3A67',
    flex: 1,
    fontSize: '20px !important',
    fontWeight: '600 !important'
  },
  percent: {
    color: '#46D339',
    fontSize: '14px !important',
    fontWeight: '400 !important'
  },
  stockUpArrow: {
    paddingBottom: '4px',
    paddingLeft: '4px',
    width: '100%'
  },
  type: {
    color: '#0A3A67',
    flex: 1,
    fontWeight: '500 !important'
  }
});

export default dashboardOrderListStyles;
