import { createSlice } from '@reduxjs/toolkit';
import {
  addStPropertyComment,
  addStPropertyDetails,
  deleteStPropertyFile,
  getStProperties,
  getStPropertiesByClientId,
  getStPropertyComments,
  getStPropertyDetails,
  getStPropertyFiles,
  getStPropertyLiveabilityDetails,
  updateStPropertyDetails,
  updateStPropertyVerifiedDetails,
  uploadStPropertyFile
} from 'api/stProperty';
import { toast } from 'react-toastify';

export const stPropertyFilterInitialValues = {
  branch: '',
  branchHead: '',
  channelPartner: '',
  createdFromDate: '',
  createdToDate: '',
  customerType: '',
  locality: '',
  maxProfileCompletion: '',
  minProfileCompletion: '',
  propertyStatus: '',
  referralType: '',
  rewm: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  transactionType: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const stPropertySlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: stPropertyFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'stPropertySlice',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const stPropertyActions = stPropertySlice.actions;

export const handleGetStProperties = (params) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await getStProperties(params);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleGetStPropertyDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await getStPropertyDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleGetStPropertiesByClientId = (clientId, page, limit) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await getStPropertiesByClientId(clientId, page, limit);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleAddStPropertyDetails = (clientId, params) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await addStPropertyDetails(clientId, params);
    if (data) {
      toast.success(data?.message || 'Property added successfully');
    }
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleUpdateStPropertyDetails = (clientId, propertyId, params) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await updateStPropertyDetails(clientId, propertyId, params);
    if (data) {
      toast.success(data?.message || 'Property updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleGetStPropertyComments = (id) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await getStPropertyComments(id);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleAddStPropertyComments = (id, comments) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await addStPropertyComment(id, comments);
    if (data) toast.success(data?.message);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleStFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await uploadStPropertyFile(clientId, files);
    if (data) toast.success('File uploaded successfully!');
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleGetStPropertyFiles = (clientId, propertyId) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await getStPropertyFiles(clientId, propertyId);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleDeleteStPropertyFile = (clientId, propertyId, fileId) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await deleteStPropertyFile(clientId, propertyId, fileId);
    if (data?.msg) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleGetStPropertyLiveabilityDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await getStPropertyLiveabilityDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export const handleUpdateStPropertyVerifiedDetails = (propertyId, params) => async (dispatch) => {
  try {
    dispatch(stPropertyActions.setIsLoading(true));
    const data = await updateStPropertyVerifiedDetails(propertyId, params);
    if (data) {
      toast.success(data?.message || 'Property updated successfully');
    }
    return data;
  } catch (error) {
    dispatch(stPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(stPropertyActions.setIsLoading(false));
  }
};

export default stPropertySlice;
