import { createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import {
  addStCallbackRemarks,
  addStProspectiveBuyer,
  createStProspectCallBackRequest,
  updateStProspect,
  updateStProspectCallBackRequest
} from './stMagik.api';

const stMagikSlice = createSlice({
  initialState: {
    isLoading: false
  },
  name: 'stMagik',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const stMagikActions = stMagikSlice.actions;

export const handleCreateStProspectCallBackRequest = (id, dateTime) => async (dispatch) => {
  try {
    dispatch(stMagikActions.setIsLoading(true));
    const data = await createStProspectCallBackRequest(id, dateTime);
    if (data?.message) {
      toast.success(data?.message || 'Successfully created prospect call back request');
    }
    return data;
  } catch (error) {
    dispatch(stMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stMagikActions.setIsLoading(false));
  }
};

export const handleUpdateStProspectCallBackRequest = (id, params) => async (dispatch) => {
  try {
    dispatch(stMagikActions.setIsLoading(true));
    const data = await updateStProspectCallBackRequest(id, params);
    if (data) {
      toast.success(data?.message || 'Successfully updated prospect call back request');
    }
    return data;
  } catch (error) {
    dispatch(stMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stMagikActions.setIsLoading(false));
  }
};

export const handleAddStProspectiveBuyer = (params) => async (dispatch) => {
  try {
    dispatch(stMagikActions.setIsLoading(true));
    const data = await addStProspectiveBuyer(params);
    if (data) {
      toast.success(data?.message || 'Successfully added prospective buyer');
    }
    return data;
  } catch (error) {
    dispatch(stMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stMagikActions.setIsLoading(false));
  }
};

export const handleUpdateStProspect = (id, param) => async (dispatch) => {
  try {
    dispatch(stMagikActions.setIsLoading(true));
    const data = await updateStProspect(id, param);
    if (data?.message) {
      toast.success(data?.message || 'Successfully updated prospect details');
    }
    return data;
  } catch (error) {
    dispatch(stMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stMagikActions.setIsLoading(false));
  }
};

export const handleAddStCallbackRemarks = (callbackId, params) => async (dispatch) => {
  try {
    dispatch(stMagikActions.setIsLoading(true));
    const data = await addStCallbackRemarks(callbackId, params);
    if (data) {
      toast.success(data?.message || 'Successfully added callback remark');
    }
    return data;
  } catch (error) {
    dispatch(stMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(stMagikActions.setIsLoading(false));
  }
};

export default stMagikSlice;
