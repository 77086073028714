import { makeStyles } from '@mui/styles';

const leadDetailsStyles = makeStyles({
  activeStatusCard: {
    background: 'rgba(19, 148, 47, 0.05)',
    border: '0.5px solid #13942F',
    color: '#13942F'
  },
  addComment: {
    '& .MuiInputBase-input::placeholder': {
      fontStyle: 'italic !important',
      fontWeight: '400 !important'
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '10px !important',
      padding: '2px 8px !important'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px !important',
      padding: '5px !important'
    },
    margin: '0 1rem !important',
    marginBottom: '0 !important',
    width: '100% !important'
  },
  box: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    height: '620px !important',
    maxWidth: '654px !important',
    minWidth: '480px !important',
    overflowY: 'scroll',
    padding: '2rem'
  },
  btn: {
    border: '1px solid #0A3A67 !important',
    color: ' #0A3A67 !important',
    textTransform: 'capitalize !important'
  },
  callbackContainer: {
    background: 'white',
    borderRadius: '30px',
    boxShadow: '6px 6px 36px 0px #0000000F',
    margin: '20px 0',
    padding: '24px 20px',
    position: 'relative'
  },
  callbackDateTime: {
    color: 'rgba(10, 58, 103, 0.60)',
    fontSize: '14px',
    fontWeight: 500,
    marginRight: '20px !important'
  },
  callbackDateTimeContainer: {
    alignItems: 'center',
    background: '#FFF',
    border: '1px solid rgba(10, 58, 103, 0.15)',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    padding: '10px 1rem'
  },
  cbStatus: {
    backgroundColor: 'rgba(10, 58, 103, 0.05)',
    borderRadius: '12px',
    color: '#6785A6',
    fontSize: '14px !important',
    marginLeft: '1rem !important',
    padding: '2px 12px'
  },
  comment: {
    color: '#0A3A6799 !important',
    fontSize: '1rem !important',
    fontWeight: '400 !important'
  },
  commentBox: {
    background: '#fff',
    borderRadius: '20px  !important',
    margin: '1rem 0  !important',
    padding: '1rem  !important'
  },
  commentContainer: { borderRadius: '10px', margin: '1rem 0', padding: '1.2rem' },
  commentText: {
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontSize: '14px !important'
  },
  createdAt: {
    color: '#6785A1',
    fontSize: '14px !important',
    marginLeft: '1rem !important'
  },
  createdBy: {
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontWeight: '500'
  },
  editBtn: {
    border: '1px solid #0A3A67 !important',
    borderRadius: '10px'
  },
  expiredStatusCard: {
    background: 'rgba(255, 107, 107, 0.05)',
    border: '0.5px solid #FF6B6B',
    color: '#FF6B6B'
  },
  firstRow: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-between'
  },
  main: {
    '@media (max-width: 767px)': {
      padding: '24px 12px'
    },
    '@media (min-width: 1440px)': {
      margin: '0 auto',
      maxWidth: '1123px'
    },
    backgroundPositionY: 'top',
    backgroundSize: 'contain',
    fontFamily: 'Lato',
    padding: '24px 54px'
  },
  menuBtn: {
    border: '2px solid rgba(10, 58, 103, 0.15) !important',
    borderRadius: '8px !important',
    cursor: 'pointer'
  },
  menuDropdown: {
    '& .MuiAvatar-root': {
      height: 36,
      ml: -0.5,
      mr: 1,
      width: 44
    },
    '&:before': {
      bgcolor: 'background.paper',
      content: '""',
      display: 'block',
      height: 10,
      position: 'absolute',
      right: 14,
      top: 0,
      transform: 'translateY(-50%) rotate(45deg)',
      width: 10,
      zIndex: 0
    },
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    overflow: 'visible'
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    height: '100vh !important',
    justifyContent: 'center',
    width: '100vw !important'
  },
  noCommentBox: {
    background: '#fff',
    border: '1px solid rgba(10, 58, 103, 0.15)',
    borderRadius: '10px ',
    marginBottom: '2rem',
    marginTop: '1rem',
    padding: '2rem 0',
    textAlign: 'center'
  },
  outline: {
    background: '#FFF',
    border: '1px solid rgba(10, 58, 103, 0.15)',
    borderRadius: '10px',
    margin: '20px 0',
    padding: '24px 20px'
  },
  property: {
    color: '#0A3A6799'
  },
  radioButton: {
    color: 'rgba(10, 58, 103, 0.60)',
    fontSize: '14px'
  },
  radioLabel: {
    color: 'rgba(10, 58, 103, 0.60) !important',
    fontSize: '16px !important',
    marginBottom: '-4px',
    marginRight: '20px'
  },
  section: {
    '& h1': {
      color: '#0A3A67',
      fontSize: '1.1rem',
      fontWeight: '500',
      marginBottom: '1.5rem'
    },
    background: '#fff',
    borderRadius: '30px',
    boxShadow: '6px 6px 36px 0px #0000000F',
    marginBottom: '2rem',
    padding: '1.5rem 1.5rem 0 1.5rem'
  },
  statusCard: {
    borderRadius: '50px !important',
    fontSize: '14px',
    fontWeight: 500,
    padding: '6px 30px !important',
    textAlign: 'center'
  },
  title: {
    color: '#0A3A67',
    fontSize: '1.2rem !important',
    fontWeight: '500 !important',
    textTransform: 'capitalize'
  },
  titleBox: {
    alignItems: 'center',
    background: '#fff',
    border: '1px solid rgba(10, 58, 103, 0.2)',
    borderRadius: '10px',
    boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem 0',
    padding: '1rem'
  },
  value: {
    color: '#0A3A67'
  },
  viewAll: {
    color: '#0A3A67 !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textDecorationLine: 'underline !important',
    textTransform: 'capitalize !important'
  },
  viewMoreBtn: {
    color: '#0A3A67 !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    padding: '0 !important',
    textDecorationLine: 'underline !important',
    textTransform: 'capitalize !important'
  }
});

export default leadDetailsStyles;
