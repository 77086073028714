import { makeStyles } from '@mui/styles';

const commentStyles = makeStyles({
  addComment: {
    '& .MuiInputBase-input::placeholder': {
      fontStyle: 'italic !important'
    },
    '& .MuiOutlinedInput-input': {
      borderRadius: '10px !important',
      padding: '2px 8px !important'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px !important',
      padding: '5px !important'
    },
    margin: '0 1rem !important',
    marginBottom: '0 !important',
    width: '100% !important'
  },
  box: {
    '@media (max-width:768px)': {
      height: '100% !important',
      maxWidth: '100% !important',
      minWidth: '100% !important'
    },
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    height: '620px !important',
    maxWidth: '654px !important',
    minWidth: '480px !important',
    overflowY: 'scroll',
    padding: '2rem'
  },
  closeBtn: {
    background: '#0A3A6717 !important',
    borderRadius: '6px !important',
    padding: '.5rem !important'
  },
  comment: {
    color: '#0A3A6799 !important',
    fontSize: '1rem !important',
    fontWeight: '400 !important'
  },
  commentBox: {
    borderRadius: '10px  !important',
    margin: '1rem 0  !important'
  },

  iconBox: {
    borderRadius: '9px !important',
    padding: '.5rem !important'
  },

  main: {
    '@media (max-width:768px)': {
      padding: '36px 12px'
    },
    backgroundColor: '#ffffff',
    border: '1px solid rgba(10, 58, 103, 0.40)',
    borderRadius: '24px',
    height: '100%',
    padding: '16px 24px'
  },
  modal: {
    '@media (max-width:768px)': {
      height: '80% !important',
      margin: 'auto auto',
      width: '70% !important'
    },
    alignItems: 'center',
    display: 'flex',
    height: '100vh !important',
    justifyContent: 'center',
    width: '100vw !important'
  },
  viewMoreBtn: {
    color: '#0A3A67 !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    textDecorationLine: 'underline !important',
    textTransform: 'capitalize !important'
  }
});

export default commentStyles;
