import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const accountMenuStyles = makeStyles({
  menu: {
    '& .MuiAvatar-root': {
      height: 32,
      ml: -0.5,
      mr: 1,
      width: 32
    },
    '&:before': {
      bgcolor: 'background.paper',
      content: '""',
      display: 'block',
      height: 10,
      position: 'absolute',
      right: 14,
      top: 0,
      transform: 'translateY(-50%) rotate(45deg)',
      width: 10,
      zIndex: 0
    },
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '1rem',
    overflow: 'visible'
  },
  menuItems: {
    '& p': {
      color: COLORS.PRIMARY,
      fontFamily: 'Lato',
      fontSize: '16px !important',
      fontWeight: '500',
      marginLeft: '8px !important'
    },
    padding: '12px 42px 12px 18px !important'
  }
});

export default accountMenuStyles;
