const LocalStorageService = (function () {
  var _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(tokenObj) {
    localStorage.setItem('accessToken', tokenObj?.access_token);
    localStorage.setItem('refreshToken', tokenObj?.refresh_token);
  }

  function _getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  function _getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  function _clearToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  return {
    clearToken: _clearToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    getService: _getService,
    setToken: _setToken
  };
})();
export default LocalStorageService;
