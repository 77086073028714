import { makeStyles } from '@mui/styles';

const notificationCardStyles = makeStyles({
  main: {
    alignItems: 'center',
    background: '#0A3A670D',
    margin: '.2rem 0 !important',
    padding: '1rem !important'
  }
});

export default notificationCardStyles;
