import { axiosClient } from 'utils/axiosClient';

export const getLoans = (filters = {}) => {
  const {
    branch,
    branchHead,
    createdFromDate,
    createdToDate,
    customerType,
    limit,
    loanStatus,
    loanType,
    page,
    premiumType,
    rewm,
    rm,
    sortAt,
    sortBy,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    branch,
    branch_head: branchHead,
    from_date: createdFromDate,
    limit,
    loan_type: loanType,
    offset: limit * page,
    portfolio_manager: rewm,
    premium_type: premiumType,
    rm_name: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: loanStatus,
    to_date: createdToDate,
    type: customerType,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('v1/clients/loans' + queryString).then((resp) => resp?.data);
};

export const getLoanDetails = (clientId, loanId) => {
  return axiosClient.get(`v1/client/${clientId}/loans/${loanId}`).then((resp) => resp?.data);
};
export const postAddLoanDetails = (clientId, data) => {
  return axiosClient.post(`v1/client/${clientId}/loan`, data).then((resp) => resp?.data);
};

export const updateLoanDetails = (clientId, loanId, data) => {
  return axiosClient
    .put(`v1/client/${clientId}/loans/${loanId}/update`, data)
    .then((resp) => resp?.data);
};

export const addExistingLoan = (clientId, loanId, data) => {
  return axiosClient
    .post(`v1/client/${clientId}/loan/${loanId}/exisitng_loan`, data)
    .then((resp) => resp?.data);
};

export const updateExistingLoan = (clientId, existingLoanId, data) => {
  return axiosClient
    .put(`v1/client/${clientId}/loans/existing_loan/${existingLoanId}/update`, data)
    .then((resp) => resp?.data);
};

export const getLoanDetailsByClientId = (clientId) => {
  return axiosClient.get(`v1/client/${clientId}/loans`).then((resp) => resp?.data);
};

export const getLoanDetailsByPropertyId = (clientId, propertyId) => {
  return axiosClient
    .get(`v1/client/${clientId}/property/${propertyId}/loans`)
    .then((resp) => resp?.data);
};

export const getLoanDropdownList = () => {
  return axiosClient.get(`v1/loans/dropdown/values`).then((resp) => resp?.data);
};

export const uploadLoanFile = (clientId, files) => {
  return axiosClient
    .post(`v1/client/loans/fileupload?encoded=false`, files)
    .then((resp) => resp?.data);
};

export const getLoanFiles = (clientId) => {
  return axiosClient.get(`v1/client/loans/${clientId}/filelist`).then((resp) => resp?.data);
};

export const deleteLoanFile = (loanId, fileId) => {
  return axiosClient
    .delete(`v1/client/loans/${loanId}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};

export const getCallBackDetailsByLoanId = (loanId) => {
  return axiosClient.get(`v1/client/loan/${loanId}/call_back`).then((resp) => resp?.data);
};

export const addCallBackLoanDetails = (loanId, params) => {
  return axiosClient.post(`v1/client/loan/${loanId}/call_back`, params).then((resp) => resp?.data);
};

export const getSourceTypeDropdownValues = () => {
  return axiosClient.get(`v2/call_back/dropdown/values`).then((resp) => resp?.data);
};

export const updateCallBackLoanDetails = (loanId, callbackId, params) => {
  return axiosClient
    .put(`v1/client/loan/${loanId}/call_back/${callbackId}`, params)
    .then((resp) => resp?.data);
};

export const updateLoanStatus = (loanId, params) => {
  return axiosClient
    .patch(`v1/client/loans/${loanId}/status/update`, params)
    .then((resp) => resp?.data);
};
