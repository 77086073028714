import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const filterStyles = makeStyles({
  applyFiltersButton: {
    backgroundColor: COLORS.PRIMARY,
    fontFamily: 'Lato',
    textTransform: 'capitalize'
  },
  box: {
    '@media (max-width:768px)': {
      marginTop: '22rem'
    },
    '@media (max-width:900px)': {
      marginTop: '12rem'
    },
    backgroundColor: '#FFF',
    borderRadius: '10px',
    marginTop: '2rem',
    maxHeight: '80vh !important',
    overflow: 'scroll',
    padding: '2rem',
    position: 'relative',
    width: '70% !important'
  },
  closeIcon: { cursor: 'pointer', position: 'absolute', right: '20px', top: '16px' },
  filterByHeading: {
    color: '#093A67',
    fontWeight: 500,
    paddingBottom: '4px'
  },
  filterLabel: {
    fontFamily: 'Lato',
    fontSize: '14px'
  },
  modal: {
    '@media (max-width:900px)': {
      bottom: '5%',
      left: '2%',
      right: '2%',
      top: '5%'
    },
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  resetButton: {
    fontFamily: 'Lato',
    textTransform: 'capitalize'
  }
});

export default filterStyles;
