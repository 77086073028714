import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Logout, Quiz as QuizIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Divider, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { userSignout } from 'store/authSlice';
import accountMenuStyles from './AccountMenu.styles';

export default function AccountMenu({ anchorEl, handleClose, open, setIsOpenFaq }) {
  const classes = accountMenuStyles();
  const dispatch = useDispatch();
  const userName = useSelector((state) => state?.auth?.userName);
  const userAvatar = useSelector((state) => state?.auth?.userAvatar);

  const handleLogout = () => dispatch(userSignout());

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      className={classes.menu}
      PaperProps={{
        elevation: 0
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem className={classes.menuItems}>
        <Avatar src={userAvatar || ''} />
        <Typography>{userName || 'My Account'} </Typography>
      </MenuItem>
      <Divider />
      <MenuItem className={classes.menuItems}>
        <a
          href="https://airtable.com/appYSVKAFJ9TqK3q1/shrxCjPiD7ICV7N8r"
          target="_blank"
          style={{ display: 'flex' }}
          rel="noreferrer">
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Raise a feedback</Typography>
        </a>
      </MenuItem>
      <MenuItem className={classes.menuItems} onClick={() => setIsOpenFaq(true)}>
        <ListItemIcon>
          <QuizIcon fontSize="small" />
        </ListItemIcon>
        <Typography>FAQ</Typography>
      </MenuItem>
      <MenuItem className={classes.menuItems} onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        <Typography>Logout</Typography>
      </MenuItem>
    </Menu>
  );
}

AccountMenu.propTypes = {
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  setIsOpenFaq: PropTypes.func
};
