import { makeStyles } from '@mui/styles';

const VerifyPropertyStyles = makeStyles({
  box: {
    '@media (max-width:768px)': {
      height: '100% !important',
      maxWidth: '100% !important',
      minWidth: '100% !important'
    },
    backgroundColor: '#E5EFF9',
    borderRadius: '30px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    height: '80%',
    overflowY: 'scroll',
    padding: '2rem',
    width: '70%'
  },
  btn: {
    borderRadius: '8px !important',
    fontSize: '1rem',
    padding: '6px 2rem !important',
    textTransform: 'capitalize !important'
  },
  closeBtn: {
    background: '#0A3A6717 !important',
    border: '1px solid gba(10, 58, 103, 0.60) !important',
    borderRadius: '6px !important',
    padding: '.5rem !important'
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    gap: '1rem !important',
    justifyContent: 'space-between'
  },
  modal: {
    '@media (max-width:768px)': {
      height: '80% !important',
      margin: 'auto auto',
      width: '70% !important'
    },
    alignItems: 'center',
    display: 'flex',
    height: '100vh !important',
    justifyContent: 'center',
    width: '100vw !important'
  },
  options: {
    color: '#0A3A67 !important',
    fontFamily: 'Literata !important',
    fontSize: '15px !important',
    fontWeight: '500 !important'
  },
  title: {
    background: '#fff',
    borderRadius: '10px',
    color: '#0A3A67',
    flex: 1,
    fontSize: '1.2rem',
    fontWeight: '500',
    padding: '8px 1rem'
  }
});

export default VerifyPropertyStyles;
