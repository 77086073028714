import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Edit as EditIcon } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import Header from 'components/Header/Header';
import { handleGetByIdVendor } from 'store/venderSlice';
import brokerDetailsStyles from './DetailsVendor.styles';

export default function VenderDetails() {
  const classes = brokerDetailsStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { vendorId } = useParams();

  const [vendorDetails, setVendorDetails] = useState([]);

  useEffect(() => {
    getVendorDetails();
  }, []);

  const getVendorDetails = async () => {
    const res = await dispatch(handleGetByIdVendor(vendorId));

    setVendorDetails(res?.[0] || []);
  };

  return (
    <>
      <Box className={classes.main}>
        <Header />

        <Box className={classes.titleBox}>
          <Typography className={classes.title}>Vendor Details</Typography>
          <EditIcon
            sx={{ color: '#0A3A67' }}
            onClick={() => navigate(`/vendor-edit/${vendorId}`)}
          />
        </Box>

        {/* Broker details */}
        <Box className={classes.section}>
          <Grid container sx={{ justifyContent: 'space-between' }}>
            <Grid item lg={4} xs={12} sx={{ marginBottom: '2rem' }}>
              <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>Company name</Typography>
              <Typography sx={{ color: '#0A3A67' }}>
                {vendorDetails?.company_name || '-'}
              </Typography>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ marginBottom: '2rem' }}>
              <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>Contact</Typography>
              <Typography sx={{ color: '#0A3A67' }}>{vendorDetails?.mobile_no || '-'}</Typography>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ marginBottom: '2rem' }}>
              <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>Email</Typography>
              <Typography sx={{ color: '#0A3A67' }}>{vendorDetails?.email || '-'}</Typography>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ marginBottom: '2rem' }}>
              <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>Branch</Typography>
              <Typography sx={{ color: '#0A3A67' }}>{vendorDetails?.branch || '-'}</Typography>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ marginBottom: '2rem' }}>
              <Typography sx={{ color: 'rgba(10, 58, 103, 0.60)' }}>Category</Typography>
              <Typography sx={{ color: '#0A3A67' }}>{vendorDetails?.category || '-'}</Typography>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ marginBottom: '2rem' }}></Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
