import { createSlice } from '@reduxjs/toolkit';
import {
  deleteEmployeeProfileImg,
  getEmployeeInsights,
  getPortfolioManagers,
  getTeammates,
  productCatalogSearch,
  search,
  uploadEmployeeProfileImg
} from 'api/main';
import { toast } from 'react-toastify';
import { authActions } from './authSlice';

const mainSlice = createSlice({
  initialState: {
    isLoading: false,
    pms: [],
    teammates: []
  },
  name: 'main',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setPMs(state, action) {
      state.pms = action.payload;
    },
    setTeammates(state, action) {
      state.teammates = action.payload;
    }
  }
});

export const mainActions = mainSlice.actions;

export const handleGetEmployeeInsights = () => async (dispatch) => {
  try {
    dispatch(mainActions.setIsLoading(true));
    const data = await getEmployeeInsights();
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export const handleSearch = (searchText, limit, offset) => async (dispatch) => {
  try {
    dispatch(mainActions.setIsLoading(true));
    const data = await search(searchText, limit, offset);
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export const handleUploadProfileImg = (file) => async (dispatch) => {
  try {
    dispatch(mainActions.setIsLoading(true));
    const data = await uploadEmployeeProfileImg(file);

    if (data) {
      dispatch(authActions.setUserAvatar(data?.image_url));
      toast.success('Profile picture uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export const handleDeleteProfileImg = () => async (dispatch) => {
  try {
    dispatch(mainActions.setIsLoading(true));
    const data = await deleteEmployeeProfileImg();
    if (data) {
      dispatch(authActions.setUserAvatar(''));
      toast.success('Profile picture removed successfully!');
    }
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export const handleGetTeammates = () => async (dispatch) => {
  try {
    dispatch(mainActions.setIsLoading(true));
    const data = await getTeammates();
    dispatch(mainActions.setTeammates(data));
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export const handleGetPortfolioManagers = () => async (dispatch) => {
  try {
    dispatch(mainActions.setIsLoading(true));
    const data = await getPortfolioManagers();
    dispatch(mainActions.setPMs(data));
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export const handleProductCatalogSearch = (searchParam) => async (dispatch) => {
  try {
    const data = await productCatalogSearch(searchParam);
    return data;
  } catch (error) {
    dispatch(mainActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(mainActions.setIsLoading(false));
  }
};

export default mainSlice;
