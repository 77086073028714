import { createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import {
  addCallbackRemarks,
  addChannelPartner,
  addChannelPartnerRM,
  addProspectiveBuyer,
  createProspectCallBackRequest,
  deleteDocument,
  getBuyerProspectList,
  getChannelPartner,
  getChannelPartnerList,
  getProspectiveBuyersDropdownValues,
  getProspects,
  updateChannelPartner,
  updateProspect,
  updateProspectCallBackRequest,
  uploadDocument,
  uploadProspect
} from './magik.api';

export const buyersFilterInitialValues = {
  buyerSource: '',
  buyersType: '',
  createdFromDate: '',
  createdToDate: '',
  limit: 10,
  maxPriceRange: '',
  minPriceRange: '',
  page: 0,
  rm: '',
  sortAt: '',
  sortBy: '',
  temperature: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const magikSlice = createSlice({
  initialState: {
    channelPartners: [],
    currentTablePage: 0,
    filters: buyersFilterInitialValues,
    isLoading: false,
    prospectiveBuyersDropdownValues: [],
    rowPerPage: 10
  },
  name: 'magik',
  reducers: {
    setChannelPartners(state, action) {
      state.channelPartners = action.payload;
    },
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setProspectiveBuyersDropdownValues(state, action) {
      state.prospectiveBuyersDropdownValues = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const magikActions = magikSlice.actions;

export const handleCreateProspectCallBackRequest = (id, dateTimeRequested) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await createProspectCallBackRequest(id, dateTimeRequested);
    if (data?.message) {
      toast.success(data?.message || 'Successfully created prospect call back request');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleUpdateProspectCallBackRequest = (id, params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await updateProspectCallBackRequest(id, params);
    if (data) {
      toast.success(data?.message || 'Successfully updated prospect call back request');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleUpdateProspect = (id, param) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await updateProspect(id, param);
    if (data?.message) {
      toast.success(data?.message || 'Successfully updated prospect details');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleUploadProspect = (formData) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await uploadProspect(formData);
    if (data) {
      toast.success(data?.message || 'Successfully uploaded prospect');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleGetProspects = (page, limit) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await getProspects(page, limit);
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleGetBuyerProspectList = (params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await getBuyerProspectList(params);
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleGetProspectiveBuyersDropdownValues = () => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await getProspectiveBuyersDropdownValues();
    if (data) dispatch(magikActions.setProspectiveBuyersDropdownValues(data));
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleAddProspectiveBuyer = (params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await addProspectiveBuyer(params);
    if (data) {
      toast.success(data?.message || 'Successfully added prospective buyer');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleAddCallbackRemarks = (callbackId, params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await addCallbackRemarks(callbackId, params);
    if (data) {
      toast.success(data?.message || 'Successfully added callback remark');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleGetChannelPartnerList = () => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await getChannelPartnerList();
    dispatch(magikActions.setChannelPartners(data?.[0]?.channel_partners));
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleGetChannelPartnerDetails = (id) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await getChannelPartner(id);
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleAddChannelPartnerRM = (params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await addChannelPartnerRM(params);
    if (data) {
      toast.success(data?.message || 'Successfully added callback remark');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleAddDocument = (id, file) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await uploadDocument(id, file);
    if (data) {
      toast.success(data?.message || 'Channel Partner added successfully');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleAddChannelPartner = (params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await addChannelPartner(params);
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleUpdateChannelPartner = (id, params) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await updateChannelPartner(id, params);
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export const handleDeleteDocument = (id, fileId) => async (dispatch) => {
  try {
    dispatch(magikActions.setIsLoading(true));
    const data = await deleteDocument(id, fileId);
    if (data) {
      toast.success(data?.message || 'Document deleted successfully');
    }
    return data;
  } catch (error) {
    dispatch(magikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(magikActions.setIsLoading(false));
  }
};

export default magikSlice;
