import { axiosClient } from '../utils/axiosClient';

export const getStBillingAddressesByClientId = (clientId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${clientId}/billing_address`)
    .then((resp) => resp?.data);
};

export const getStBillingAddressById = (clientId, billingAddressId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${clientId}/billing_address/${billingAddressId}`)
    .then((resp) => resp?.data);
};

export const addStBillingAddress = (clientId, params) => {
  return axiosClient
    .post('/v2/structured_transaction/client/' + clientId + '/billing_address', params)
    .then((resp) => resp?.data);
};

export const updateStBillingAddress = (clientId, billingAddressId, params) => {
  return axiosClient
    .put(
      '/v2/structured_transaction/client/' + clientId,
      +'/billing_address/' + billingAddressId,
      params
    )
    .then((resp) => resp?.data);
};

export const deleteStBillingAddress = (clientId, callbackId) => {
  return axiosClient
    .delete('/v2/structured_transaction/client/' + clientId, +'/' + callbackId)
    .then((resp) => resp?.data);
};
