import { makeStyles } from '@mui/styles';

const sideBarCardStyles = makeStyles({
  boxButton: {
    '&:hover': {
      backgroundColor: '#e5eff9'
    },
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    padding: '8px 20px'
  },
  iconBox: {
    alignItems: 'center',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    marginRight: '8px',
    width: '32px'
  },
  notificationCountBox: {
    alignItems: 'center',
    background: '#EF5F5F',
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    fontSize: '12px  !important',
    fontWeight: '400  !important',
    height: '1.3rem',
    justifyContent: 'center',
    marginLeft: '1rem !important',
    width: '1.3rem'
  },
  sideBarText: {
    color: 'rgba(10, 58, 103, 0.60) !important',
    fontSize: '1rem !important',
    fontWeight: '500 !important'
  }
});

export default sideBarCardStyles;
