import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Modal, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { handleGetFaqDropdown } from 'store/clientSlice';
import faqModalStyles from './Faq.style';

export default function FaqModal({ isOpenFaq = false, setIsOpenFaq = () => {} }) {
  const classes = faqModalStyles();
  const dispatch = useDispatch();

  const [faqValue, setFaqValue] = useState([]);
  const [selectedFaqValue, setSelectedFaqValue] = useState('');
  const [selectedSubFaqValue, setSelectedSubFaqValue] = useState('');

  useEffect(() => {
    getFaqDropdownFn();
  }, []);

  const getFaqDropdownFn = async () => {
    const res = await dispatch(handleGetFaqDropdown());

    setFaqValue(res?.FAQ);
  };

  return (
    <Modal open={isOpenFaq} onClose={() => setIsOpenFaq(false)}>
      <Box className={classes.main}>
        <Box
          sx={{
            backgroundColor: '#0A3A6726',
            padding: '1rem 0'
          }}>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 1rem'
            }}>
            <Box sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
              <Typography className={classes.heading}>FAQs</Typography>
            </Box>
            <CloseIcon
              onClick={() => setIsOpenFaq(false)}
              sx={{
                '&: hover': {
                  background: '#0A3A670D'
                },
                border: '1px solid rgba(10, 58, 103, 0.6)',
                borderRadius: '6px',
                color: '#0A3A67',
                cursor: 'pointer',
                padding: '.3rem'
              }}
            />
          </Box>
        </Box>
        {!selectedFaqValue && (
          <>
            <Box sx={{ paddingInline: '2rem', paddingTop: '1rem' }}>
              <Box sx={{ marginBottom: '5px' }}>
                <Typography variant="h4" className={classes.title}>
                  What can we help you with?
                </Typography>
                <Typography variant="h5" className={classes.subtitle}>
                  Select a topic to resolve your issue quickly, or connect with us when needed.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: '500px',
                overflow: 'scroll',
                paddingInline: '2rem',
                paddingTop: '1rem'
              }}>
              {Object.keys(faqValue || {}).map((item) => {
                return (
                  <>
                    <Box
                      sx={{
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        border: '1px solid rgba(10, 58, 103, 0.2)',
                        borderRadius: '10px',
                        boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06)',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '25px',
                        padding: '10px 10px'
                      }}
                      onClick={() => setSelectedFaqValue(item)}>
                      <Box>
                        <Typography
                          sx={{
                            color: 'rgba(10, 58, 103, 1)',
                            fontSize: '16px',
                            textTransform: 'capitalize'
                          }}>
                          {item.toLowerCase()}
                        </Typography>
                      </Box>
                      <ArrowForwardIosIcon sx={{ color: 'rgba(10, 58, 103, 1)' }} />
                    </Box>
                  </>
                );
              })}
            </Box>
          </>
        )}

        {selectedFaqValue && (
          <>
            <Box sx={{ paddingInline: '2rem', paddingTop: '1rem' }}>
              <Box
                sx={{
                  display: 'flex',
                  marginBottom: '10px'
                }}>
                <ArrowBackIosIcon
                  sx={{
                    cursor: 'pointer',
                    marginTop: '5px'
                  }}
                  onClick={() => setSelectedFaqValue('')}
                />
                <Typography
                  variant="h4"
                  sx={{
                    marginLeft: '10px'
                  }}
                  className={classes.title}>
                  {selectedFaqValue.toLowerCase()}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: '500px',
                overflow: 'scroll',
                paddingInline: '2rem',
                paddingTop: '1rem'
              }}>
              {faqValue?.[selectedFaqValue].map((item) => {
                return (
                  <React.Fragment key={item?.faq_id}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        border: '1px solid rgba(10, 58, 103, 0.2)',
                        borderRadius: '10px',
                        boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06)',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '25px',
                        padding: '10px 10px'
                      }}
                      onClick={() => {
                        if (item?.faq_id === selectedSubFaqValue) {
                          setSelectedSubFaqValue('');
                        } else {
                          setSelectedSubFaqValue(item?.faq_id);
                        }
                      }}>
                      <Box>
                        <Typography
                          sx={{
                            color: 'rgba(10, 58, 103, 1)',
                            fontSize: '16px'
                          }}>
                          {item?.faq}
                        </Typography>
                        {selectedSubFaqValue === item?.faq_id && (
                          <Typography
                            sx={{
                              color: 'rgba(156, 156, 168, 1)',
                              fontSize: '14px',
                              marginBlock: '5px'
                            }}>
                            {item?.faq_answer}
                          </Typography>
                        )}
                      </Box>
                      {selectedSubFaqValue === item?.faq_id ? (
                        <KeyboardArrowUpIcon
                          sx={{ color: 'rgba(10, 58, 103, 1)', fontSize: 30, marginBottom: '25px' }}
                        />
                      ) : (
                        <ExpandMoreIcon sx={{ color: 'rgba(10, 58, 103, 1)', fontSize: 30 }} />
                      )}
                    </Box>
                  </React.Fragment>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

FaqModal.propTypes = {
  isOpenFaq: PropTypes.bool,
  setIsOpenFaq: PropTypes.func
};
