import { createSlice } from '@reduxjs/toolkit';
import { getVistaListing } from 'api/transactionalOrder';

export const vistaOrderFilterInitialValues = {
  assignTo: '',
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  orderStatus: '',
  orderType: '',
  rewm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const vistaOrderSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: vistaOrderFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'vistaOrder',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const vistaOrderAction = vistaOrderSlice.actions;

export const handleGetVistaListing = (params) => async (dispatch) => {
  try {
    dispatch(vistaOrderAction.setIsLoading(true));
    const data = await getVistaListing(params);
    return data;
  } catch (error) {
    dispatch(vistaOrderAction.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(vistaOrderAction.setIsLoading(false));
  }
};

export default vistaOrderSlice;
