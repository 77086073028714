import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';

import { Avatar, Box, FormControl, Grid, Modal, Stack, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import HtmlContent from 'components/HtmlContent';
import debounce from 'lodash/debounce';
import moment from 'moment';
import PropTypes from 'prop-types';
import { debounceDelay } from 'utils/constant';
import specialNotesStyles from './SpecialNotes.styles';

export default function SpecialNotes({
  addComments,
  comments,
  handleNotesClose,
  id,
  openSpecialNote,
  showEmployeeSearchResult,
  employeeSearchResult,
  setShowEmployeeSearchResult = () => {},
  handleEmployeeSearch
}) {
  const classes = specialNotesStyles();
  const [commentText, setCommentText] = useState('');
  const [tagUser, setTagUser] = useState('');

  useEffect(() => {
    tagUser?.includes('@') ? setShowEmployeeSearchResult(true) : setShowEmployeeSearchResult(false);

    if (commentText?.includes('@')) {
      const atIndex = commentText.lastIndexOf('@');
      const spaceIndex = commentText.indexOf(' ', atIndex);
      const searchParam =
        spaceIndex !== -1
          ? commentText.substring(atIndex + 1, spaceIndex)
          : commentText.substring(atIndex + 1);
      if (tagUser?.includes('@')) debouncedHandleSearch(searchParam);
    } else {
      setShowEmployeeSearchResult(false);
    }
  }, [commentText]);

  const debouncedHandleSearch = useMemo(
    () =>
      debounce((query) => {
        handleEmployeeSearch(10000, 0, query);
      }, 800 || debounceDelay),
    []
  );

  const handleMentionClick = (fullName) => {
    setCommentText((prevText) => {
      const atIndex = prevText.lastIndexOf('@');
      const newText = prevText.substring(0, atIndex) + '@' + fullName;
      return newText;
    });
    setShowEmployeeSearchResult(false);
    setTagUser('');
  };

  return (
    <Box>
      <Modal
        open={openSpecialNote}
        onClose={() => handleNotesClose(false)}
        className={classes.modal}>
        <Box className={classes.box}>
          <Box className={classes.flex}>
            <Typography>Special Notes</Typography>
            <CloseIcon onClick={() => handleNotesClose(false)} />
          </Box>
          <FormControl fullWidth sx={{ position: 'relative' }}>
            <Box>
              {showEmployeeSearchResult && (
                <Box
                  sx={{
                    background: '#fff',
                    borderRadius: '10px',
                    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.16)',
                    height: 'auto',
                    left: 0,
                    maxHeight: '200px',
                    overflow: 'scroll',
                    position: 'absolute',
                    right: 0,
                    top: '72px',
                    zIndex: 999
                  }}>
                  <Stack>
                    {employeeSearchResult?.map((emp) => (
                      <Typography
                        key={emp?.emp_id}
                        onClick={() => handleMentionClick(emp.full_name + '  ')}
                        sx={{
                          '&:hover': {
                            background: '#e5eff9',
                            cursor: 'pointer'
                          },
                          marginBottom: '4px',
                          padding: '6px 1rem'
                        }}>
                        {emp?.full_name}
                      </Typography>
                    ))}
                  </Stack>
                </Box>
              )}
              <TextField
                sx={{ margin: '12px 0' }}
                fullWidth
                value={commentText}
                placeholder="Add special note here and then press enter"
                name="specialNote"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === '@') setTagUser('@');
                }}
                onChange={(e) => {
                  setCommentText(e.target.value);
                  if (tagUser) {
                    if (e.target.value) setTagUser(e.target.value);
                  }
                }}
                multiline
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SendIcon
                        sx={{
                          backgroundColor: '#0A3A67',
                          borderRadius: '8px',
                          color: '#fff',
                          cursor: 'pointer',
                          height: '20px',
                          padding: '8px'
                        }}
                        onClick={() => {
                          if (commentText) {
                            addComments(id, commentText);
                            setCommentText('');
                          }
                        }}
                      />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {/* <SendIcon
              sx={{
                backgroundColor: '#0A3A67',
                borderRadius: '8px',
                color: '#fff',
                cursor: 'pointer',
                height: '20px',
                padding: '8px',
                position: 'absolute',
                right: '1rem',
                top: '20px',
                width: '20px',
                zIndex: 1000
              }}
              onClick={() => {
                if (commentText) {
                  addComments(id, commentText);
                  setCommentText('');
                }
              }}
            /> */}
          </FormControl>
          {comments?.map((comment, index) => (
            <Stack className={classes.comments} key={index}>
              <Grid
                container
                key={index}
                backgroundColor="#f4f5f7"
                className={classes.commentContainer}>
                <Grid item>
                  <Avatar className={classes.avatar} src="" />
                </Grid>
                <Grid item sx={{ ml: '20px' }}>
                  <Box>
                    <Typography className={classes.createdBy}>{comment?.created_by}</Typography>
                    <Typography className={classes.createdAt}>
                      {comment?.created_at
                        ? moment(comment?.created_at).format('DD/MM/YYYY hh:mm A')
                        : ''}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    border: '1px solid #6785A1',
                    borderRadius: '6px',
                    marginTop: '1rem',
                    padding: '.5rem 1rem'
                  }}>
                  <Typography className={classes.commentText}>
                    {/* {comment?.comments} */}
                    <HtmlContent text={comment?.comments} />
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          ))}
        </Box>
      </Modal>
    </Box>
  );
}

SpecialNotes.propTypes = {
  addComments: PropTypes.func,
  comments: PropTypes.array,
  employeeSearchResult: PropTypes.array,
  handleEmployeeSearch: PropTypes.func,
  handleNotesClose: PropTypes.func,
  id: PropTypes.string,
  openSpecialNote: PropTypes.bool,
  setShowEmployeeSearchResult: PropTypes.func,
  showEmployeeSearchResult: PropTypes.bool
};
