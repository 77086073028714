import { makeStyles } from '@mui/styles';

const mainSidebarStyles = makeStyles({
  LogoutBtn: {
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    borderRadius: '15px',
    color: '#74013c',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: 'bold',
    justifyContent: 'center',
    marginTop: '40px',
    width: '80%'
  },

  leftSideImage: {
    marginTop: '24px'
  },
  leftSideScreen: {
    '@media (max-width: 900px)': {
      display: 'none',
      height: '100%'
    },
    background: '#fff',
    border: '1px solid rgba(10, 58, 103, 0.2)',
    borderRadius: '0px 40px 40px 0px',
    boxShadow: '6px 6px 12px #becde2, -6px -6px 12px #fff',
    maxHeight: '90vh',
    minWidth: '210px',
    overflowY: 'auto',
    padding: '2rem 0',
    position: 'sticky',
    textAlign: 'center',
    top: '0'
  },
  logouticon: {
    marginRight: '20px',
    width: '18px'
  }
});

export default mainSidebarStyles;
