import { axiosClient } from '../utils/axiosClient';

export const getPrimarySalesBillingAddressesByClientId = (builderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/${builderId}/billing_address`)
    .then((resp) => resp?.data);
};

export const getPrimarySalesBillingAddressById = (builderId, billingAddressId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/${builderId}/billing_address/${billingAddressId}`)
    .then((resp) => resp?.data);
};

export const addPrimarySalesBillingAddress = (builderId, params) => {
  return axiosClient
    .post('/v2/primary_sales/client/' + builderId + '/billing_address', params)
    .then((resp) => resp?.data);
};

export const updatePrimarySalesBillingAddress = (builderId, billingAddressId, params) => {
  return axiosClient
    .put('/v2/primary_sales/client/' + builderId, +'/billing_address/' + billingAddressId, params)
    .then((resp) => resp?.data);
};

export const deletePrimarySalesBillingAddress = (builderId, callbackId) => {
  return axiosClient
    .delete('/v2/primary_sales/client/' + builderId, +'/' + callbackId)
    .then((resp) => resp?.data);
};
