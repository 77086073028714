import { makeStyles } from '@mui/styles';

const notificationModalStyles = makeStyles({
  heading: {
    color: '#0A3A67',
    fontFamily: 'Literata !important',
    fontSize: '20px !important',
    fontWeight: '400 !important'
  },
  main: {
    '@media (max-width:768px)': {
      left: '4%',
      maxHeight: '90vh',
      overflow: 'scroll',
      top: '4%',
      width: '92%'
    },
    '@media (min-width:769px && max-width:900px)': {
      top: '20%',
      width: '50%'
    },
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    display: 'block',
    maxHeight: 800,
    padding: '0 0',
    position: 'absolute',
    right: '17%',
    top: '7%',
    width: '38%'
  },
  notificationFilters: {
    '&: hover': {
      backgroundColor: '#0e3e6a !important',
      color: '#fff !important'
    },
    backgroundColor: '#0A3A670D !important',
    border: '1px solid rgba(10, 58, 103, 0.15) !important',
    marginBottom: '4px',
    padding: '6px 4px !important',
    textTransform: 'capitalize'
  },
  today: {
    '& p': {
      color: '#0A3A67',
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      paddingBottom: '20px',
      textAlign: 'center'
    },
    justifyContent: 'center'
  }
});

export default notificationModalStyles;
