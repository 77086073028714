import { makeStyles } from '@mui/styles';

const propertyDocsStyles = makeStyles({
  accordian: {
    border: '1px solid rgba(10, 58, 103, 0.15) !important',
    borderRadius: '10px !important',
    boxShadow: 'none !important',
    margin: '.5rem 0 !important'
  },
  box: {
    '& label': {
      background: 'transparent',
      border: '1px dashed rgba(10, 58, 103, 0.2)',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      padding: '2rem 0'
    },
    '& p': {
      color: '#0A3A67',
      fontSize: '14px',
      fontWeight: '500',
      textAlign: 'center',
      textTransform: 'capitalize'
    },
    alignItems: 'center',
    borderBottom: '1px solid rgba(10, 58, 103, 0.15)',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem 0'
  },
  btnBox: {
    alignItems: 'center',
    display: 'flex',
    gap: 3,
    justifyContent: 'center',
    marginTop: '2rem'
  },
  cancelBtn: {
    border: '1px solid #0A3A67 !important',
    borderRadius: '30px !important',
    boxShadow: 'none !important',
    color: '#0A3A67 !important',
    textTransform: 'capitalize !important'
  },
  deleteButton: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '28px',
    justifyContent: 'center',
    position: 'absolute',
    right: '8px',
    top: '8px',
    width: '28px'
  },
  details: {
    alignItems: 'center',
    display: 'flex',
    padding: '2rem 1rem'
  },
  docsDetails: {
    '& h2': {
      color: 'white',
      fontSize: '20px',
      fontWeight: '500',
      padding: '1rem'
    },
    boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06)',
    marginBottom: '2rem'
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    background: '#0A3A6799',
    borderTopLeftRadius: '10px !important',
    borderTopRightRadius: '10px !important'
  },
  main: {
    border: '1px solid rgba(10, 58, 103, 0.20)',

    borderRadius: '30px',
    // boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5)',
    color: '#093A67',
    marginTop: '1.5rem',
    padding: '2rem'
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: '100vw !important'
  },
  modalContainer: {
    backgroundColor: 'rgba(229, 239, 249, 1)',
    borderRadius: '10px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    maxHeight: '80vh !important',
    overflowY: 'scroll',
    padding: '2rem',
    width: '900px !important'
  },
  modalTitle: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    marginRight: '24px',
    padding: '12px',
    width: '100%'
  },
  renameBtn: {
    '&:hover': {
      color: 'white !important'
    },
    background: '#ECEEF5 !important',
    border: '1px solid rgba(10, 58, 103, 0.15) !important',
    borderRadius: '30px !important',
    boxShadow: 'none !important',
    color: '#0A3A67 !important',
    textTransform: 'capitalize !important'
  },
  saveBtn: {
    background: '#0A3A67 !important',
    borderRadius: '30px !important',
    boxShadow: 'none !important',
    color: 'white !important',
    textTransform: 'capitalize !important'
  },
  title: {
    color: '#0A3A67',
    fontWeight: '500 !important',
    marginBottom: '.5rem !important'
  },
  userImg: {
    '& .MuiAvatar-root': {
      height: '100%',
      width: '100%'
    },
    height: '3.5rem',
    marginRight: '.5rem',
    width: '3.5rem'
  }
});

export default propertyDocsStyles;
