import { axiosClient } from '../utils/axiosClient';
const propertiesEndpoint = '/v2/structured_transaction/clients/properties';
const propertiesListingByClientIdEndpoint = '/v2/structured_transaction/client/';
const propertyDetailsEndpoint = '/v2/structured_transaction/client/property/';
const clientProperty = '/v2/structured_transaction/client/';

export const getStProperties = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    rewm,
    branchHead,
    rm,
    createdFromDate,
    createdToDate,
    customerType,
    minPriceRange,
    maxPriceRange,
    iaValuation,
    maxProfileCompletion,
    minProfileCompletion,
    propertyCategory,
    propertyType,
    sortAt,
    sortBy,
    referralType,
    propertyStatus,
    channelPartner,
    transactionType,
    locality
  } = filters;

  const params = {
    branch,
    branch_head: branchHead,
    channel_partner: channelPartner,
    from_date: createdFromDate,
    ia_valuation: iaValuation,
    limit,
    locality: locality,
    offset: limit * page,
    percentage_max: maxProfileCompletion,
    percentage_min: minProfileCompletion,
    portfolio_manager: rewm,
    price_max: maxPriceRange,
    price_min: minPriceRange,
    property_category: propertyCategory,
    property_type: propertyType,
    referral_type: referralType,
    rm_name: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: propertyStatus,
    to_date: createdToDate,
    transaction_type: transactionType,
    type: customerType
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(propertiesEndpoint + queryString).then((resp) => resp?.data);
};

export const getStPropertyDetails = (propertyId) => {
  return axiosClient.get(propertyDetailsEndpoint + propertyId).then((resp) => resp?.data);
};

export const getStPropertiesByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient
    .get(
      propertiesListingByClientIdEndpoint + clientId + `/properties?limit=10&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const addStPropertyDetails = (clientId, params) => {
  return axiosClient
    .post(clientProperty + clientId + '/property/add', params)
    .then((resp) => resp?.data);
};

export const updateStPropertyDetails = (clientId, propertyId, params) => {
  return axiosClient
    .put(clientProperty + clientId + '/property/' + propertyId + '/update', params)
    .then((resp) => resp?.data);
};

export const getStPropertyComments = (orderId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/property/${orderId}/comments`)
    .then((resp) => resp?.data);
};

export const addStPropertyComment = (propertyId, comments) => {
  return axiosClient
    .post(`/v2/structured_transaction/client/property/${propertyId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const uploadStPropertyFile = (clientId, files) => {
  return axiosClient
    .post(
      `/v2/structured_transaction/client/${clientId}/properties/fileupload?encoded=false`,
      files
    )
    .then((resp) => resp?.data);
};

export const getStPropertyFiles = (clientId, propertyId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/${clientId}/property/${propertyId}/filelist`)
    .then((resp) => resp?.data);
};

export const deleteStPropertyFile = (clientId, propertyId, fileId) => {
  return axiosClient
    .delete(
      `/v2/structured_transaction/client/${clientId}/property/${propertyId}/file/${fileId}/delete`
    )
    .then((resp) => resp?.data);
};

export const getStPropertyLiveabilityDetails = (propertyId) => {
  return axiosClient.get(`/v2/structured_transaction/keyhighlights/score?prop_id=${propertyId}`);
};

export const updateStPropertyVerifiedDetails = (propertyId, params) => {
  return axiosClient.put(
    `/v2/structured_transaction/property_verification/${propertyId}/update`,
    params
  );
};
