import { makeStyles } from '@mui/styles';

const keyHighlightsStyles = makeStyles({
  active: {
    color: 'red !important',
    transition: 'color 0.5s ease-in-out !important'
  },

  card: {
    background: '#0A3A670D',
    borderRadius: '30px',
    padding: '1.4rem'
  },
  circle: {
    color: '#0A3A674D',
    transition: 'color 0.5s ease-in-out !important'
  },

  connectivityCard: {
    alignItems: 'center',
    border: '1px solid rgba(10, 58, 103, 0.15)',
    borderRadius: '80px',
    display: 'flex',
    padding: '.5rem'
  },
  main: {
    '& h2': {
      color: '#0A3A67',
      fontSize: '20px',
      fontWeight: '600'
    },
    '& h3': {
      color: '#0A3A67',
      fontSize: '18px',
      fontWeight: '400',
      marginBottom: '0 !important',
      paddingBottom: '2rem'
    },
    '@media (max-width:768px)': {
      padding: '27px 12px'
    },
    backgroundColor: '#FFFFFF',
    borderRadius: '30px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5)',
    marginBottom: '30px',
    padding: '27px'
  },
  progress: {
    '& 	.MuiLinearProgress-bar1Determinate': {
      backgroundColor: '#0A3A67'
    },
    '& 	.MuiLinearProgress-root': {
      backgroundColor: '#F9F9F9',
      border: '1px solid rgba(10, 58, 103, 0.15)',
      borderRadius: '50px',
      height: '8px'
    },
    margin: '0 1rem',
    width: '100%'
  },
  score: {
    color: '#DB7E48',
    fontSize: '96px !important',
    fontWeight: '500 !important',
    lineHeight: '0 !important'
  },
  totalScoreValue: {
    color: '#EF691D',
    fontSize: '96px !important',
    fontWeight: '500 !important'
  },
  triangle: {
    borderBottom: 'solid 12px',
    borderLeft: 'solid 12px',
    borderRight: 'solid 12px transparent',
    borderTop: 'solid 12px transparent',
    boxSizing: 'border-box',
    display: 'flex',
    margin: '4px',
    verticalAlign: 'middle'
  }
});

export default keyHighlightsStyles;
