import { axiosClient } from '../utils/axiosClient';

export const getNotifications = (limit = 50, offset = 0) => {
  return axiosClient
    .get(`v1/employee/notifications?limit=${limit}&offset=${offset}`)
    .then((resp) => resp?.data);
};

export const markReadNotification = (id) => {
  return axiosClient.put(`v1/employee/mark/notification/${id}`).then((resp) => resp?.data);
};
