import { createSlice } from '@reduxjs/toolkit';
import {
  addPrimarySalesPropertyComment,
  addPrimarySalesPropertyDetails,
  deletePrimarySalesPropertyFile,
  getPrimarySalesProperties,
  getPrimarySalesPropertiesByClientId,
  getPrimarySalesPropertyComments,
  getPrimarySalesPropertyDetails,
  getPrimarySalesPropertyFiles,
  getPrimarySalesPropertyLiveabilityDetails,
  updatePrimarySalesPropertyDetails,
  updatePrimarySalesPropertyVerifiedDetails,
  uploadPrimarySalesPropertyFile
} from 'features/PrimarySales/Properties/primarySalesProperty';
import { toast } from 'react-toastify';

export const primarySalesPropertyFilterInitialValues = {
  branch: '',
  branchHead: '',
  createdFromDate: '',
  createdToDate: '',
  customerType: '',
  limit: 10,
  page: 0,
  premiumType: '',
  rewm: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const primarySalesPropertySlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: primarySalesPropertyFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'primarySalesPropertySlice',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const primarySalesPropertyActions = primarySalesPropertySlice.actions;

export const handleGetPrimarySalesProperties = (params) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await getPrimarySalesProperties(params);
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesPropertyDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await getPrimarySalesPropertyDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesPropertiesByClientId =
  (clientId, page, limit) => async (dispatch) => {
    try {
      dispatch(primarySalesPropertyActions.setIsLoading(true));
      const data = await getPrimarySalesPropertiesByClientId(clientId, page, limit);
      return data;
    } catch (error) {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      console.log(errorMessage);
    } finally {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
    }
  };

export const handleAddPrimarySalesPropertyDetails = (clientId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await addPrimarySalesPropertyDetails(clientId, params);
    if (data) {
      toast.success(data?.message || 'Property added successfully');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesPropertyDetails =
  (clientId, propertyId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesPropertyActions.setIsLoading(true));
      const data = await updatePrimarySalesPropertyDetails(clientId, propertyId, params);
      if (data) {
        toast.success(data?.message || 'Property updated successfully');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
    }
  };

export const handleGetPrimarySalesPropertyComments = (id) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await getPrimarySalesPropertyComments(id);
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleAddPrimarySalesPropertyComments = (id, comments) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await addPrimarySalesPropertyComment(id, comments);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handlePrimarySalesFileUpload = (clientId, files) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await uploadPrimarySalesPropertyFile(clientId, files);
    if (data?.message) {
      toast.success('File uploaded successfully!');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleGetPrimarySalesPropertyFiles = (clientId, propertyId) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await getPrimarySalesPropertyFiles(clientId, propertyId);
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleDeletePrimarySalesPropertyFile =
  (clientId, propertyId, fileId) => async (dispatch) => {
    try {
      dispatch(primarySalesPropertyActions.setIsLoading(true));
      const data = await deletePrimarySalesPropertyFile(clientId, propertyId, fileId);
      if (data?.msg) {
        toast.success('File deleted successfully!');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
    }
  };

export const handleGetPrimarySalesPropertyLiveabilityDetails = (propertyId) => async (dispatch) => {
  try {
    dispatch(primarySalesPropertyActions.setIsLoading(true));
    const data = await getPrimarySalesPropertyLiveabilityDetails(propertyId);
    return data;
  } catch (error) {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(primarySalesPropertyActions.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesPropertyVerifiedDetails =
  (propertyId, params) => async (dispatch) => {
    try {
      dispatch(primarySalesPropertyActions.setIsLoading(true));
      const data = await updatePrimarySalesPropertyVerifiedDetails(propertyId, params);
      if (data) {
        toast.success(data?.message || 'Property updated successfully');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(primarySalesPropertyActions.setIsLoading(false));
    }
  };

export default primarySalesPropertySlice;
