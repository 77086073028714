import { createSlice } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import {
  addPrimarySalesCallbackRemarks,
  addPrimarySalesProspectiveBuyer,
  createPrimarySalesProspectCallBackRequest,
  updatePrimarySalesProspect,
  updatePrimarySalesProspectCallBackRequest
} from './primarySalesMagik.api';

const primarySalesMagikSlice = createSlice({
  initialState: {
    isLoading: false
  },
  name: 'primarySalesMagik',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const primarySalesMagikActions = primarySalesMagikSlice.actions;

export const handleCreatePrimarySalesProspectCallBackRequest =
  (id, dateTime) => async (dispatch) => {
    try {
      dispatch(primarySalesMagikActions.setIsLoading(true));
      const data = await createPrimarySalesProspectCallBackRequest(id, dateTime);
      if (data?.message) {
        toast.success(data?.message || 'Successfully created prospect call back request');
      }
      return data;
    } catch (error) {
      dispatch(primarySalesMagikActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage || 'Something went wrong');
    } finally {
      dispatch(primarySalesMagikActions.setIsLoading(false));
    }
  };

export const handleUpdatePrimarySalesProspectCallBackRequest = (id, params) => async (dispatch) => {
  try {
    dispatch(primarySalesMagikActions.setIsLoading(true));
    const data = await updatePrimarySalesProspectCallBackRequest(id, params);
    if (data) {
      toast.success(data?.message || 'Successfully updated prospect call back request');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesMagikActions.setIsLoading(false));
  }
};

export const handleAddPrimarySalesProspectiveBuyer = (params) => async (dispatch) => {
  try {
    dispatch(primarySalesMagikActions.setIsLoading(true));
    const data = await addPrimarySalesProspectiveBuyer(params);
    if (data) {
      toast.success(data?.message || 'Successfully added prospective buyer');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesMagikActions.setIsLoading(false));
  }
};

export const handleUpdatePrimarySalesProspect = (id, param) => async (dispatch) => {
  try {
    dispatch(primarySalesMagikActions.setIsLoading(true));
    const data = await updatePrimarySalesProspect(id, param);
    if (data?.message) {
      toast.success(data?.message || 'Successfully updated prospect details');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesMagikActions.setIsLoading(false));
  }
};

export const handleAddPrimarySalesCallbackRemarks = (callbackId, params) => async (dispatch) => {
  try {
    dispatch(primarySalesMagikActions.setIsLoading(true));
    const data = await addPrimarySalesCallbackRemarks(callbackId, params);
    if (data) {
      toast.success(data?.message || 'Successfully added callback remark');
    }
    return data;
  } catch (error) {
    dispatch(primarySalesMagikActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(primarySalesMagikActions.setIsLoading(false));
  }
};

export default primarySalesMagikSlice;
