import { axiosClient } from '../utils/axiosClient';

export const getLeads = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    createdFromDate,
    createdToDate,
    updatedFromDate,
    updatedToDate,
    rewm,
    leadSource,
    branch,
    sortAt,
    sortBy
  } = filters;

  const params = {
    branch,
    from_date: createdFromDate,
    lead_prm: rewm,
    limit,
    offset: limit * page,
    sort_at: sortAt,
    sort_by: sortBy,
    source: leadSource,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('v1/leads' + queryString).then((resp) => resp?.data);
};

export const getLeadDetails = (id) => {
  return axiosClient.get(`v1/lead/${id}`).then((resp) => resp?.data);
};

export const addLead = (params) => {
  return axiosClient.post('v1/lead/add', params).then((resp) => resp?.data);
};

export const updateLead = (id, params) => {
  return axiosClient.put(`v1/lead/${id}`, params).then((resp) => resp?.data);
};

export const deleteLead = (id) => {
  return axiosClient.delete(`v1/lead/${id}`).then((resp) => resp?.data);
};

export const assignLeadPm = (id, params) => {
  return axiosClient.put(`v1/lead/${id}/assign_pm`, params).then((resp) => resp?.data);
};

export const assignLeadRm = (id, params) => {
  return axiosClient.put(`v1/lead/${id}/assign_rm`, params).then((resp) => resp?.data);
};

export const addLeadComment = (id, params) => {
  return axiosClient.post(`v1/lead/${id}/comments`, params).then((resp) => resp?.data);
};

export const getLeadComments = (id) => {
  return axiosClient.get(`v1/lead/${id}/comments`).then((resp) => resp?.data);
};

export const addLeadCallback = (id, params) => {
  return axiosClient.post(`v1/lead/${id}/call_back`, params).then((resp) => resp?.data);
};
