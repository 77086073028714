import * as React from 'react';

import { FormLabel, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import countryCodeDropdownStyles from './CountryCodeDropdown.styles';

export default function CountryCodeDropdown({
  countryCode = '',
  disabled = false,
  handleBlur = () => {},
  setFieldValue = () => {},
  customLabelStyles = {}
}) {
  const classes = countryCodeDropdownStyles();

  return (
    <>
      <FormLabel
        id="country-code"
        sx={{
          background: '#fff',
          marginBottom: '-1.2rem !important',
          marginLeft: '0.8rem !important',
          padding: '0 10px',
          position: 'relative !important',
          width: '100px',
          zIndex: 5,
          ...customLabelStyles
        }}>
        <span style={{ background: 'white' }}>Country Code</span>
        <span style={{ color: '#d11414' }}>*</span>
      </FormLabel>
      <Autocomplete
        sx={{
          '& .MuiAutocomplete-inputRoot': {
            borderRadius: '8px !important'
          }
        }}
        value={countryCode}
        isOptionEqualToValue={(option, value) => option.phone == value}
        id="country-code"
        options={countries}
        onPaste={(e) => {
          e.preventDefault();
          return false;
        }}
        onCopy={(e) => {
          e.preventDefault();
          return false;
        }}
        disabled={disabled}
        onBlur={handleBlur('countryCode')}
        autoHighlight
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{
              '& > img': { flexShrink: 0, mr: 2, py: '8px' }
            }}
            {...props}
            onClick={() => setFieldValue('countryCode', option?.phone)}>
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt="flag"
            />
            <Typography className={classes.code}>
              {option.label} ({option.code}) +{option.phone}
            </Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password'
            }}
          />
        )}
      />
    </>
  );
}

CountryCodeDropdown.propTypes = {
  countryCode: PropTypes.string,
  customLabelStyles: PropTypes.object,
  disabled: PropTypes.bool,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func
};

const countries = [
  { code: 'IN', label: 'India', phone: '91', suggested: true },
  {
    code: 'US',
    label: 'United States',
    phone: '1'
  },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971'
  },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true
  },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true
  },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true
  },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GR', label: 'Greece', phone: '30' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  {
    code: 'IR',
    label: 'Iran',
    phone: '98'
  },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true
  },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of",
    phone: '850'
  },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  {
    code: 'TW',
    label: 'Taiwan, Republic of China',
    phone: '886'
  },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' }
];
