import { makeStyles } from '@mui/styles';

const dashboardRedboxCardStyles = makeStyles({
  bargraph: {
    '& img': {
      width: '100%'
    },
    '@media (max-width:768px)': {
      width: '20%'
    },
    width: '40%'
  },
  closed: {
    '& p': {
      color: '#02632C',
      fontWeight: '500',
      marginRight: '4px'
    },
    '& span': {
      color: '#02632C',
      fontSize: '12px'
    },
    alignItems: 'baseline',
    display: 'flex',
    margin: '.7rem 0'
  },
  flex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  hoverText: {
    '@media (max-width: 768px)': {
      borderBottomLeftRadius: '40px',
      borderTopRightRadius: 0,
      left: 0,
      top: '40%',
      width: '12rem'
    },
    background: '#fff',
    borderBottomLeftRadius: 0,
    borderRadius: '40px',
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.16)',
    color: '#0A3A67',
    display: 'none',
    fontSize: '14px',
    left: '80%',
    padding: '1rem',
    position: 'absolute',
    top: '-20%',
    width: '15rem',
    zIndex: '9'
  },
  main: {
    '@media (max-width:768px)': {
      width: '100%'
    },
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.16)',
    fontFamily: 'Lato',
    margin: '0 0 1rem 1rem',
    padding: '1rem',
    position: 'relative',

    width: '25%'
  },
  open: {
    '& h2': { color: '#0A3A67', fontWeight: 600 },
    alignItems: 'baseline',
    display: 'flex',
    marginBottom: '1rem'
  },
  openBox: {
    '& div': {
      '& img': {
        paddingBottom: '4px',
        paddingLeft: '4px',
        width: '100%'
      },
      width: '16px'
    },
    '& span': {
      color: '#E70202',
      fontSize: '12px',
      fontWeight: 400
    },
    alignItems: 'center',
    backgroundColor: '#E3E9EF',
    borderRadius: '20px',
    display: 'flex',
    padding: '1px 14px'
  },
  redCircle: {
    '& p': {
      color: '#E70202',
      fontSize: '14px',
      fontWeight: 600
    },
    '&:hover div': {
      display: 'block'
    },
    alignItems: 'center',
    background: '#E702021A',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    padding: '8px',
    width: '40px'
  },
  title: {
    color: '#0A3A67',
    fontWeight: '500 !important'
  },
  total: {
    '& span': {
      color: '#7A9AA6',
      fontSize: '14px',
      fontWeight: 400,
      marginRight: '4px'
    },
    color: '#7A9AA6',
    fontSize: '20px',
    fontWeight: 500
  }
});

export default dashboardRedboxCardStyles;
