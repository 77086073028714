import { axiosClient } from '../utils/axiosClient';

const propertiesEndpoint = 'v1/clients/properties';
const propertiesListingByClientIdEndpoint = 'v1/clients/';
const propertyDetailsEndpoint = 'v1/client/properties/';
const clientProperty = 'v1/client/';

export const getProperties = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    rewm,
    branchHead,
    rm,
    createdFromDate,
    createdToDate,
    customerType,
    minPriceRange,
    maxPriceRange,
    iaValuation,
    maxProfileCompletion,
    minProfileCompletion,
    propertyCategory,
    propertyType,
    sortAt,
    sortBy,
    referralType,
    propertyStatus,
    channelPartner,
    transactionType,
    locality
  } = filters;

  const params = {
    branch,
    branch_head: branchHead,
    channel_partner: channelPartner,
    from_date: createdFromDate,
    ia_valuation: iaValuation,
    limit,
    locality: locality,
    offset: limit * page,
    percentage_max: maxProfileCompletion,
    percentage_min: minProfileCompletion,
    portfolio_manager: rewm,
    price_max: maxPriceRange,
    price_min: minPriceRange,
    property_category: propertyCategory,
    property_type: propertyType,
    referral_type: referralType,
    rm_name: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: propertyStatus,
    to_date: createdToDate,
    transaction_type: transactionType,
    type: customerType
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(propertiesEndpoint + queryString).then((resp) => resp?.data);
};

export const getPropertyDetails = (propertyId) => {
  return axiosClient.get(propertyDetailsEndpoint + propertyId).then((resp) => resp?.data);
};

export const getPropertiesByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient
    .get(
      propertiesListingByClientIdEndpoint + clientId + `/properties?limit=10&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const addPropertyDetails = (clientId, params) => {
  return axiosClient
    .post(clientProperty + clientId + '/property/add', params)
    .then((resp) => resp?.data);
};

export const updatePropertyDetails = (clientId, propertyId, params) => {
  return axiosClient
    .put(clientProperty + clientId + '/property/' + propertyId + '/update', params)
    .then((resp) => resp?.data);
};

export const getPropertyComments = (orderId) => {
  return axiosClient.get(`v1/client/property/${orderId}/comments`).then((resp) => resp?.data);
};

export const addPropertyComment = (propertyId, comments) => {
  return axiosClient
    .post(`v1/client/property/${propertyId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const uploadFile = (clientId, files) => {
  return axiosClient
    .post(`v1/client/${clientId}/properties/fileupload?encoded=false`, files)
    .then((resp) => resp?.data);
};

export const getFiles = (clientId, propertyId) => {
  return axiosClient
    .get(`v1/client/${clientId}/property/${propertyId}/filelist`)
    .then((resp) => resp?.data);
};

export const deleteFile = (clientId, propertyId, fileId) => {
  return axiosClient
    .delete(`v1/client/${clientId}/property/${propertyId}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};

export const getLiveabilityDetails = (propertyId) => {
  return axiosClient.get(`v1/keyhighlights/score?prop_id=${propertyId}`);
};

export const getPropertyVerifiedDetails = (propertyId) => {
  return axiosClient.get(`v2/property_verification/${propertyId}`);
};

export const updatePropertyVerifiedDetails = (propertyId, params) => {
  return axiosClient.put(`v2/property_verification/${propertyId}/update`, params);
};

export const setCover = (propertyId, fileId) => {
  return axiosClient
    .post(`v1/client/property/${propertyId}/file/${fileId}/set_cover`)
    .then((resp) => resp?.data);
};

export const renewDoc = (clientId, params) => {
  return axiosClient.put(`v1/users/${clientId}/properties/filesrenewal`, params);
};

export const renameDoc = (clientId, params) => {
  return axiosClient.put(`v1/users/${clientId}/properties/filesrename`, params);
};

export const reminderDoc = (clientId, propertyId) => {
  return axiosClient.get(`v1/users/${clientId}/properties/${propertyId}/filesremainder`);
};

export const deleteDoc = (clientId) => {
  return axiosClient.delete(`v1/users/${clientId}/properties/filesdelete`);
};

export const recommendProperty = (orderId) => {
  return axiosClient.get(`v2/clients/reco_props/${orderId}?limit=1000&offset=0`);
};

export const recommendBuyers = (propertyId) => {
  return axiosClient.get(`v2/clients/reco_buyers/${propertyId}?limit=1000&offset=0`);
};
