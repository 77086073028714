import { makeStyles } from '@mui/styles';

const timelineBarStyles = makeStyles({
  connector: {
    borderTop: '1px dashed #0A3A67',
    height: '0px',
    margin: '0 12px !important',
    maxWidth: '320px'
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row !important',
    justifyContent: 'center',
    margin: '0 3rem'
  },
  step: {
    '@media (max-width:1024px)': {
      fontSize: '12px !important',
      marginLeft: '8px !important',
      textAlign: 'left'
    },
    color: '#0A3A67',
    fontFamily: 'Lato',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    marginLeft: '1rem !important',
    textAlign: 'center'
  },
  timelineDot: {
    '@media (max-width:768px)': {
      fontSize: '12px !important',
      height: '.7rem !important',
      width: '1rem !important'
    },
    alignItems: 'center',
    border: '1px solid !important',
    display: 'flex',
    fontSize: '18px !important',
    height: '18px !important',
    justifyContent: 'center',
    padding: '.5rem !important',
    width: '18px !important'
  }
});

export default timelineBarStyles;
