import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addLoanLead,
  addLoanLeadCallback,
  addLoanLeadComment,
  assignLoanLeadPm,
  deleteLoanLead,
  getLoanLeadComments,
  getLoanLeadDetails,
  getLoanLeads,
  loanLeadTypeDropdownValues,
  updateLoanLead,
  updateLoanLeadStatus,
  updateLoanLeadTemperature
} from './loanLeads.api';

export const loanLeadFilterInitialValues = {
  branch: '',
  createdFromDate: '',
  createdToDate: '',
  leadSource: '',
  rewm: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  temperature: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const loanLeadRecommendation = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: loanLeadFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'loanLeads',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const loanLeadsActions = loanLeadRecommendation.actions;

export const handleGetLoanLeads = (filters) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await getLoanLeads(filters);
    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleGetLoanLeadDetails = (id) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await getLoanLeadDetails(id);
    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleAddLoanLead = (params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await addLoanLead(params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleUpdateLoanLead = (id, params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await updateLoanLead(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleDeleteLoanLead = (id) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await deleteLoanLead(id);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleAssignLoanLeadPM = (id, params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await assignLoanLeadPm(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleAddLoanLeadComment = (id, params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await addLoanLeadComment(id, params);

    if (response?.message) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleGetLoanLeadComments = (id) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await getLoanLeadComments(id);
    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleAddLoanLeadCallback = (id, params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await addLoanLeadCallback(id, params);
    if (response) toast.success(response?.message || 'Callback added successfully');
    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleGetLoanLeadTypeDropdownValues = () => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const response = await loanLeadTypeDropdownValues();
    return response;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }

    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleUpdateLoanLeadTemperature = (loanId, params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const data = await updateLoanLeadTemperature(loanId, params);
    if (data) toast.success(data?.message || 'Loan status updated successfully');
    return data;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export const handleUpdateLoanLeadStatus = (loanId, params) => async (dispatch) => {
  try {
    dispatch(loanLeadsActions.setIsLoading(true));
    const data = await updateLoanLeadStatus(loanId, params);
    if (data) toast.success(data?.message || 'Loan status updated successfully');
    return data;
  } catch (error) {
    dispatch(loanLeadsActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loanLeadsActions.setIsLoading(false));
  }
};

export default loanLeadRecommendation;
