import { createSlice } from '@reduxjs/toolkit';
import {
  sendOtpForClientEmailVerification,
  sendOtpForClientMobileVerification,
  sendOtpForLoginUsingEmail,
  sendOtpForLoginUsingMobile,
  verifyOtpForClientEmailVerification,
  verifyOtpForClientMobileVerification,
  verifyOtpForLoginUsingEmail,
  verifyOtpForLoginUsingMobile
} from 'api/auth';
import { getEmployeeRoles } from 'api/main';
import { dashboardActions } from 'features/Dashboard/dashboard.slice';
import { toast } from 'react-toastify';
import { clientActions } from './clientSlice';
import { propertyActions } from './propertySlice';
import { stClientActions } from './stClientSlice';

const authSlice = createSlice({
  initialState: {
    error: { data: null, isError: false },
    isLoading: false,
    isLoggedIn: false,
    isLoggedOut: false,
    referralCode: '',
    referralURL: '',
    role: '',
    type: 'loading',
    userAvatar: '',
    userId: '',
    userName: ''
  },
  name: 'auth',
  reducers: {
    login(state) {
      state.type = 'loaded';
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = false;
    },
    logout(state) {
      state.type = 'empty';
      state.isLoggedIn = false;
      state.isLoggedOut = true;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setReferralCode(state, action) {
      state.referralCode = action.payload;
    },
    setReferralURL(state, action) {
      state.referralURL = action.payload;
    },
    setRole(state, action) {
      state.role = action.payload;
    },
    setUserAvatar(state, action) {
      state.userAvatar = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    }
  }
});

export const authActions = authSlice.actions;

export const getLoginOtpForMobile =
  ({ mobile }) =>
  async (dispatch) => {
    try {
      dispatch(authActions.setIsLoading(true));
      const data = await sendOtpForLoginUsingMobile(mobile);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(authActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(authActions.setIsLoading(false));
    }
  };

export const getLoginOtpForEmail =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch(authActions.setIsLoading(true));
      const data = await sendOtpForLoginUsingEmail(email);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(authActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(authActions.setIsLoading(false));
    }
  };

export const handleVerifyOtpForLoginUsingMobile = (otp, mobile) => async (dispatch) => {
  try {
    const data = await verifyOtpForLoginUsingMobile(otp, mobile);

    if (data?.access_token) {
      localStorage.setItem('accessToken', data?.access_token);
      localStorage.setItem('refreshToken', data?.refresh_token);

      dispatch(authActions.login());

      if (data?.role_id) {
        const roles = await getEmployeeRoles();

        let findRole = roles?.find((role) => role?.role_id === data?.role_id);
        dispatch(authActions.setRole(findRole?.role));
      }

      dispatch(authActions.setUserName(data?.full_name));
      dispatch(authActions.setUserAvatar(data?.profile_image));
      dispatch(authActions.setUserId(data?.emp_code));
      dispatch(authActions.setReferralCode(data?.referral_id));
      dispatch(authActions.setReferralURL(data?.referral_link));

      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    }
  } catch (error) {
    dispatch(authActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  }
};

export const handleVerifyOtpForLoginUsingEmail = (otp, email) => async (dispatch) => {
  try {
    const data = await verifyOtpForLoginUsingEmail(otp, email);

    if (data?.access_token) {
      localStorage.setItem('accessToken', data?.access_token);
      localStorage.setItem('refreshToken', data?.refresh_token);

      dispatch(authActions.login());

      if (data?.role_id) {
        const roles = await getEmployeeRoles();

        let findRole = roles?.find((role) => role?.role_id === data?.role_id);
        dispatch(authActions.setRole(findRole?.role));
      }

      dispatch(authActions.setUserName(data?.full_name));
      dispatch(authActions.setUserAvatar(data?.profile_image));
      dispatch(authActions.setUserId(data?.emp_code));
      dispatch(authActions.setReferralCode(data?.referral_id));
      dispatch(authActions.setReferralURL(data?.referral_link));

      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    }
  } catch (error) {
    dispatch(authActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  }
};

export const userSignout = () => async (dispatch) => {
  localStorage.clear();
  dispatch(authActions.logout());
  dispatch(dashboardActions.setFilters({}));
  dispatch(clientActions.setFilters({}));
  dispatch(stClientActions.setFilters({}));
  dispatch(propertyActions.setFilters({}));
  toast.success('Logout successfully');
};

export const getOtpForClientMobileVerification =
  ({ clientId, countryCode, mobile }) =>
  async (dispatch) => {
    try {
      dispatch(authActions.setIsLoading(true));
      const data = await sendOtpForClientMobileVerification(clientId, countryCode, mobile);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(authActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(authActions.setIsLoading(false));
    }
  };

export const getOtpForClientEmailVerification =
  ({ clientId, email }) =>
  async (dispatch) => {
    try {
      dispatch(authActions.setIsLoading(true));
      const data = await sendOtpForClientEmailVerification(clientId, email);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(authActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(authActions.setIsLoading(false));
    }
  };

export const handleVerifyOtpForClientMobileVerification =
  ({ clientId, countryCode, mobile, otp }) =>
  async (dispatch) => {
    try {
      const data = await verifyOtpForClientMobileVerification(clientId, countryCode, mobile, otp);

      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(authActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    }
  };

export const handleVerifyOtpForClientEmailVerification =
  ({ clientId, email, otp }) =>
  async (dispatch) => {
    try {
      const data = await verifyOtpForClientEmailVerification(clientId, email, otp);

      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(authActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    }
  };

export default authSlice;
