import { axiosClient } from '../utils/axiosClient';

export const getStOrdersListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    rm,
    createdFromDate,
    createdToDate,
    orderType,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate,
    assignTo
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient
    .get('/v1/structured_transaction/clients/normal_orders' + queryString)
    .then((resp) => resp?.data);
};

export const getStOrderDetailsById = (orderId) => {
  return axiosClient
    .get('/v1/structured_transaction/clients/order/' + orderId)
    .then((resp) => resp?.data);
};

export const addStOrder = (clientId, params) => {
  return axiosClient
    .post('/v1/structured_transaction/client/' + clientId + '/orders', params)
    .then((resp) => resp?.data);
};

export const getStOrderComments = (orderId) => {
  return axiosClient
    .get(`/v1/structured_transaction/client/normal/order/${orderId}/comments`)
    .then((resp) => resp?.data);
};

export const addStOrderComment = (orderId, comments) => {
  return axiosClient
    .post(`/v1/structured_transaction/client/normal/order/${orderId}/comments`, {
      comments: comments
    })
    .then((resp) => resp?.data);
};

export const addStOrderExtComment = (orderId, comments) => {
  return axiosClient
    .post(`v1/structured_transaction/client/normal/order/${orderId}/external/comments`, { comments })
    .then((resp) => resp?.data);
};


export const getStOrderExtComments = (orderId) => {
  return axiosClient
    .get(`/v2/structured_transaction/client/normal/order/${orderId}/external/comments`)
    .then((resp) => resp?.data);
};

export const assignStOrderRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`/v1/structured_transaction/client/${clientId}/normal_orders/${orderId}/assign_rm`, params)
    .then((resp) => resp?.data);
};

export const assignStOrderFOS = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `/v1/structured_transaction/client/${clientId}/normal_orders/${orderId}/sub_assign_fos`,
      params
    )
    .then((resp) => resp?.data);
};

export const subAssignStOrderRM = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `/v1/structured_transaction/client/${clientId}/normal_orders/${orderId}/sub_assign_rm`,
      params
    )
    .then((resp) => resp?.data);
};

export const updateOrderStatus = (clientId, orderId, statusId, subStatusId) => {
  return axiosClient
    .patch(`v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/status/update`, {
      order_status: statusId,
      order_sub_status: subStatusId
    })
    .then((resp) => resp?.data);
};

export const addSTAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/assign_rm_status`,
      params
    )
    .then((resp) => resp?.data);
};

export const updateSTAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/assign_rm_status/update`,
      params
    )
    .then((resp) => resp?.data);
};

export const addSTAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/assign_prm_status`,
      params
    )
    .then((resp) => resp?.data);
};

export const updateSTAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/assign_prm_status/update`,
      params
    )
    .then((resp) => resp?.data);
};

export const orderAssignConfirmStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `v2/structured_transaction/client/${clientId}/normal_orders/${orderId}/assign_confirm_status`,
      params
    )
    .then((resp) => resp?.data);
};

export const uploadStOrderCommentFile = (commentId, files) => {
  return axiosClient
    .post(`v2/structured_transaction/client/normal/order/external/comments/${commentId}/fileupload?encoded=False`, files)
    .then((resp) => resp?.data);
};