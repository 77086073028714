import { makeStyles } from '@mui/styles';

const tabContainerStyles = makeStyles({
  '& .Mui-selected': { backgroundColor: 'red' },
  main: {
    marginBottom: '20px',
    width: '100%'
  },
  tab: {
    color: '#0F3E6A',
    width: '20%'
  },
  tabs: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '6px 6px 36px rgba(0, 0, 0, 0.06)'
  }
});

export default tabContainerStyles;
