import { createSlice } from '@reduxjs/toolkit';
import {
  createEKYCRequest,
  createEKYCRequestForAadhar,
  geteKYCDetails,
  updateKycStatusForAadhar,
  updateKycStatusForPanDLandVID
} from 'api/ekyc';
import { toast } from 'react-toastify';

const eKYCSlice = createSlice({
  initialState: {
    isLoading: false
  },
  name: 'eKYC',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const eKYCactions = eKYCSlice.actions;

export const handleGetEKYCById = (clientId) => async (dispatch) => {
  try {
    dispatch(eKYCactions.setIsLoading(true));
    const data = await geteKYCDetails(clientId);
    return data;
  } catch (error) {
    dispatch(eKYCactions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eKYCactions.setIsLoading(false));
  }
};

export const handleCreateEKYCRequestForAadhar = (params) => async (dispatch) => {
  try {
    dispatch(eKYCactions.setIsLoading(true));
    const data = await createEKYCRequestForAadhar(params);
    return data;
  } catch (error) {
    dispatch(eKYCactions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eKYCactions.setIsLoading(false));
  }
};

export const handleCreateEKYCRequest = (params) => async (dispatch) => {
  try {
    dispatch(eKYCactions.setIsLoading(true));
    const data = await createEKYCRequest(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(eKYCactions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eKYCactions.setIsLoading(false));
  }
};

export const handleUpdateEkycStatusForAadhar = (params) => async (dispatch) => {
  try {
    dispatch(eKYCactions.setIsLoading(true));
    const data = await updateKycStatusForAadhar(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(eKYCactions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eKYCactions.setIsLoading(false));
  }
};

export const handleUpdateEkycStatusForPanDlAndVID = (params) => async (dispatch) => {
  try {
    dispatch(eKYCactions.setIsLoading(true));
    const data = await updateKycStatusForPanDLandVID(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(eKYCactions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(eKYCactions.setIsLoading(false));
  }
};

export default eKYCSlice;
