import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { numDifferentiation } from 'utils';
import amountInWordsStyles from './AmountInWords.styles';

export default function AmountInWords({ amount }) {
  const classes = amountInWordsStyles();
  return (
    amount > 0 && <Typography className={classes.amount}>₹ {numDifferentiation(amount)}</Typography>
  );
}

AmountInWords.propTypes = {
  amount: PropTypes.string
};
