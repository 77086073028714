import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function Callbacks({
  clientId,
  callbacks,
  hideAddCallbackButton = false,
  hideStatus = false
}) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        background: '#fff',
        border: '1px solid rgba(10, 58, 103, 0.2)',
        borderRadius: '30px',
        margin: '1rem 0',
        padding: '1rem'
      }}>
      <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ color: '#0A3A67', fontWeight: '400', margin: '1rem 0' }}>
          Callbacks
        </Typography>

        {!hideAddCallbackButton && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => navigate(`/add-callback/${clientId}`)}
            sx={{ borderColor: '#0A3A67', color: '#0A3A67', textTransform: 'capitalize' }}>
            Add Callback
          </Button>
        )}
      </Box>

      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0 1rem',
            minWidth: 650,
            padding: { lg: '0 1rem', xs: 0 }
          }}>
          <TableHead sx={{ background: 'aliceblue' }}>
            <TableRow sx={{ borderRadius: '10px', outline: '1px solid rgba(15, 62, 106, 0.15)' }}>
              <TableCell
                sx={{
                  border: 'none',
                  borderBottomLeftRadius: '10px',
                  borderTopLeftRadius: '10px',
                  color: '#0A3A67CC'
                }}>
                Callback Id
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                  color: '#0A3A67CC'
                }}>
                Created At
              </TableCell>
              <TableCell
                sx={{
                  border: 'none',
                  color: '#0A3A67CC'
                }}>
                Requested Date & Time
              </TableCell>
              {!hideStatus && (
                <TableCell
                  sx={{
                    border: 'none',
                    color: '#0A3A67CC'
                  }}>
                  Status
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {callbacks?.length ? (
              callbacks?.map((callback) => (
                <TableRow
                  key={callback?.call_back_id}
                  onClick={() => {
                    hideAddCallbackButton
                      ? null
                      : navigate(
                          `/callback-details/${callback?.app_user_id}/${callback?.call_back_id}`
                        );
                  }}
                  sx={{
                    ':hover': {
                      background: 'rgba(15, 62, 106, 0.05)'
                    },
                    borderRadius: '10px',
                    cursor: 'pointer',
                    marginBottom: '1rem',
                    outline: '1px solid rgba(15, 62, 106, 0.15)'
                  }}>
                  <TableCell
                    sx={{
                      border: 'none',
                      color: '#0A3A67',
                      fontWeight: 500,
                      textTransform: 'capitalize'
                    }}>
                    {callback?.call_back_id}
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      color: '#0A3A67',
                      fontWeight: 500,
                      textTransform: 'capitalize'
                    }}>
                    {moment(callback?.created_at).format('DD-MM-YY hh:mm A')}
                  </TableCell>
                  <TableCell sx={{ border: 'none', color: '#0A3A67', fontWeight: 500 }}>
                    {moment(callback?.date_time_requested).format('DD-MM-YY hh:mm A') || '-'}
                  </TableCell>
                  {!hideStatus && (
                    <TableCell
                      sx={{
                        border: 'none',
                        color: '#0A3A67',
                        fontWeight: 500,
                        textTransform: 'capitalize'
                      }}>
                      {callback?.status || '-'}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  borderRadius: '10px',
                  outline: '1px solid rgba(15, 62, 106, 0.15)'
                }}>
                <TableCell
                  colSpan={hideStatus ? 3 : 4}
                  sx={{
                    border: 'none',
                    color: 'rgba(10, 58, 103, 0.6)',
                    fontSize: '14px !important',
                    fontWeight: 500,
                    padding: '12px',
                    textAlign: 'center'
                  }}>
                  No new callback added to the list. Please add a new callback.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

Callbacks.propTypes = {
  callbacks: PropTypes.array,
  clientId: PropTypes.string,
  hideAddCallbackButton: PropTypes.bool,
  hideStatus: PropTypes.bool
};
