import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { handleGetNotifications, handleMarkReadNotification } from 'store/notificationSlice';
import notificationCardStyles from './NotificationCard.styles';

export default function NotificationCard({
  notification,
  setIsNotificationModalVisible = () => {},
  handleGetUpdateNotifiList = () => {}
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = notificationCardStyles();

  const markNotificationRead = async (notificationId) => {
    await dispatch(handleMarkReadNotification(notificationId));
    await dispatch(handleGetNotifications());
    handleGetUpdateNotifiList();
  };

  return (
    <Box
      className={classes.main}
      sx={notification?.is_app_user ? { background: '#0A3A670D' } : { background: '#F2F2F2' }}
      onClick={() => markNotificationRead(notification?.notification_id)}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Avatar sx={{ marginRight: '8px' }} />

        {/* client notification */}
        {notification?.customer_id && 'customer_type' in notification && (
          <Typography
            onClick={() => {
              navigate(`/client-details/${notification?.app_user_id}`),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            A new client
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` "${notification?.full_name}" `}
            </Typography>
            is assigned to you.
          </Typography>
        )}

        {/* property comment */}
        {notification?.property_id && notification?.comments && (
          <Typography
            onClick={() => {
              navigate(
                `/property-details/${notification?.app_user_id}/${notification?.property_id}`
              ),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            A new comment is added by
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` ${notification?.created_by} `}
            </Typography>
            on your client&apos;s property
            <Typography variant="span">{` : "${notification?.comments?.slice(
              0,
              20
            )}..."`}</Typography>
          </Typography>
        )}

        {/* property - internal comments */}
        {notification?.app_user_id &&
          notification?.comments &&
          !('property_id' in notification) && (
            <Typography
              onClick={() => {
                navigate(`/client-details/${notification?.app_user_id}`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A special note is added by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by} `}
              </Typography>
              against your client
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/* trans order comment */}
        {notification?.external_transaction_order_comments_id &&
          notification?.order_id &&
          notification?.order_type === 'Transaction' &&
          'total_sub_comments_count' in notification && (
            <Typography
              onClick={() => {
                navigate(`/transactional/${notification?.order_id}/client-comments`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A new comment has been added against an order by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by}`}
              </Typography>
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/* trans order comment reply */}
        {notification?.external_transaction_order_sub_comments_id &&
          notification?.order_type === 'Transaction' && (
            <Typography
              onClick={() => {
                navigate(`/transactional/${notification?.order_id}/client-comments`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A new reply is added to an existing comment by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by}`}
              </Typography>
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/* non-transactional order comment */}
        {notification?.external_normal_order_comments_id &&
          notification?.order_id &&
          notification?.order_type === 'Normal' &&
          'total_sub_comments_count' in notification && (
            <Typography
              onClick={() => {
                navigate(`/non-transactional/${notification?.order_id}/client-comments`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A new comment has been added against an order by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by}`}
              </Typography>
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/*  non-transactional order sub comment */}
        {notification?.external_normal_order_sub_comments_id &&
          notification?.order_type === 'Normal' && (
            <Typography
              onClick={() => {
                navigate(`/non-transactional/${notification?.order_id}/client-comments`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A new reply is added to an existing comment by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by}`}
              </Typography>
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/* special notes - order */}
        {notification?.order_id && notification?.comments && !('order_type' in notification) && (
          <Typography sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            A new internal comment is added by
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` ${notification?.created_by} `}
            </Typography>
            on your assigned order
            <Typography variant="span">{` : "${notification?.comments?.slice(
              0,
              20
            )}..."`}</Typography>
          </Typography>
        )}

        {/* special notes -  non-transactional order */}
        {notification?.order_id &&
          notification?.order_type === 'Normal' &&
          notification?.comment_type === 'Internal' && (
            <Typography
              onClick={() => {
                navigate(`/order/non-transactional-details/${notification?.order_id}`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A new internal comment is added by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by}`}
              </Typography>
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/* special notes - transactional order */}
        {notification?.order_id &&
          notification?.order_type === 'Transaction' &&
          notification?.comment_type === 'Internal' && (
            <Typography
              onClick={() => {
                navigate(`/order/transactional-details/${notification?.order_id}`),
                  setIsNotificationModalVisible(false);
              }}
              sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
              A new internal comment is added by
              <Typography variant="span" sx={{ fontWeight: '500' }}>
                {` ${notification?.created_by}`}
              </Typography>
              <Typography variant="span">{` : "${notification?.comments?.slice(
                0,
                20
              )}..."`}</Typography>
            </Typography>
          )}

        {/* trans order assign */}
        {notification?.transaction_order_no && notification?.transaction_id && (
          <Typography
            onClick={() => {
              navigate(`/order/transactional-details/${notification?.transaction_order_id}`),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            Your client
            <Typography
              variant="span"
              sx={{ fontWeight: '500' }}>{` ${notification?.full_name}'s `}</Typography>
            order is assigned to
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` ${notification?.rm_name}`}
            </Typography>
          </Typography>
        )}

        {/*  non-transactional order assign */}
        {notification?.order_uuid && notification?.normal_order_id && (
          <Typography
            onClick={() => {
              navigate(`/order/non-transactional-details/${notification?.order_uuid}`),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            Your client
            <Typography
              variant="span"
              sx={{ fontWeight: '500' }}>{` ${notification?.full_name}'s `}</Typography>
            order
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` "${notification?.normal_order_name}" `}
            </Typography>
            is assigned to
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` ${notification?.rm_name}`}
            </Typography>
          </Typography>
        )}

        {/* lead  */}
        {notification?.lead_id && (
          <Typography
            onClick={() => {
              navigate(`/lead-details/${notification?.lead_id}`),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            A new lead
            <Typography
              variant="span"
              sx={{ fontWeight: '500' }}>{` "${notification?.full_name}" `}</Typography>
            is added by
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` ${notification?.created_by}`}
            </Typography>
          </Typography>
        )}

        {/* callback */}
        {notification?.call_back_id && (
          <Typography
            onClick={() => {
              navigate(
                `/callback-details/${notification?.app_user_id}/${notification?.call_back_id}`
              ),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            A new callback is requested by your client
            <Typography variant="span" sx={{ fontWeight: '500' }}>
              {` ${notification?.full_name}`}
            </Typography>
          </Typography>
        )}

        {/* buyer  */}
        {notification?.prospect_id && (
          <Typography
            onClick={() => {
              navigate(`/order/transactional-details/${notification?.transaction_order_id}`),
                setIsNotificationModalVisible(false);
            }}
            sx={{ color: '#0A3A67', cursor: 'pointer', fontSize: '14px' }}>
            You have a new update for the buyer {` "${notification?.prospect_reference_no}" `}
            assigned to order
            <Typography
              variant="span"
              sx={{ fontWeight: '500' }}>{` "${notification?.order_no}" `}</Typography>
          </Typography>
        )}
      </Box>

      {/* created at date */}
      <Typography
        variant="span"
        sx={{
          color: '#0A3A67',
          display: 'flex',
          fontSize: '12px',
          justifyContent: 'flex-end',
          marginTop: '4px'
        }}>
        {notification?.created_at
          ? moment(notification?.created_at).format('DD/MM/YYYY hh:mm A')
          : notification?.prospect_added_on
          ? moment(notification?.prospect_added_on).format('DD/MM/YYYY hh:mm A')
          : ''}
      </Typography>
    </Box>
  );
}

NotificationCard.propTypes = {
  handleGetUpdateNotifiList: PropTypes.func,
  notification: PropTypes.object,
  setIsNotificationModalVisible: PropTypes.func
};
