import { createSlice } from '@reduxjs/toolkit';
import {
  addBillingAddress,
  deleteBillingAddress,
  getBillingAddressById,
  getBillingAddressesByClientId,
  updateBillingAddress
} from 'api/billingAddress';
import { toast } from 'react-toastify';

const billingAddressSlice = createSlice({
  initialState: {},
  name: 'billingAddress',
  reducers: {
    login(state) {
      state.type = 'loaded';
      state.isLoggedIn = true;
      state.isLoading = false;
      state.error = false;
    },
    logout(state) {
      state.type = 'empty';
      state.isLoggedIn = false;
      state.isLoggedOut = true;
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const billingAddressActions = billingAddressSlice.actions;

export const handleGetBillingAddressesByClientId = (clientId) => async (dispatch) => {
  try {
    dispatch(billingAddressActions.setIsLoading(true));
    const data = await getBillingAddressesByClientId(clientId);
    return data;
  } catch (error) {
    dispatch(billingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(billingAddressActions.setIsLoading(false));
  }
};

export const handleGetBillingAddressById = (clientId, billingAddressId) => async (dispatch) => {
  try {
    dispatch(billingAddressActions.setIsLoading(true));
    const data = await getBillingAddressById(clientId, billingAddressId);
    return data;
  } catch (error) {
    dispatch(billingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(billingAddressActions.setIsLoading(false));
  }
};

export const handleAddBillingAddress = (clientId, params) => async (dispatch) => {
  try {
    dispatch(billingAddressActions.setIsLoading(true));
    const data = await addBillingAddress(clientId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(billingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(billingAddressActions.setIsLoading(false));
  }
};

export const handleUpdateBillingAddress =
  (clientId, billingAddressId, params) => async (dispatch) => {
    try {
      dispatch(billingAddressActions.setIsLoading(true));
      const data = await updateBillingAddress(clientId, billingAddressId, params);
      if (data?.message) {
        toast.success(data?.message);
      }
      return data;
    } catch (error) {
      dispatch(billingAddressActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(billingAddressActions.setIsLoading(false));
    }
  };

export const handleDeleteBillingAddress = (clientId, callbackId) => async (dispatch) => {
  try {
    dispatch(billingAddressActions.setIsLoading(true));
    const data = await deleteBillingAddress(clientId, callbackId);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(billingAddressActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(billingAddressActions.setIsLoading(false));
  }
};

export default billingAddressSlice;
