import { makeStyles } from '@mui/styles';

const propertyImagesStyles = makeStyles({
  addButton: {
    background: '#E7F1FB !important',
    border: '1px dashed #0F3E6A !important',
    borderRadius: '10px !important',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 400
  },
  container: {
    position: 'relative'
  },
  deleteButton: {
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    height: '28px',
    justifyContent: 'center',
    position: 'absolute',
    right: '8px',
    top: '8px',
    width: '28px'
  },
  image: {
    borderRadius: '10px',
    objectFit: 'cover'
  },
  main: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  },
  modal: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  overlay: {
    background: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',
    borderRadius: '10px',
    content: '""',
    cursor: 'pointer',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1
  }
});

export default propertyImagesStyles;
