import { createSlice } from '@reduxjs/toolkit';
import {
  getByIdVendor,
  getVenderListData,
  getVendorCount,
  postVenderData,
  putVenderData
} from 'api/venders';
import { toast } from 'react-toastify';

export const venderFilterInitialValues = {
  branch: '',
  fromDate: '',
  leadSource: '',
  limit: 10,
  page: 0,
  rewm: '',
  sortAt: '',
  sortBy: '',
  toDate: '',
  updatedFromDate: '',
  updatedToDate: ''
};
const venderSlice = createSlice({
  initialState: {
    filters: venderFilterInitialValues,
    isLoading: false,
    currentTablePage: 0,
    rowPerPage: 10
  },
  name: 'vender',
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const venderActions = venderSlice.actions;

export const handleAddVender = (params) => async (dispatch) => {
  try {
    dispatch(venderActions.setIsLoading(true));
    const data = await postVenderData(params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(venderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(venderActions.setIsLoading(false));
  }
};

export const handleEditVender = (id, params) => async (dispatch) => {
  try {
    dispatch(venderActions.setIsLoading(true));
    const data = await putVenderData(id, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(venderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(venderActions.setIsLoading(false));
  }
};

export const handleGetListVender = (filters) => async (dispatch) => {
  try {
    dispatch(venderActions.setIsLoading(true));
    const data = await getVenderListData(filters);
    return data;
  } catch (error) {
    dispatch(venderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(venderActions.setIsLoading(false));
  }
};

export const handleGetByIdVendor = (id) => async (dispatch) => {
  try {
    dispatch(venderActions.setIsLoading(true));
    const data = await getByIdVendor(id);
    return data;
  } catch (error) {
    dispatch(venderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(venderActions.setIsLoading(false));
  }
};

export const handleGetVendorCityCount = () => async (dispatch) => {
  try {
    dispatch(venderActions.setIsLoading(true));
    const data = await getVendorCount();
    return data;
  } catch (error) {
    dispatch(venderActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(venderActions.setIsLoading(false));
  }
};
export default venderSlice;
