import { axiosClient } from '../utils/axiosClient';

const transactionalOrdersEndpoint = 'v1/clients/transaction_orders';
const transactionalOrdersListingByClientIdEndpoint = 'v1/clients/';
const transactionalOrderEndpoint = 'v1/client/';
const vistaListEndpoint = 'v2/client/advisory/orders';

export const getTransactionalOrdersListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    assignTo,
    rewm,
    createdFromDate,
    createdToDate,
    orderType,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rewm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(transactionalOrdersEndpoint + queryString).then((resp) => resp?.data);
};

export const getVistaListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    assignTo,
    rewm,
    createdFromDate,
    createdToDate,
    orderType,
    subAssignTo,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rewm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    sub_assign_to: subAssignTo,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(vistaListEndpoint + queryString).then((resp) => resp?.data);
};

export const getTransactionalOrderDetailsById = (orderId) => {
  return axiosClient.get('v1/clients/transaction_order/' + orderId).then((resp) => resp?.data);
};

export const getTransactionalOrdersListingByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient
    .get(
      transactionalOrdersListingByClientIdEndpoint +
        clientId +
        `/transaction_orders?limit=${limit}&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const addTransactionalOrder = (clientId, params) => {
  return axiosClient
    .post(transactionalOrderEndpoint + clientId + '/transaction_orders', params)
    .then((resp) => resp?.data);
};

export const cancelTransactionalOrder = (clientId, orderId) => {
  return axiosClient
    .patch(transactionalOrderEndpoint + clientId + '/transaction_orders/' + orderId + '/cancel')
    .then((resp) => resp?.data);
};

export const updateTransactionalOrder = (clientId, orderId, params) => {
  return axiosClient
    .patch(
      transactionalOrderEndpoint + clientId + '/transaction_orders/' + orderId + '/update',
      params
    )
    .then((resp) => resp?.data);
};

export const getTransOrderComments = (orderId) => {
  return axiosClient
    .get(`v1/client/transaction/order/${orderId}/comments`)
    .then((resp) => resp?.data);
};

export const addTransOrderComment = (orderId, comments) => {
  return axiosClient
    .post(`v1/client/transaction/order/${orderId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const transactionCart = (clientId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/transaction_carts`, params)
    .then((resp) => resp?.data);
};

export const assignTransOrderRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/transaction_orders/${orderId}/assign_rm`, params)
    .then((resp) => resp?.data);
};

export const uploadFile = (clientId, files) => {
  return axiosClient
    .post(`v1/client/${clientId}/transaction/order/fileupload?encoded=false`, files)
    .then((resp) => resp?.data);
};

export const getFiles = (clientId, orderId) => {
  return axiosClient
    .get(`v1/client/${clientId}/transaction/order/${orderId}/filelist`)
    .then((resp) => resp?.data);
};

export const deleteFile = (clientId, orderId, fileId) => {
  return axiosClient
    .delete(`v1/client/${clientId}/transaction/order/${orderId}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};

export const addTransOrderExtComment = (orderId, comments) => {
  return axiosClient
    .post(`v1/client/transaction/order/${orderId}/external/comments`, { comments })
    .then((resp) => resp?.data);
};

export const getTransOrderExtComments = (orderId) => {
  return axiosClient
    .get(`v1/client/transaction/order/${orderId}/external/comments?offset=0&limit=100`)
    .then((resp) => resp?.data);
};

export const addTransOrderExtSubComment = (parentId, comments) => {
  return axiosClient
    .post(`v1/client/transaction/order/external/sub/comments/${parentId}`, { comments })
    .then((resp) => resp?.data);
};

export const getTransOrderExtSubComments = (parentId) => {
  return axiosClient
    .get(`v1/client/transaction/order/external/comments/${parentId}`)
    .then((resp) => resp?.data);
};

export const uploadTransOrderCommentFile = (id, files) => {
  return axiosClient
    .post(`v1/client/transaction/order/external/comments/${id}/fileupload?encoded=False`, files)
    .then((resp) => resp?.data);
};

export const searchInTransComment = (offset = 0, limit = 100, search_param, orderId) => {
  return axiosClient
    .get(
      `v1/client/transaction/order/${orderId}/search?offset=${offset}&limit=${limit}&search_param=${search_param}`
    )
    .then((resp) => resp?.data);
};

export const updateTransactionalOrderStatus = (clientId, transOrderId, statusId, subStatusId) => {
  return axiosClient
    .patch(`v2/client/${clientId}/transaction_orders/${transOrderId}/status/update`, {
      order_status: statusId,
      order_sub_status: subStatusId
    })
    .then((resp) => resp?.data);
};

export const updateSaleAdvisoryOrderStatus = (clientId, transOrderId, statusId, subStatusId) => {
  return axiosClient
    .patch(`v2/client/${clientId}/advisory_orders/${transOrderId}/status/update`, {
      order_status: statusId,
      order_sub_status: subStatusId
    })
    .then((resp) => resp?.data);
};

export const updateSubAssignFOS = (clientId, orderId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/transaction_orders/${orderId}/sub_assign_fos`, params)
    .then((resp) => resp?.data);
};

export const updateSubAssignRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/transaction_orders/${orderId}/sub_assign_rm`, params)
    .then((resp) => resp?.data);
};

export const orderSubStatusAdd = (clientId, orderId, params) => {
  return axiosClient
    .post(`v2/client/${clientId}/transaction_orders/${orderId}/sub_status/details/add`, params)
    .then((resp) => resp?.data);
};

export const addPaymentsInOrderSubStatus = (formData) => {
  return axiosClient
    .post('v1/client/order/payments/fileupload?encoded=False', formData)
    .then((resp) => resp?.data);
};

export const orderSubStatusUpdate = (clientId, orderId, params) => {
  return axiosClient
    .patch(`v2/client/${clientId}/transaction_orders/${orderId}/sub_status/details/update`, params)
    .then((resp) => resp?.data);
};

export const updatePaymentsInOrderSubStatus = (paymentId, formData) => {
  return axiosClient
    .patch(`v1/client/order/payments/${paymentId}/fileupload?encoded=False`, formData)
    .then((resp) => resp?.data);
};

export const addTransAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/transaction_orders/${orderId}/assign_rm_status`, params)
    .then((resp) => resp?.data);
};

export const addOrderAssignConfirmStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/normal_orders/${orderId}/assign_confirm_status`, params)
    .then((resp) => resp?.data);
};

export const addTransAssignConfirmStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/transaction_orders/${orderId}/assign_confirm_status`, params)
    .then((resp) => resp?.data);
};

export const addPsOrderAssignConfirmStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/ps_orders/${orderId}/assign_confirm_status`, params)
    .then((resp) => resp?.data);
};

export const addAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/transaction_orders/${orderId}/assign_prm_status`, params)
    .then((resp) => resp?.data);
};

export const addTokenAdvancePayment = (formData) => {
  return axiosClient
    .post('v1/client/order/payments/fileupload?encoded=True', formData)
    .then((resp) => resp?.data);
};

export const addTotalRevenuePayment = (formData) => {
  return axiosClient
    .post('v1/client/order/payments/fileupload?encoded=True', formData)
    .then((resp) => resp?.data);
};

export const addPartialRevenuePayment = (formData) => {
  return axiosClient
    .post('v1/client/order/payments/fileupload?encoded=True', formData)
    .then((resp) => resp?.data);
};

export const getTransPerformanceCredit = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/transaction_orders/${orderId}/performance_credit`)
    .then((resp) => resp?.data);
};

export const getTransPaymentList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/transaction_orders/${orderId}/payments`)
    .then((resp) => resp?.data);
};

export const updateTransAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/transaction_orders/${orderId}/assign_rm_status/update`, params)
    .then((resp) => resp?.data);
};

export const updateAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/transaction_orders/${orderId}/assign_prm_status/update`, params)
    .then((resp) => resp?.data);
};

export const getTransPerformancePointList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/transaction_orders/${orderId}/performance_point_payments`)
    .then((resp) => resp?.data);
};

export const addPerformancePoint = (formData) => {
  return axiosClient
    .post('v1/client/order/payments/fileupload?encoded=True', formData)
    .then((resp) => resp?.data);
};
