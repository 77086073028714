import { makeStyles } from '@mui/styles';

const propertiesOrOrderNotFoundStyles = makeStyles({
  main: {
    '& button': {
      background: '#0A3A67',
      borderRadius: '10px',
      fontFamily: 'Lato',
      fontSize: '18px',
      marginTop: '24px',
      textTransform: 'capitalize'
    },
    '& div': {
      flex: 1
    },
    '& img': {
      width: '80%'
    },
    '& p': {
      color: '#0A3A67',
      fontFamily: 'Literata !important',
      fontSize: '30px',
      fontWeight: '600'
    },
    '@media (max-width:768px)': {
      '& div': {
        textAlign: 'center'
      },
      flexDirection: 'column',
      padding: '1rem'
    },
    alignItems: 'center',
    background: '#fff',
    borderRadius: '30px',
    display: 'flex',
    marginTop: '3rem',
    padding: '5rem'
  }
});

export default propertiesOrOrderNotFoundStyles;
