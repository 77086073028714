import { axiosClient } from '../utils/axiosClient';

export const getBillingAddressesByClientId = (clientId) => {
  return axiosClient.get(`v1/client/${clientId}/billing_address`).then((resp) => resp?.data);
};

export const getBillingAddressById = (clientId, billingAddressId) => {
  return axiosClient
    .get(`v1/client/${clientId}/billing_address/${billingAddressId}`)
    .then((resp) => resp?.data);
};

export const addBillingAddress = (clientId, params) => {
  return axiosClient
    .post('v1/client/' + clientId + '/billing_address', params)
    .then((resp) => resp?.data);
};

export const updateBillingAddress = (clientId, billingAddressId, params) => {
  return axiosClient
    .put('v1/client/' + clientId + '/billing_address/' + billingAddressId, params)
    .then((resp) => resp?.data);
};

export const deleteBillingAddress = (clientId, callbackId) => {
  return axiosClient.delete('v1/client/' + clientId, +'/' + callbackId).then((resp) => resp?.data);
};
