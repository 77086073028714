import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addCallBackLoanDetails,
  addExistingLoan,
  deleteLoanFile,
  getCallBackDetailsByLoanId,
  getLoanDetails,
  getLoanDetailsByClientId,
  getLoanDetailsByPropertyId,
  getLoanDropdownList,
  getLoanFiles,
  getLoans,
  getSourceTypeDropdownValues,
  postAddLoanDetails,
  updateCallBackLoanDetails,
  updateExistingLoan,
  updateLoanDetails,
  updateLoanStatus,
  uploadLoanFile
} from './loans.api';

export const loanFilterInitialValues = {
  branch: '',
  branchHead: '',
  createdFromDate: '',
  createdToDate: '',
  customerType: '',
  limit: 10,
  loanStatus: '',
  loanType: '',
  page: 0,
  premiumType: '',
  rewm: '',
  rm: '',
  sortAt: '',
  sortBy: '',
  updatedFromDate: '',
  updatedToDate: ''
};

const loanSlice = createSlice({
  initialState: {
    currentTablePage: 0,
    filters: loanFilterInitialValues,
    isLoading: false,
    rowPerPage: 10
  },
  name: 'loans',
  reducers: {
    setCurrentTablePage(state, action) {
      state.currentTablePage = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setRowPerPage(state, action) {
      state.rowPerPage = action.payload;
    }
  }
});

export const loansActions = loanSlice.actions;

export const handleGetLoans = (filters) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getLoans(filters);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetLoanDetails = (clientId, loanId) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getLoanDetails(clientId, loanId);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetLoanDetailsByClientId = (clientId) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getLoanDetailsByClientId(clientId);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetLoanDetailsByPropertyId = (clientId, propertyId) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getLoanDetailsByPropertyId(clientId, propertyId);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetLoanDropdownList = () => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getLoanDropdownList();
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handlePostAddLoanDetails = (clientId, loanDetails) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await postAddLoanDetails(clientId, loanDetails);
    if (data) toast.success(data?.message || 'Loan added successfully');
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleUpdateLoanDetails = (clientId, loanId, loanDetails) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await updateLoanDetails(clientId, loanId, loanDetails);
    if (data) toast.success(data?.message || 'Loan added successfully');
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleAddExistingLoan = (clientId, loanId, loanDetails) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await addExistingLoan(clientId, loanId, loanDetails);
    if (data) toast.success(data?.message || 'Loan added successfully');
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleUpdateExistingLoan =
  (clientId, existingLoanId, loanDetails) => async (dispatch) => {
    try {
      dispatch(loansActions.setIsLoading(true));
      const data = await updateExistingLoan(clientId, existingLoanId, loanDetails);
      if (data) toast.success(data?.message || 'Loan added successfully');
      return data;
    } catch (error) {
      dispatch(loansActions.setIsLoading(false));
      let errorMessage = error?.message;

      if (error?.response) {
        errorMessage = error?.response?.data?.message || error?.response?.data?.error;

        if (error?.response?.status === 502) {
          errorMessage = 'The server encountered a problem';
        }
      } else if (error?.request) {
        errorMessage = 'Request was made but no response was received';
      }
      toast.error(errorMessage);
    } finally {
      dispatch(loansActions.setIsLoading(false));
    }
  };

export const handleLoanFileUpload = (loanId, files) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await uploadLoanFile(loanId, files);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetLoanFiles = (loanId) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getLoanFiles(loanId);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleDeleteLoanFile = (loanId, fileId) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await deleteLoanFile(loanId, fileId);
    if (data?.msg) {
      toast.success('File deleted successfully!');
    }
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage || 'Something went wrong');
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetCallBackDetails = (loanId) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getCallBackDetailsByLoanId(loanId);
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleAddCallBackLoan = (loanId, params) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await addCallBackLoanDetails(loanId, params);
    if (data) toast.success(data?.message || 'Loan callback added successfully');

    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleGetSourceTypeDropdownValues = () => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await getSourceTypeDropdownValues();
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleUpdateCallBackLoan = (loanId, callbackId, params) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await updateCallBackLoanDetails(loanId, callbackId, params);
    if (data?.message) {
      toast.success(data?.message);
    }
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export const handleUpdateLoanStatus = (loanId, params) => async (dispatch) => {
  try {
    dispatch(loansActions.setIsLoading(true));
    const data = await updateLoanStatus(loanId, params);
    if (data) toast.success(data?.message || 'Loan status updated successfully');
    return data;
  } catch (error) {
    dispatch(loansActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    toast.error(errorMessage);
  } finally {
    dispatch(loansActions.setIsLoading(false));
  }
};

export default loanSlice;
