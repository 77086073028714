import { axiosClient } from '../utils/axiosClient';

const getCallbacksEndpoint = 'v1/clients/call_backs';
const callbackEndpoint = 'v1/client';

export const getCallbacks = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    createdFromDate,
    createdToDate,
    updatedFromDate,
    updatedToDate,
    rewm,
    sortAt,
    sortBy,
    requestedFromDate,
    requesedToDate,
    callbackStatus
  } = filters;

  const params = {
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    portfolio_manager: rewm,
    request_from_date: requestedFromDate,
    request_to_date: requesedToDate,
    sort_at: sortAt,
    sort_by: sortBy,
    status: callbackStatus,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(getCallbacksEndpoint + queryString).then((resp) => resp?.data);
};

export const getCallbackById = (id) => {
  return axiosClient.get(callbackEndpoint + '/call_back/' + id).then((resp) => resp?.data);
};

export const createCallback = (clientId, params) => {
  return axiosClient
    .post(callbackEndpoint + '/' + clientId + '/call_back', params)
    .then((resp) => resp?.data);
};

export const updateCallback = (clientId, callbackId, params) => {
  return axiosClient
    .put(callbackEndpoint + '/' + clientId + '/call_back/' + callbackId, params)
    .then((resp) => resp?.data);
};

export const updateCallbackStatus = (clientId, callbackId, status) => {
  return axiosClient
    .put(`/v2/client/${clientId}/call_back/${callbackId}/update`, { status })
    .then((resp) => resp?.data);
};

export const deleteCallback = (clientId, callbackId) => {
  return axiosClient
    .delete(callbackEndpoint + '/' + clientId, +'/' + callbackId)
    .then((resp) => resp?.data);
};

export const getCallbackComments = (clientId, callbackId) => {
  return axiosClient
    .get(`/v2/client/${clientId}/call_back/${callbackId}/comments`)
    .then((resp) => resp?.data);
};

export const addCallbackComment = (clientId, callbackId, comment) => {
  return axiosClient
    .post(`/v2/client/${clientId}/call_back/${callbackId}/comments`, {
      comments: comment
    })
    .then((resp) => resp?.data);
};

export const getCallbacksByClientId = (id) => {
  return axiosClient.get(`v1/client/${id}/call_back`).then((resp) => resp?.data);
};
