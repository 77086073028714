import React from 'react';
import PropTypes from 'prop-types';

function HtmlContent({ text }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `${text?.replaceAll('\n', '</br>')}`
      }}></div>
  );
}

export default HtmlContent;

HtmlContent.propTypes = {
  text: PropTypes.string
};
