import { makeStyles } from '@mui/styles';

const commentStyles = makeStyles({
  box: {
    '@media (max-width:768px)': {
      height: '100% !important',
      maxWidth: '100% !important',
      minWidth: '100% !important'
    },
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 0px 50px -25px rgba(0, 0, 0, 0.5) !important',
    color: '#093A67',
    height: '620px !important',
    maxWidth: '854px !important',
    minWidth: '480px !important',
    overflowY: 'scroll',
    padding: '2rem'
  },
  closeBtn: {
    background: '#0A3A6717 !important',
    borderRadius: '6px !important',
    padding: '.5rem !important'
  },
  modal: {
    '@media (max-width:768px)': {
      height: '90% !important',
      margin: 'auto auto',
      width: '90% !important'
    },
    alignItems: 'center',
    display: 'flex',
    height: '100vh !important',
    justifyContent: 'center',
    width: '100vw !important'
  }
});

export default commentStyles;
