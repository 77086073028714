import { axiosClient } from '../../../utils/axiosClient';

const addClientEndpoint = '/v2/primary_sales/client/add';

export const getPrimarySalesBuilders = (filters = {}) => {
  const {
    branch,
    branchHead,
    createdFromDate,
    createdToDate,
    customerType,
    limit,
    page,
    premiumType,
    rewm,
    rm,
    sortAt,
    sortBy,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    branch,
    branch_head: branchHead,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    portfolio_manager: rewm,
    premium_type: premiumType,
    rm_name: rm,
    sort_at: sortAt,
    sort_by: sortBy,
    to_date: createdToDate,
    type: customerType,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get('/v2/primary_sales/clients' + queryString).then((resp) => resp?.data);
};

export const getPrimarySalesBuilderDetails = (builderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/details/${builderId}`)
    .then((resp) => resp?.data);
};

export const getPrimarySalesBuilderInsights = (builderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/${builderId}/insights`)
    .then((resp) => resp?.data);
};

export const assignPortfolioManagerForPrimarySales = (builderId, assignedTo) => {
  return axiosClient
    .put(`/v2/primary_sales/client/${builderId}/assign_rm`, { assigned_to: assignedTo })
    .then((resp) => resp?.data);
};

export const addPrimarySalesBuilder = (params) => {
  return axiosClient.post(addClientEndpoint, params).then((resp) => resp?.data);
};

export const updatePrimarySalesBuilder = (builderId, params) => {
  return axiosClient
    .put(`/v2/primary_sales/client/${builderId}/update`, params)
    .then((resp) => resp?.data);
};

export const deletePrimarySalesBuilder = (builderId) => {
  return axiosClient
    .delete(`/v2/primary_sales/client/${builderId}/delete`)
    .then((resp) => resp?.data);
};

export const getPrimarySalesBuilderComments = (builderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/${builderId}/comments`)
    .then((resp) => resp?.data);
};

export const addPrimarySalesBuilderComment = (builderId, comments) => {
  return axiosClient
    .post(`/v2/primary_sales/client/${builderId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const getPrimarySalesPayments = (id) => {
  return axiosClient
    .get('/v2/primary_sales/users/payments/status/' + id)
    .then((resp) => resp?.data);
};

export const addPrimarySalesPaymentDetails = (formData) => {
  return axiosClient
    .post('/v2/primary_sales/client/payments/fileupload?encoded=false', formData)
    .then((resp) => resp?.data);
};

export const updatePrimarySalesPaymentDetails = (formData, paymentId) => {
  return axiosClient
    .put(`/v2/primary_sales/client/payments/update?payment_id=${paymentId}`, formData)
    .then((resp) => resp?.data);
};

export const updatePrimarySalesPremiumType = (builderId, premiumType) => {
  return axiosClient
    .put(`/v2/primary_sales/client/${builderId}/update/premium`, { premium_type: premiumType })
    .then((resp) => resp?.data);
};

export const getPrimarySalesPayment = (builderId, paymentId) => {
  return axiosClient
    .get(`/v2/primary_sales/users/payments/${builderId}?payment_id=${paymentId}`)
    .then((resp) => resp?.data);
};
