import { createSlice } from '@reduxjs/toolkit';
import { getRecommendations } from 'api/recommendation';

const recommendationSlice = createSlice({
  initialState: {
    isLoading: false
  },
  name: 'recommendation',
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const recommendationActions = recommendationSlice.actions;

export const handleGetRecommendation = (params) => async (dispatch) => {
  try {
    dispatch(recommendationActions.setIsLoading(true));
    const response = await getRecommendations(params);
    return response;
  } catch (error) {
    dispatch(recommendationActions.setIsLoading(false));
    let errorMessage = error?.message;

    if (error?.response) {
      errorMessage = error?.response?.data?.message || error?.response?.data?.error;

      if (error?.response?.status === 502) {
        errorMessage = 'The server encountered a problem';
      }
    } else if (error?.request) {
      errorMessage = 'Request was made but no response was received';
    }
    console.log(errorMessage);
  } finally {
    dispatch(recommendationActions.setIsLoading(false));
  }
};

export default recommendationSlice;
