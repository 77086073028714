import { axiosClient } from '../../../utils/axiosClient';

const transactionalOrdersEndpoint = '/v2/primary_sales/clients/transaction_orders';
const transactionalOrdersListingByClientIdEndpoint = '/v2/primary_sales/clients/';
const transactionalOrderEndpoint = '/v2/primary_sales/client/';

export const getPrimarySalesTransactionalOrdersListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    assignTo,
    rewm,
    createdFromDate,
    createdToDate,
    orderType,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rewm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(transactionalOrdersEndpoint + queryString).then((resp) => resp?.data);
};

export const getPrimarySalesTransactionalOrderDetailsById = (orderId) => {
  return axiosClient
    .get('/v2/primary_sales/clients/transaction_order/' + orderId)
    .then((resp) => resp?.data);
};

export const getPrimarySalesTransactionalOrdersListingByClientId = (
  clientId,
  page = 0,
  limit = 10
) => {
  return axiosClient
    .get(
      transactionalOrdersListingByClientIdEndpoint +
        clientId +
        `/transaction_orders?limit=${limit}&offset=${page * limit}`
    )
    .then((resp) => resp?.data);
};

export const addPrimarySalesTransactionalOrder = (clientId, params) => {
  return axiosClient
    .post(transactionalOrderEndpoint + clientId + '/transaction_orders', params)
    .then((resp) => resp?.data);
};

export const cancelPrimarySalesTransactionalOrder = (clientId, orderId) => {
  return axiosClient
    .patch(transactionalOrderEndpoint + clientId + '/transaction_orders/' + orderId + '/cancel')
    .then((resp) => resp?.data);
};

export const updatePrimarySalesTransactionalOrder = (clientId, orderId, params) => {
  return axiosClient
    .patch(
      transactionalOrderEndpoint + clientId + '/transaction_orders/' + orderId + '/update',
      params
    )
    .then((resp) => resp?.data);
};

export const getPrimarySalesTransOrderComments = (orderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/transaction/order/${orderId}/comments`)
    .then((resp) => resp?.data);
};

export const addPrimarySalesTransOrderComment = (orderId, comments) => {
  return axiosClient
    .post(`/v2/primary_sales/client/transaction/order/${orderId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const primarySalesTransactionCart = (clientId, params) => {
  return axiosClient
    .put(`/v2/primary_sales/client/${clientId}/transaction_carts`, params)
    .then((resp) => resp?.data);
};

export const assignPrimarySalesTransOrderRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`/v2/primary_sales/client/${clientId}/transaction_orders/${orderId}/assign_rm`, params)
    .then((resp) => resp?.data);
};

export const uploadPrimarySalesTransOrderFile = (clientId, files) => {
  return axiosClient
    .post(`/v2/primary_sales/client/${clientId}/transaction/order/fileupload?encoded=false`, files)
    .then((resp) => resp?.data);
};

export const getPrimarySalesTransOrderFiles = (clientId, orderId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/${clientId}/transaction/order/${orderId}/filelist`)
    .then((resp) => resp?.data);
};

export const deletePrimarySalesTransOrderFile = (clientId, orderId, fileId) => {
  return axiosClient
    .delete(
      `/v2/primary_sales/client/${clientId}/transaction/order/${orderId}/file/${fileId}/delete`
    )
    .then((resp) => resp?.data);
};

export const addPrimarySalesTransOrderExtComment = (orderId, comments) => {
  return axiosClient
    .post(`/v2/primary_sales/client/transaction/order/${orderId}/external/comments`, {
      comments
    })
    .then((resp) => resp?.data);
};

export const getPrimarySalesTransOrderExtComments = (orderId) => {
  return axiosClient
    .get(
      `/v2/primary_sales/client/transaction/order/${orderId}/external/comments?offset=0&limit=100`
    )
    .then((resp) => resp?.data);
};

export const addPrimarySalesTransOrderExtSubComment = (parentId, comments) => {
  return axiosClient
    .post(`/v2/primary_sales/client/transaction/order/external/sub/comments/${parentId}`, {
      comments
    })
    .then((resp) => resp?.data);
};

export const getPrimarySalesTransOrderExtSubComments = (parentId) => {
  return axiosClient
    .get(`/v2/primary_sales/client/transaction/order/external/comments/${parentId}`)
    .then((resp) => resp?.data);
};

export const uploadPrimarySalesTransOrderCommentFile = (id, files) => {
  return axiosClient
    .post(
      `/v2/primary_sales/client/transaction/order/external/comments/${id}/fileupload?encoded=False`,
      files
    )
    .then((resp) => resp?.data);
};

export const searchInPrimarySalesTransComment = (
  offset = 0,
  limit = 100,
  search_param,
  orderId
) => {
  return axiosClient
    .get(
      `/v2/primary_sales/client/transaction/order/${orderId}/search?offset=${offset}&limit=${limit}&search_param=${search_param}`
    )
    .then((resp) => resp?.data);
};

export const updatePrimarySalesTransactionalOrderStatus = (
  clientId,
  transOrderId,
  statusId,
  subStatusId
) => {
  return axiosClient
    .patch(
      `/v2/primary_sales/client/${clientId}/transaction_orders/${transOrderId}/status/update`,
      {
        order_status: statusId,
        order_sub_status: subStatusId
      }
    )
    .then((resp) => resp?.data);
};

export const updatePrimarySalesSubAssignFOS = (clientId, orderId, params) => {
  return axiosClient
    .put(
      `/v2/primary_sales/client/${clientId}/transaction_orders/${orderId}/sub_assign_fos`,
      params
    )
    .then((resp) => resp?.data);
};

export const updatePrimarySalesTransOrderSubAssignRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`/v2/primary_sales/client/${clientId}/transaction_orders/${orderId}/sub_assign_rm`, params)
    .then((resp) => resp?.data);
};

export const primarySalesTransOrderSubStatusAdd = (clientId, orderId, params) => {
  return axiosClient
    .post(
      `/v2/primary_sales/client/${clientId}/transaction_orders/${orderId}/sub_status/details/add`,
      params
    )
    .then((resp) => resp?.data);
};

export const addPaymentsInPrimarySalesTransOrderSubStatus = (formData) => {
  return axiosClient
    .post('/v2/primary_sales/client/order/payments/fileupload?encoded=False', formData)
    .then((resp) => resp?.data);
};

export const primarySalesTransOrderSubStatusUpdate = (clientId, orderId, params) => {
  return axiosClient
    .patch(
      `/v2/primary_sales/client/${clientId}/transaction_orders/${orderId}/sub_status/details/update`,
      params
    )
    .then((resp) => resp?.data);
};

export const updatePaymentsInPrimarySalesTransOrderSubStatus = (paymentId, formData) => {
  return axiosClient
    .patch(`/v2/primary_sales/client/order/payments/${paymentId}/fileupload?encoded=True`, formData)
    .then((resp) => resp?.data);
};

export const addTransAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/ps_orders/${orderId}/assign_rm_status`, params)
    .then((resp) => resp?.data);
};

export const addAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/ps_orders/${orderId}/assign_prm_status`, params)
    .then((resp) => resp?.data);
};

export const getPSTransPerformanceCredit = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/ps_orders/${orderId}/performance_credit`)
    .then((resp) => resp?.data);
};

export const getPSTransPaymentList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/ps_orders/${orderId}/payments`)
    .then((resp) => resp?.data);
};

export const addTokenAdvancePayment = (formData) => {
  return axiosClient
    .post('v2/primary_sales/client/order/payments/fileupload', formData)
    .then((resp) => resp?.data);
};

export const addTotalRevenuePayment = (formData) => {
  return axiosClient
    .post('v2/primary_sales/client/order/payments/fileupload', formData)
    .then((resp) => resp?.data);
};

export const addPartialRevenuePayment = (formData) => {
  return axiosClient
    .post('v2/primary_sales/client/order/payments/fileupload', formData)
    .then((resp) => resp?.data);
};

export const updatePSTransAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/ps_orders/${orderId}/assign_rm_status/update`, params)
    .then((resp) => resp?.data);
};

export const updateAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/ps_orders/${orderId}/assign_prm_status/update`, params)
    .then((resp) => resp?.data);
};

export const verifyTDS = (clientId, paymentId, isTDSVerified) => {
  return axiosClient
    .put(`v2/client/${clientId}/ps_payments/${paymentId}/tds_status?tds_status=${isTDSVerified}`, {
      tds_status: isTDSVerified
    })
    .then((resp) => resp?.data);
};

export const verifyPayment = (clientId, paymentId, isPaymentVerified, emailId) => {
  return axiosClient
    .put(
      `v2/client/${clientId}/ps_payments/${paymentId}/verification_status?payment_status=${isPaymentVerified}&assign_to=${emailId}`,
      {
        assign_to: emailId,
        payment_status: isPaymentVerified
      }
    )
    .then((resp) => resp?.data);
};

export const verifyPaymentByBranchHead = (
  clientId,
  paymentId,
  isPaymentVerifiedByBranchHead,
  prmEmailId,
  bhEmailId
) => {
  return axiosClient
    .put(
      `v2/client/${clientId}/ps_payments/${paymentId}/bh_status?bh_status=${isPaymentVerifiedByBranchHead}&bh_email=${bhEmailId}&prm_email=${prmEmailId}`,
      {
        bh_email: bhEmailId,
        bh_status: isPaymentVerifiedByBranchHead,
        prm_email: prmEmailId
      }
    )
    .then((resp) => resp?.data);
};

export const verifyPaymentByNationalHead = (
  clientId,
  paymentId,
  isPaymentVerifiedByNationalHead,
  bhEmailId
) => {
  return axiosClient
    .put(
      `v2/client/${clientId}/payments/${paymentId}/nh_status?nh_status=${isPaymentVerifiedByNationalHead}&bh_email=${bhEmailId}`,
      {
        bh_email: bhEmailId,
        nh_status: isPaymentVerifiedByNationalHead
      }
    )
    .then((resp) => resp?.data);
};

export const getPSPerformancePointList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/ps_orders/${orderId}/performance_point_payments`)
    .then((resp) => resp?.data);
};

export const addPerformancePoint = (formData) => {
  return axiosClient
    .post('v2/primary_sales/client/order/payments/fileupload', formData)
    .then((resp) => resp?.data);
};