import React from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import tabContainerStyles from './TabContainer.styles';

export default function TabsContainer({ setValue, tabs = [], value = '', ...props }) {
  const classes = tabContainerStyles();
  return (
    <Box className={classes.main}>
      <Tabs
        value={value}
        centered
        allowScrollButtonsMobile
        className={classes.tabs}
        onChange={(event, newValue) => setValue(newValue)}
        {...props}>
        {tabs?.map((tab) => (
          <Tab
            key={tab.value}
            className={classes.tab}
            value={tab.value}
            label={
              <>
                {tab.label}
                <Typography sx={{ fontSize: '12px' }}>
                  {tab.count ? tab.count + ' Notifications' : ''}
                </Typography>
              </>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
}

TabsContainer.propTypes = {
  setValue: PropTypes.func,
  tabs: PropTypes.array,
  value: PropTypes.string
};
