import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AttachFile as AttachFileIcon,
  Close as CloseIcon,
  Telegram as TelegramIcon
} from '@mui/icons-material';
import { Avatar, Box, Button, Grid, IconButton, Modal, TextField, Typography } from '@mui/material';
import HtmlContent from 'components/HtmlContent';
import moment from 'moment';
import PropTypes from 'prop-types';
import ImagesLinks from 'utils/ImagesLinks';
import commentStyles from './Comment.styles';

export default function Comments({
  internalComments,
  addComments,
  id,
  handleDisplayCommentModal,
  openInternalCommentsModal,
  title,
  orderId,
  transType,
  handleCommentFiles,
  commentFiles
}) {
  const classes = commentStyles();
  const navigate = useNavigate();
  const [commentText, setCommentText] = useState('');

  const uploadFile = () => {
    addComments(id, commentText);
    setCommentText('');
  };

  const handleDeleteCommentFile = (index) => {
    const fileArray = Array.from(commentFiles);
    fileArray.splice(index, 1);
    handleCommentFiles(fileArray);
  };

  return (
    <>
      <Box
        className={classes.main}
        sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
          {/* title and view more button */}
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#0A3A67', fontSize: '1rem', fontWeight: '500' }}>
              {title}
            </Typography>

            {internalComments?.length > 0 && (
              <Button
                className={classes.viewMoreBtn}
                onClick={
                  title === 'Client comments'
                    ? () => navigate(`/${transType}/${orderId}/client-comments`)
                    : handleDisplayCommentModal
                }>
                View all
              </Button>
            )}
          </Box>

          {/* comment note */}
          {title === 'Client comments' && (
            <Typography sx={{ color: '#FF5353', fontSize: '16px', fontWeight: '400' }}>
              * This comment will be sent to the client.
            </Typography>
          )}

          {internalComments?.[0] ? (
            <Box className={classes.commentBox}>
              <Grid container sx={{ alignItems: 'center', gap: 2, marginBottom: '1rem' }}>
                <Grid item>
                  <Avatar />
                </Grid>

                <Grid item>
                  {/* comment added by */}
                  <Typography sx={{ color: '#0A3A67', fontSize: '1rem', fontWeight: '500' }}>
                    {internalComments?.[0]?.created_by}
                  </Typography>

                  {/* comment date and time */}
                  <Typography sx={{ color: '#0A3A6766', fontWeight: '400' }}>
                    {moment(internalComments?.[0]?.created_at).format('DD.MM.YYYY hh:mm A')}
                  </Typography>
                </Grid>
              </Grid>

              {/* comment title */}
              <Box sx={{ border: '1px solid rgba(10, 58, 103, 0.15)', borderRadius: '10px' }}>
                <Typography className={classes.comment} sx={{ margin: '1rem .5rem' }}>
                  <HtmlContent text={internalComments?.[0]?.comments} />
                </Typography>
              </Box>

              {/* comment files */}
              {internalComments?.[0]?.files?.length > 0 && (
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: 2,
                    marginBottom: '1.2rem'
                  }}>
                  {internalComments?.[0]?.files?.map((file) => (
                    <Box
                      key={file.file_id}
                      sx={{
                        alignItems: 'center',
                        border: '1px black solid',
                        borderRadius: '6px',
                        display: 'flex',
                        height: '7rem',
                        justifyContent: 'center',
                        padding: '6px',
                        width: '7rem'
                      }}>
                      {file?.file_type?.startsWith('image') && (
                        <img
                          src={file.file_url}
                          style={{
                            borderRadius: '6px',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                          alt={file.file_name}
                        />
                      )}

                      {file.file_type === 'application/pdf' && (
                        <img
                          style={{
                            borderRadius: '6px',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                          src={ImagesLinks.PdfIcon}
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                border: '1px solid rgba(10, 58, 103, 0.15)',
                borderRadius: '10px ',
                marginTop: '1rem',
                padding: '2rem 0',
                textAlign: 'center'
              }}>
              <Typography sx={{ color: '#0A3A67', fontSize: '2rem', fontWeight: '400' }}>
                No Comments
              </Typography>
              <Typography
                sx={{
                  color: '#0A3A6799',
                  fontSize: '14px',
                  fontWeight: '400',
                  margin: '.5rem 0'
                }}>
                You don’t have any comments added.
              </Typography>
              <Box sx={{ margin: '0 auto', width: '5rem' }}>
                <img src={ImagesLinks.NoCommentIcon} alt="No comments" style={{ width: '100%' }} />
              </Box>
            </Box>
          )}
        </Box>

        {/* type here text field */}
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Avatar sx={{ height: '2rem', width: '2rem' }} />
          <Box
            sx={{
              border: '0.5px solid rgba(10, 58, 103, 0.4)',
              borderRadius: '10px',
              marginLeft: '1rem',
              width: '90%'
            }}>
            <TextField
              placeholder="Type here"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              multiline
              className={classes.addComment}
              sx={{
                '& .MuiInputBase-root': {
                  width: '95% !important'
                }
              }}
              variant="standard"
              InputProps={{
                disableUnderline: true
              }}
            />

            <Box
              sx={{
                display: commentFiles?.length > 0 ? 'flex' : 'none',
                gap: 1,
                margin: '1rem'
              }}>
              {commentFiles?.length > 0 &&
                Array.prototype.slice.call(commentFiles)?.map((file, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        alignItems: 'center',
                        border: '1px black solid',
                        borderRadius: '6px',
                        display: 'flex',
                        height: '7rem',
                        justifyContent: 'center',
                        padding: '6px',
                        position: 'relative',
                        width: '7rem'
                      }}>
                      <IconButton
                        onClick={() => handleDeleteCommentFile(index)}
                        sx={{
                          background: 'rgba(255,255,255,0.6)',
                          padding: '4px',
                          position: 'absolute',
                          right: 8,
                          top: 8
                        }}>
                        <CloseIcon sx={{ fontSize: '16px', padding: '0px' }} />
                      </IconButton>
                      {file?.type?.startsWith('image') && (
                        <img
                          style={{
                            borderRadius: '6px',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                          src={URL.createObjectURL(file)}
                          alt={file.name}
                        />
                      )}

                      {file?.type === 'application/pdf' && (
                        <img
                          style={{
                            borderRadius: '6px',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%'
                          }}
                          src={ImagesLinks.PdfIcon}
                        />
                      )}
                    </Box>
                  );
                })}
            </Box>
          </Box>

          {title === 'Client comments' && (
            <IconButton
              className={classes.iconBox}
              component="label"
              sx={{ border: '1px solid rgba(10, 58, 103, 0.15)', margin: '0 .5rem' }}>
              <AttachFileIcon sx={{ color: '#0A3A67' }} />
              <input
                type="file"
                hidden
                accept="image/*, application/pdf,"
                multiple
                onChange={(e) => handleCommentFiles(e.target.files)}
              />
            </IconButton>
          )}

          <IconButton
            className={classes.iconBox}
            sx={{ background: '#0F3E6A', marginLeft: '.5rem' }}
            onClick={uploadFile}>
            <TelegramIcon sx={{ color: 'white' }} />
          </IconButton>
        </Box>
      </Box>

      {/*  comments modal box */}
      <Modal
        open={openInternalCommentsModal}
        onClose={handleDisplayCommentModal}
        className={classes.modal}>
        <Box className={classes.box}>
          <Box sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#0A3A67', fontSize: '20px', fontWeight: '500' }}>
              Internal Comments
            </Typography>
            <IconButton onClick={handleDisplayCommentModal} className={classes.closeBtn}>
              <CloseIcon sx={{ color: '#0A3A67' }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '95%',
              justifyContent: 'space-between'
            }}>
            <Box sx={{ maxHeight: '90%', overflow: 'scroll' }}>
              {internalComments?.length &&
                internalComments
                  ?.slice()
                  ?.reverse()
                  ?.map((item, index) => (
                    <Box key={index} className={classes.commentBox}>
                      <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                        <Avatar />
                        <Typography sx={{ color: '#0A3A67', fontSize: '1rem', fontWeight: '500' }}>
                          {item?.created_by}
                        </Typography>
                        <Typography sx={{ color: '#0A3A6766', fontWeight: '400' }}>
                          {moment(item?.created_at).format('DD.MM.YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography className={classes.comment} sx={{ marginTop: '1rem' }}>
                          {/* {item?.comments} */}
                          <HtmlContent text={item?.comments} />
                        </Typography>
                      </Box>
                    </Box>
                  ))}
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                marginTop: '1rem'
              }}>
              <Avatar sx={{ height: '2rem', width: '2rem' }} />
              <TextField
                placeholder="Type here / Add your comments"
                value={commentText}
                onChange={(e) => {
                  setCommentText(e.target.value);
                }}
                multiline
                className={classes.addComment}
                sx={{
                  '& .MuiInputBase-root': {
                    width: '90% !important'
                  }
                }}
              />
              {title === 'Client comments' && (
                <IconButton
                  className={classes.iconBox}
                  sx={{ border: '1px solid rgba(10, 58, 103, 0.15)', marginRight: '.5rem' }}>
                  <AttachFileIcon sx={{ color: '#0A3A67' }} />
                </IconButton>
              )}

              <IconButton
                className={classes.iconBox}
                sx={{ background: '#0F3E6A' }}
                onClick={() => {
                  commentText?.length && addComments(id, commentText);
                  setCommentText('');
                }}>
                <TelegramIcon sx={{ color: 'white' }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

Comments.propTypes = {
  addComments: PropTypes.func,
  commentFiles: PropTypes.array,
  comments: PropTypes.array,
  handleCommentFiles: PropTypes.func,
  handleDisplayCommentModal: PropTypes.func,
  id: PropTypes.string,
  internalComments: PropTypes.array,
  openInternalCommentsModal: PropTypes.bool,
  orderId: PropTypes.string,
  title: PropTypes.string,
  transType: PropTypes.string
};
