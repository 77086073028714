import { axiosClient } from '../utils/axiosClient';

const ordersListingEndpoint = 'v1/clients/normal_orders';
const orderDetailsByIdEndpoint = 'v1/clients/order/';
const orderListingByClientIdEndpoint = 'v1/clients/';
const orderAdvisoryTypeDropdown = 'v2/client/advisory/dropdown/values';
const orderTaxAssessmentEndpoint = 'v2/client/tax_assessment/orders';
const orderTaxAssessmentDropdownEndpoint = 'v2/client/tax_assessment/dropdown/values';

export const getOrdersListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    assignTo,
    subAssignTo,
    rewm,
    createdFromDate,
    createdToDate,
    orderType,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rewm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    sub_assign_to: subAssignTo,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(ordersListingEndpoint + queryString).then((resp) => resp?.data);
};

export const getTaxAssessmentListing = (filters = {}) => {
  const {
    page = 0,
    limit = 10,
    branch,
    assignTo,
    subAssignTo,
    rewm,
    createdFromDate,
    createdToDate,
    orderType,
    sortAt,
    sortBy,
    orderStatus,
    updatedFromDate,
    updatedToDate
  } = filters;

  const params = {
    assign_to: assignTo,
    branch,
    from_date: createdFromDate,
    limit,
    offset: limit * page,
    order_type: orderType,
    relationship_manager: rewm,
    sort_at: sortAt,
    sort_by: sortBy,
    status: orderStatus,
    sub_assign_to: subAssignTo,
    to_date: createdToDate,
    update_from_date: updatedFromDate,
    update_to_date: updatedToDate
  };

  const query = Object.entries(params)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => value !== undefined && value !== '' && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const queryString = query ? `?${query}` : '';

  return axiosClient.get(orderTaxAssessmentEndpoint + queryString).then((resp) => resp?.data);
};

export const getVistaOrdersListingByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient.get(`v2/client/${clientId}/advisory/orders`).then((resp) => resp?.data);
};

export const getTaxAssessmentOrdersListingByClientId = (clientId, page = 0, limit = 10) => {
  return axiosClient.get(`v2/client/${clientId}/tax_assessment/orders`).then((resp) => resp?.data);
};

export const getVistaOrdersListingByPropertyId = (propertyID, page = 0, limit = 10) => {
  return axiosClient
    .get(`v2/client/property/${propertyID}/advisory/orders`)
    .then((resp) => resp?.data);
};

export const getTaxAssessmentOrdersListingByPropertyId = (propertyID, page = 0, limit = 10) => {
  return axiosClient
    .get(`v2/client/property/${propertyID}/tax_assessment/orders`)
    .then((resp) => resp?.data);
};

export const getOrderDetails = (orderId) => {
  return axiosClient.get(orderDetailsByIdEndpoint + orderId).then((resp) => resp?.data);
};

export const getOrdersListingByClientId = (clientId, page = 0) => {
  return axiosClient
    .get(orderListingByClientIdEndpoint + clientId + `/normal_orders?limit=10&offset=${page}`)
    .then((resp) => resp?.data);
};

export const addOrder = (clientId, params) => {
  return axiosClient.post('v1/client/' + clientId + '/orders', params).then((resp) => resp?.data);
};

export const cancelOrder = (clientId, orderId) => {
  return axiosClient
    .patch('v1/client/' + clientId + '/orders/' + orderId + '/cancel')
    .then((resp) => resp?.data);
};

export const updateOrder = (clientId, orderId) => {
  return axiosClient
    .patch('v1/client/' + clientId + '/orders/' + orderId + '/update')
    .then((resp) => resp?.data);
};

export const getOrderComments = (orderId) => {
  return axiosClient.get(`v1/client/normal/order/${orderId}/comments`).then((resp) => resp?.data);
};

export const addOrderComment = (orderId, comments) => {
  return axiosClient
    .post(`v1/client/normal/order/${orderId}/comments`, { comments })
    .then((resp) => resp?.data);
};

export const assignNonTransOrderRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/normal_orders/${orderId}/assign_rm`, params)
    .then((resp) => resp?.data);
};

export const assignToVista = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/advisory_orders/${orderId}/assign_rm`, params)
    .then((resp) => resp?.data);
};

export const assignToTaxAssessment = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/tax_assessment_order/${orderId}/assign_rm`, params)
    .then((resp) => resp?.data);
};

export const subAssignToVista = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/advisory_orders/${orderId}/sub_assign_rm`, params)
    .then((resp) => resp?.data);
};
export const subAssignToTaxAssessment = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/tax_assessment_order/${orderId}/sub_assign_rm`, params)
    .then((resp) => resp?.data);
};

export const advisoryReportUpload = (clientId, params) => {
  return axiosClient
    .post(`v2/client/${clientId}/advisory/order/status/fileupload`, params)
    .then((resp) => resp?.data);
};

export const uploadFile = (clientId, files) => {
  return axiosClient
    .post(`v1/client/${clientId}/normal/order/fileupload?encoded=false`, files)
    .then((resp) => resp?.data);
};

export const getFiles = (clientId, orderId) => {
  return axiosClient
    .get(`v1/client/${clientId}/normal/order/${orderId}/filelist`)
    .then((resp) => resp?.data);
};

export const deleteFile = (clientId, orderId, fileId) => {
  return axiosClient
    .delete(`v1/client/${clientId}/normal/order/${orderId}/file/${fileId}/delete`)
    .then((resp) => resp?.data);
};

export const getOrderExtComments = (orderId) => {
  return axiosClient
    .get(`v1/client/normal/order/${orderId}/external/comments`)
    .then((resp) => resp?.data);
};

export const addOrderExtComment = (orderId, comments) => {
  return axiosClient
    .post(`v1/client/normal/order/${orderId}/external/comments`, { comments })
    .then((resp) => resp?.data);
};

export const addOrderExtSubComment = (parentId, comments) => {
  return axiosClient
    .post(`v1/client/normal/order/external/sub/comments/${parentId}`, { comments })
    .then((resp) => resp?.data);
};

export const getOrderExtSubComments = (parentId) => {
  return axiosClient
    .get(`v1/client/normal/order/external/comments/${parentId}`)
    .then((resp) => resp?.data);
};

export const uploadNormalOrderCommentFile = (commentId, files) => {
  return axiosClient
    .post(`v1/client/normal/order/external/comments/${commentId}/fileupload?encoded=False`, files)
    .then((resp) => resp?.data);
};

export const searchInNormalComment = (offset = 0, limit = 100, search_param, orderId) => {
  return axiosClient
    .get(
      `v1/client/normal/order/${orderId}/search?offset=${offset}&limit=${limit}&search_param=${search_param}`
    )
    .then((resp) => resp?.data);
};

export const updateOrderStatus = (clientId, orderId, statusId, subStatusId) => {
  return axiosClient
    .patch(`v2/client/${clientId}/normal_orders/${orderId}/status/update`, {
      order_status: statusId,
      order_sub_status: subStatusId
    })
    .then((resp) => resp?.data);
};

export const updateSubAssignFOS = (clientId, orderId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/normal_orders/${orderId}/sub_assign_fos`, params)
    .then((resp) => resp?.data);
};

export const updateSubAssignRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v1/client/${clientId}/normal_orders/${orderId}/sub_assign_rm`, params)
    .then((resp) => resp?.data);
};

export const addNormalOrderSubStatus = (clientId, formData) => {
  return axiosClient
    .post(`v1/client/${clientId}/normal/order/status/fileupload?encoded=False`, formData)
    .then((resp) => resp?.data);
};

export const addPaymentsInNormalOrderSubStatus = (formData) => {
  return axiosClient
    .post('v1/client/normal/order/payments/fileupload?encoded=False', formData)
    .then((resp) => resp?.data);
};

export const addAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/normal_orders/${orderId}/assign_rm_status`, params)
    .then((resp) => resp?.data);
};

export const addAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/normal_orders/${orderId}/assign_prm_status`, params)
    .then((resp) => resp?.data);
};

export const addPayment = (formData) => {
  return axiosClient
    .post('v1/client/normal/order/payments/fileupload?encoded=False', formData)
    .then((resp) => resp?.data);
};

export const getPerformanceCredit = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/normal_orders/${orderId}/performance_credit`)
    .then((resp) => resp?.data);
};

export const getPaymentList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/normal_orders/${orderId}/payments`)
    .then((resp) => resp?.data);
};

export const verifyTDS = (clientId, paymentId, isTDSVerified) => {
  return axiosClient
    .put(`v2/client/${clientId}/payments/${paymentId}/tds_status?tds_status=${isTDSVerified}`, {
      tds_status: isTDSVerified
    })
    .then((resp) => resp?.data);
};

export const verifyPayment = (clientId, paymentId, isPaymentVerified, emailId) => {
  return axiosClient
    .put(
      `v2/client/${clientId}/payments/${paymentId}/verification_status?payment_status=${isPaymentVerified}&assign_to=${emailId}`,
      {
        assign_to: emailId,
        payment_status: isPaymentVerified
      }
    )
    .then((resp) => resp?.data);
};

export const verifyPaymentByBranchHead = (
  clientId,
  paymentId,
  isPaymentVerifiedByBranchHead,
  prmEmailId,
  bhEmailId
) => {
  return axiosClient
    .put(
      `v2/client/${clientId}/payments/${paymentId}/bh_status?bh_status=${isPaymentVerifiedByBranchHead}&bh_email=${bhEmailId}&prm_email=${prmEmailId}`,
      {
        bh_email: bhEmailId,
        bh_status: isPaymentVerifiedByBranchHead,
        prm_email: prmEmailId
      }
    )
    .then((resp) => resp?.data);
};

export const verifyPaymentByNationalHead = (
  clientId,
  paymentId,
  isPaymentVerifiedByNationalHead,
  bhEmailId
) => {
  return axiosClient
    .put(
      `v2/client/${clientId}/payments/${paymentId}/nh_status?nh_status=${isPaymentVerifiedByNationalHead}&bh_email=${bhEmailId}`,
      {
        bh_email: bhEmailId,
        nh_status: isPaymentVerifiedByNationalHead
      }
    )
    .then((resp) => resp?.data);
};

export const editPayment = (paymentId, params) => {
  return axiosClient
    .patch(`v1/client/order/payments/${paymentId}/fileupload?encoded=True`, params)
    .then((resp) => resp?.data);
};

export const updateAssignStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/normal_orders/${orderId}/assign_rm_status/update`, params)
    .then((resp) => resp?.data);
};

export const updateAssignPRM = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/normal_orders/${orderId}/assign_prm_status/update`, params)
    .then((resp) => resp?.data);
};

export const getAdvisoryType = () => {
  return axiosClient.get(orderAdvisoryTypeDropdown).then((resp) => resp?.data);
};

export const getTaxAssessmentDropdown = () => {
  return axiosClient.get(orderTaxAssessmentDropdownEndpoint).then((resp) => resp?.data);
};

export const getTaxAssessmentById = (id) => {
  return axiosClient.get(`v2/client/tax_assessment/order/${id}/details`).then((resp) => resp?.data);
};

export const getAdvisoryById = (id) => {
  return axiosClient.get(`v2/clients/advisory/order/${id}`).then((resp) => resp?.data);
};

export const postAdvisory = (clientId, payload) => {
  return axiosClient
    .post(`v2/client/${clientId}/advisory/orders`, payload)
    .then((resp) => resp?.data);
};

export const postTaxAssessment = (clientId, payload) => {
  return axiosClient
    .post(`v2/client/${clientId}/tax_assessment/orders`, payload)
    .then((resp) => resp?.data);
};

export const getNormalPerformancePointList = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/normal_orders/${orderId}/performance_point_payments`)
    .then((resp) => resp?.data);
};

export const addNormalPerformancePoint = (formData) => {
  return axiosClient
    .post('v1/client/normal/order/payments/fileupload?encoded=True', formData)
    .then((resp) => resp?.data);
};

export const createAssessment = (clientId, params) => {
  return axiosClient
    .post(`v2/client/${clientId}/tax_assessment_order/order/status/fileupload`, params)
    .then((resp) => resp?.data);
};

export const updateTaxAssessmentStatus = (clientId, orderId, params) => {
  return axiosClient
    .patch(`v2/client/${clientId}/tax_assessment_order/${orderId}/status/update`, params)
    .then((resp) => resp?.data);
};

export const addQuotation = (clientId, params) => {
  return axiosClient
    .post(`v2/client/${clientId}/tax_assessment_order/order/status/fileupload`, params)
    .then((resp) => resp?.data);
};

export const uploadTaxReceipt = (clientId, params) => {
  return axiosClient
    .post(`v2/client/${clientId}/tax_assessment_order/order/status/fileupload`, params)
    .then((resp) => resp?.data);
};

export const addAssignStatusTaxAssessment = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/tax_assessment_order/${orderId}/assign_rm_status`, params)
    .then((resp) => resp?.data);
};

export const addAssignPRMTaxAssessment = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/tax_assessment_order/${orderId}/assign_prm_status`, params)
    .then((resp) => resp?.data);
};

export const orderAssignConfirmStatus = (clientId, orderId, params) => {
  return axiosClient
    .put(`v2/client/${clientId}/tax_assessment_order/${orderId}/assign_confirm_status`, params)
    .then((resp) => resp?.data);
};

export const addPaymentTaxAssessment = (params) => {
  return axiosClient
    .post('v2/client/tax_assessment_order/payments/fileupload', params)
    .then((resp) => resp?.data);
};

export const getPaymentsTaxAssessment = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/tax_assessment_order/${orderId}/payments`)
    .then((resp) => resp?.data);
};

export const getPerformanceCreditTaxAssessment = (clientId, orderId) => {
  return axiosClient
    .get(`v2/client/${clientId}/tax_assessment_order/${orderId}/performance_credit`)
    .then((resp) => resp?.data);
};