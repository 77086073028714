import { makeStyles } from '@mui/styles';
import { COLORS } from 'utils/enums';

const countryCodeDropdownStyles = makeStyles({
  code: {
    color: COLORS.PRIMARY,
    fontFamily: 'Literata !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    width: 265
  }
});

export default countryCodeDropdownStyles;
