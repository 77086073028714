import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import titleContainerStyles from './TitleContainer.styles';

export default function TitleContainer({
  primaryButtonAction = () => {},
  primaryButtonText = '',
  secondaryButtonAction = () => {},
  secondaryButtonText = '',
  subTitle = '',
  title = ''
}) {
  const classes = titleContainerStyles();
  return (
    <Box className={classes.main}>
      <Box>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h5" className={classes.subtitle}>
          {subTitle}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex'
        }}>
        {primaryButtonText && (
          <Button variant="contained" onClick={primaryButtonAction} className={classes.button}>
            {primaryButtonText}
          </Button>
        )}
        {secondaryButtonText && (
          <Button
            size="small"
            variant="outlined"
            onClick={secondaryButtonAction}
            className={`${classes.button} ${classes.secondaryButton}`}>
            {secondaryButtonText}
          </Button>
        )}
      </Box>
    </Box>
  );
}

TitleContainer.propTypes = {
  primaryButtonAction: PropTypes.func,
  primaryButtonText: PropTypes.string,
  quaternaryButtonAction: PropTypes.func,
  quaternaryButtonText: PropTypes.string,
  secondaryButtonAction: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  subTitle: PropTypes.string,
  tertiaryButtonAction: PropTypes.func,
  tertiaryButtonText: PropTypes.string,
  title: PropTypes.string
};
